<template>
  <a
    :href="`mailto:${getSupportAddress}`"
    target="_blank"
    class="support-wrapper"
  >
    <BaseIconText :icon-url="svgs.ICONS.QUESTION_ENVELOPE">
      {{ isMobile ? 'Behöver du hjälp?' : getSupportAddress }}
    </BaseIconText>
  </a>
</template>

<script>
import BaseIconText from '@/components/BaseIconText.vue';
import svgs from '@/utils/icon-constants';
import { mapGetters } from 'vuex';

export default {
  name: 'SupportButton',
  components: { BaseIconText },
  data() {
    return {
      svgs
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      getSupportAddress: 'app/getSupportAddress'
    })
  }
};
</script>

<style lang="scss" scoped>
.support-wrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  text-align: center;
  background-color: var(--color-blue);
  border-radius: 25px;
  color: #fff;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  @media ($mobile) {
    bottom: 1.2rem;
    right: 1.2rem;
  }
}
</style>
