import { render, staticRenderFns } from "./DuplicatesModal.vue?vue&type=template&id=4cd590ef&scoped=true"
import script from "./DuplicatesModal.vue?vue&type=script&lang=js"
export * from "./DuplicatesModal.vue?vue&type=script&lang=js"
import style0 from "./DuplicatesModal.vue?vue&type=style&index=0&id=4cd590ef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd590ef",
  null
  
)

export default component.exports