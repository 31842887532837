import { fieldIsEmpty } from '@/utils/input-validations';
import { hasNewPropertyRejections } from '@/utils/common';
import { validateAll, getDocumentGroups } from '@/utils/applicationValidation';

const summarizeStatuses = statuses => {
  if (!statuses || statuses.every(status => status === '')) return '';
  if (statuses.every(status => status === 'EMPTY')) return 'EMPTY';
  if (statuses.includes('COMMENT')) return 'COMMENT';
  if (statuses.every(status => status === 'APPROVED')) return 'APPROVED';
  if (statuses.includes('INVALID')) return 'INVALID';
  if (statuses.some(status => ['INCOMPLETE', 'EMPTY'].includes(status)))
    return 'INCOMPLETE';
  return 'COMPLETE';
};

export default {
  applicationStatus(state, getters, rootState) {
    const application = rootState.application.application;
    if (!application) return 'INCOMPLETE';

    return application.applicants.every(
      applicant =>
        applicant.apartments &&
        applicant.members &&
        applicant.members.every(member => member.name)
    )
      ? 'COMPLETE'
      : 'INCOMPLETE';
  },
  all(state, getters, rootState) {
    const application = rootState.application.application;
    if (!application) {
      return null;
    }
    return validateAll(application);
  },
  allSummarized(state, getters) {
    if (!getters.eachApplicantSummarized) return null;

    const merged = {};

    getters.eachApplicantSummarized.forEach(applicant => {
      Object.entries(applicant).forEach(([key, value]) => {
        if (!merged[key]) merged[key] = [];
        merged[key].push(value);
      });
    });

    return {
      APPLICATION: getters.applicationStatus,
      APARTMENTS: summarizeStatuses(merged.APARTMENTS),
      MEMBERS: summarizeStatuses(merged.MEMBERS),
      SWAPREASON: summarizeStatuses(merged.SWAPREASON),
      SWAPDETAILS: summarizeStatuses(merged.SWAPDETAILS),
      CREDIT_INFORMATION: summarizeStatuses(merged.CREDIT_INFORMATION),
      FAMILY_CERTIFICATE: summarizeStatuses(merged.FAMILY_CERTIFICATE),
      INCOME_BASIS: summarizeStatuses(merged.INCOME_BASIS),
      LEASE_AGREEMENT: summarizeStatuses(merged.LEASE_AGREEMENT),
      RENTAL_INVOICE: summarizeStatuses(merged.RENTAL_INVOICE),
      OTHER: summarizeStatuses(merged.OTHER)
    };
  },
  eachApplicantSummarized(state, getters, rootState) {
    const application = rootState.application.application;
    if (!application) return [];

    const categories = [
      'APARTMENTS',
      'MEMBERS',
      'SWAPREASON',
      'SWAPDETAILS',
      'CREDIT_INFORMATION',
      'FAMILY_CERTIFICATE',
      'INCOME_BASIS',
      'LEASE_AGREEMENT',
      'RENTAL_INVOICE',
      'OTHER'
    ];

    const checkReviewStatus = (items, category, applicantIndex) => ({
      comment: items.some(
        (item, index) =>
          hasNewPropertyRejections(
            application.applicants,
            applicantIndex,
            index,
            category
          ) && item.review?.message
      ),
      approved: items.every(item => item.review?.approved)
    });

    return application.applicants.map((applicant, applicantIndex) => {
      const merged = categories.reduce((acc, category) => {
        let status = { statuses: [], approved: false, comment: false };

        switch (category) {
          case 'APARTMENTS':
            status = checkReviewStatus(
              applicant.apartments,
              category,
              applicantIndex
            );
            break;
          case 'MEMBERS':
            status = checkReviewStatus(
              applicant.members,
              category,
              applicantIndex
            );
            break;
          case 'SWAPREASON':
            status = checkReviewStatus(
              [applicant.swapReason],
              category,
              applicantIndex
            );
            break;
          case 'SWAPDETAILS':
            status = checkReviewStatus(
              applicant.swapDetails,
              category,
              applicantIndex
            );
            break;
          default:
            status = {
              comment: applicant.documentGroups.some(
                (memberDocumentGroup, index) =>
                  hasNewPropertyRejections(
                    application.applicants,
                    applicantIndex,
                    index,
                    category
                  ) &&
                  (memberDocumentGroup[category].comment ||
                    memberDocumentGroup[category].documents.some(
                      doc => doc.review?.message
                    ))
              ),
              approved: getDocumentGroups(
                application,
                applicant,
                category
              ).every(
                memberDocumentGroup => memberDocumentGroup[category].approved
              )
            };
        }

        acc[category] = {
          ...status,
          statuses: [
            summarizeStatuses(
              getters.applicantCategoryAll(applicantIndex, category)
            )
          ]
        };

        return acc;
      }, {});

      return Object.entries(merged).reduce((acc, [category, value]) => {
        let status = '';
        if (value.comment) {
          status = 'COMMENT';
        } else if (value.approved) {
          status = 'APPROVED';
        } else if (category !== 'OTHER') {
          status = summarizeStatuses(value.statuses);
        }
        return { ...acc, [category]: status };
      }, {});
    });
  },
  applicantCategoryAll: (state, getters) => (applicantIndex, category) => {
    if (!getters.all) return null;

    const documentCategories = [
      'CREDIT_INFORMATION',
      'FAMILY_CERTIFICATE',
      'INCOME_BASIS',
      'LEASE_AGREEMENT',
      'RENTAL_INVOICE',
      'OTHER'
    ];
    const data = getters.all[applicantIndex][category];
    if (!data) return [''];

    if (!documentCategories.includes(category)) {
      return data.map(category => {
        const categoryValues = Object.values(category);
        return summarizeStatuses(
          categoryValues.map(field => {
            if (fieldIsEmpty(field.value)) return 'EMPTY';
            if (!field.valid) return 'INVALID';
            return 'COMPLETE';
          })
        );
      });
    }
    return data.map(docs => {
      const statuses = docs.length ? ['COMPLETE'] : ['EMPTY'];
      return summarizeStatuses(statuses);
    });
  }
};
