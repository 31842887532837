import apiClient from './apiClient';

async function getUserTutorialsSeen() {
  return await apiClient.get('/portal/tutorial');
}

async function addSeenTutorialStep(tutorialId) {
  return await apiClient.put(`/portal/tutorial`, { tutorialId });
}

export default {
  addSeenTutorialStep,
  getUserTutorialsSeen
};
