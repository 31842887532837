var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"summary-menu"},[(_vm.isOwnerOfAllApplicants)?_c('TabMenu',_vm._l((_vm.application.applicants),function(applicant,index){return _c('TabMenuButton',{key:applicant.index,attrs:{"active":index === _vm.selectedApplicantIndex},on:{"tabMenuButtonClick":function($event){return _vm.handleTabClick(index)}}},[_vm._v(" "+_vm._s(_vm.getName(applicant))+" ")])}),1):_vm._e()],1),_c('transition',{attrs:{"name":"form","mode":"out-in"}},[(!!_vm.activeValidationApplicant)?_c('div',{key:_vm.selectedApplicantIndex,staticClass:"data-summary-wrapper"},[_c('div',{staticClass:"summary-splitter"},_vm._l((_vm.activeValidationApplicant.APARTMENTS),function(apartment,apartmentIndex){return _c('ApartmentSummary',{key:`apartment-${apartmentIndex}`,attrs:{"apartment":apartment,"title":_vm.activeValidationApplicant.APARTMENTS.length > 1
              ? `Lägenhet ${_vm.alphabet[_vm.selectedApplicantIndex]}${apartmentIndex + 1}`
              : 'Lägenhet',"is-approved":_vm.categoryIsApproved('APARTMENTS', apartmentIndex),"category-has-errors":_vm.categoryHasErrors('APARTMENTS', apartmentIndex),"rejection-message":_vm.newPropertyRejections('APARTMENTS', apartmentIndex)
              ? _vm.getRejectionMessage('apartments', apartmentIndex)
              : ''},on:{"goToCategory":function($event){return _vm.goToCategory($event, apartmentIndex)}}})}),1),_c('div',{staticClass:"summary-splitter"},_vm._l((_vm.activeValidationApplicant.MEMBERS),function(member,memberIndex){return _c('MemberSummary',{key:`member-${memberIndex}`,attrs:{"member":member,"category-has-errors":_vm.categoryHasErrors('MEMBERS', memberIndex),"is-approved":_vm.categoryIsApproved('MEMBERS', memberIndex),"rejection-message":_vm.newPropertyRejections('MEMBERS', memberIndex)
              ? _vm.getRejectionMessage('members', memberIndex)
              : ''},on:{"goToCategory":function($event){return _vm.goToCategory($event, memberIndex)}}})}),1),_vm._l((_vm.activeValidationApplicant.SWAPREASON),function(swapReason,reasonIndex){return _c('SwapReasonSummary',{key:`reason-${reasonIndex}`,attrs:{"swap-reason":swapReason,"category-has-errors":_vm.categoryHasErrors('SWAPREASON', reasonIndex),"is-approved":_vm.categoryIsApproved('SWAPREASON', reasonIndex),"rejection-message":_vm.newPropertyRejections('SWAPREASON')
            ? _vm.getRejectionMessage('swapReason')
            : ''},on:{"goToCategory":function($event){return _vm.goToCategory($event)}}})}),_vm._l((_vm.activeValidationApplicant.SWAPDETAILS),function(swapDetail,detailIndex){return _c('SwapDetailsSummary',{key:`detail-${detailIndex}`,attrs:{"title":`Bytesdetaljer (${
          _vm.activeValidationApplicant.SWAPDETAILS[detailIndex].name ||
          `Bytespart ${detailIndex + 1}`
        })`,"swap-detail":swapDetail,"category-has-errors":_vm.categoryHasErrors('SWAPDETAILS', detailIndex),"is-approved":_vm.categoryIsApproved('SWAPDETAILS', detailIndex),"rejection-message":_vm.newPropertyRejections('SWAPDETAILS', detailIndex)
            ? _vm.getRejectionMessage('swapDetails', detailIndex)
            : ''},on:{"goToCategory":function($event){return _vm.goToCategory($event, detailIndex)}}})}),_vm._l(([
          'CREDIT_INFORMATION',
          'FAMILY_CERTIFICATE',
          'INCOME_BASIS',
          'LEASE_AGREEMENT',
          'RENTAL_INVOICE',
          'OTHER'
        ]),function(category){return _vm._l((_vm.activeValidationApplicant[category]),function(documents,index){return _c('DocumentCategorySummary',{key:`${category}-${index}`,attrs:{"name":_vm.activeValidationApplicant.MEMBERS[index].name.value,"applicant-id":_vm.activeApplicantId,"documents":documents,"category-id":category,"is-approved":_vm.categoryIsApproved(category, index),"category-has-errors":_vm.categoryHasErrors(category, index),"rejection-message":_vm.newPropertyRejections(category, index)
              ? _vm.getRejectionMessage(category, index)
              : ''},on:{"goToCategory":function($event){return _vm.goToCategory($event, index)}}})})})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }