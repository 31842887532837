export default {
  isMobile: state => state.screenSize === 'MOBILE',
  isSmallDesktop: state => state.screenSize === 'DESKTOP_SMALL',
  isDesktop: state => state.screenSize === 'DESKTOP',
  menu: state => state.sideMenu,
  tooltipsForScreensize: state =>
    state.tooltips.filter(tip =>
      tip.type.includes(state.screenSize.split('_')[0])
    ),
  filteredMenu(state) {
    const newMenu = [];
    const blackList = ['DATA_SUMMARY', 'STATUS'];

    for (const category of state.sideMenu) {
      if (!blackList.includes(category.statusName)) {
        newMenu.push(category);
      }
    }

    return newMenu;
  },
  getSupportAddress(state) {
    return state.landlord?.hasAltEmailAddress
      ? state.altSupportAddress
      : state.supportAddress;
  }
};
