<template>
  <DocumentsGrid
    :documents="documents"
    :downloading-uuids="downloadingUuids"
    @fileClick="handleOpenFile"
  />
</template>

<script>
import applicationService from '@/services/applicationService';
import DocumentsGrid from '@/components/DocumentsGrid.vue';

export default {
  name: 'SubletDocumentsGrid',
  components: {
    DocumentsGrid
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    applicationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      downloadingUuids: []
    };
  },
  methods: {
    async handleOpenFile(uuid) {
      const applicationId = this.applicationId;
      this.downloadingUuids.push(uuid);
      const file = await applicationService.getFile(applicationId, uuid);

      const buffer = file?.buffer;
      const type = file?.type || '';

      if (!buffer) {
        return;
      }

      const uint8 = new Uint8Array(buffer.data);
      const blob = new Blob([uint8], { type });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);

      const index = this.downloadingUuids.indexOf(uuid);
      if (index > -1) {
        this.downloadingUuids.splice(index, 1);
      }
    }
  }
};
</script>

<style></style>
