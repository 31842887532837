<template>
  <header>
    <div class="header-wrapper">
      <div class="logo-wrapper">
        <div v-show="showLogo">
          <img
            v-if="landlord"
            class="logo"
            :src="landlord.logoUrl"
            alt="logo"
          />
          <img
            v-else
            class="logo"
            :src="defaultLogoSrc"
            :alt="defaultLogoAlt"
          />
          <p v-if="landlord?.header_disclaimer">
            {{ landlord.header_disclaimer }}
          </p>
        </div>
      </div>
      <div v-if="showLoginBtn" class="right-container">
        <BaseButton
          class="login-button"
          :is-rounded="true"
          :is-small="isMobile"
          @click="scrollToLogin()"
        >
          <p :style="{ 'margin-right': '0px' }">Logga in</p>
          <BaseIconText
            class="info-modal-arrow-right"
            :icon-url="svgs.ICONS.ARROW_RIGHT"
          >
          </BaseIconText>
        </BaseButton>
      </div>
      <transition name="fade-in-out" appear mode="in-out">
        <div v-if="showRightContainer" class="right-container">
          <BaseButton
            v-if="isSwapHeader"
            class="header-button"
            :is-rounded="true"
            :is-default="true"
            :is-small="isMobile"
            @click="$emit('swapClick')"
          >
            <span>{{ isMobile ? 'Byten' : 'Välj byte' }}</span>
          </BaseButton>
          <BaseButton
            v-if="isSwapHeader"
            class="header-button"
            :is-rounded="true"
            :is-small="isMobile"
            :is-dark="true"
            @click="$emit('showStatusClick')"
          >
            <img :src="svgs.ICONS.CLIPBOARD" />

            <span class="button-text">{{
              isMobile ? 'Status' : 'Visa status'
            }}</span>
          </BaseButton>
          <BaseButton
            v-if="!isSwapHeader"
            class="header-button"
            :is-rounded="true"
            :is-small="isMobile"
            :is-dark="true"
            @click="$emit('showSubletInformationClick')"
          >
            <img :src="svgs.ICONS.CLIPBOARD" />

            <span class="button-text">{{
              isMobile ? 'Information' : 'Viktig information'
            }}</span>
          </BaseButton>
          <BaseButton
            v-if="!isMobile"
            :is-rounded="true"
            :is-default="true"
            :is-small="isMobile"
            @click="$emit('logoutClick')"
          >
            <img :src="svgs.ICONS.ARROW_RIGHT_CIRCLE" />
            <span class="button-text">Logga ut</span>
          </BaseButton>

          <Tooltip v-if="isMobile" position="bottom-right" step="mobileMenu">
            <button
              type="button"
              class="hamburger"
              @click.stop.prevent="$emit('toggleMenuClick')"
            >
              <svg
                v-if="sideMenuOpen"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#2C3E50" />
                <path
                  d="M21.41 19.9999L25.71 15.7099C25.8983 15.5216 26.0041 15.2662 26.0041 14.9999C26.0041 14.7336 25.8983 14.4782 25.71 14.2899C25.5217 14.1016 25.2663 13.9958 25 13.9958C24.7337 13.9958 24.4783 14.1016 24.29 14.2899L20 18.5899L15.71 14.2899C15.5217 14.1016 15.2663 13.9958 15 13.9958C14.7337 13.9958 14.4783 14.1016 14.29 14.2899C14.1017 14.4782 13.9959 14.7336 13.9959 14.9999C13.9959 15.2662 14.1017 15.5216 14.29 15.7099L18.59 19.9999L14.29 24.2899C14.1963 24.3829 14.1219 24.4935 14.0711 24.6154C14.0203 24.7372 13.9942 24.8679 13.9942 24.9999C13.9942 25.132 14.0203 25.2627 14.0711 25.3845C14.1219 25.5064 14.1963 25.617 14.29 25.7099C14.383 25.8037 14.4936 25.8781 14.6154 25.9288C14.7373 25.9796 14.868 26.0057 15 26.0057C15.132 26.0057 15.2627 25.9796 15.3846 25.9288C15.5064 25.8781 15.617 25.8037 15.71 25.7099L20 21.4099L24.29 25.7099C24.383 25.8037 24.4936 25.8781 24.6154 25.9288C24.7373 25.9796 24.868 26.0057 25 26.0057C25.132 26.0057 25.2627 25.9796 25.3846 25.9288C25.5064 25.8781 25.617 25.8037 25.71 25.7099C25.8037 25.617 25.8781 25.5064 25.9289 25.3845C25.9797 25.2627 26.0058 25.132 26.0058 24.9999C26.0058 24.8679 25.9797 24.7372 25.9289 24.6154C25.8781 24.4935 25.8037 24.3829 25.71 24.2899L21.41 19.9999Z"
                  fill="white"
                />
              </svg>

              <svg
                v-else
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#2C3E50" />
                <rect x="10" y="13" width="20" height="2" rx="1" fill="white" />
                <rect x="10" y="19" width="16" height="2" rx="1" fill="white" />
                <rect x="10" y="25" width="20" height="2" rx="1" fill="white" />
              </svg>
            </button>
          </Tooltip>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import Tooltip from '@/components/Tooltip.vue';
import svgs from '@/utils/icon-constants';
import bytesansokanLogo from '@/assets/svgs/logo.svg';

export default {
  components: { BaseButton, BaseIconText, Tooltip },

  props: {
    sideMenuOpen: {
      type: Boolean,
      default: false
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    showLoginBtn: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isSwapHeader: {
      type: Boolean,
      default: false
    },
    showRightContainer: {
      type: Boolean,
      default: false
    },
    landlord: {
      type: Object,
      default: () => {}
    },
    defaultLogoSrc: {
      type: String,
      default: bytesansokanLogo
    },
    defaultLogoAlt: {
      type: String,
      default: 'Bytesansökans logo'
    }
  },

  data() {
    return {
      svgs
    };
  },
  methods: {
    scrollToLogin() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background-color: #fff;
  border-bottom: 1px solid hsl(0, 0%, 95%);
  height: var(--header-height);
  width: 100%;
  @media ($mobile) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
  }
}

.header-wrapper {
  height: 100%;
  padding: 0 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  font-size: 1.2rem;
  @media ($mobile) {
    padding: 0 1.2rem;
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 5px;
  img {
    max-height: var(--header-height);
  }
}

.logo {
  max-width: 200px;
  margin-right: 1rem;
  font-weight: 800;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;

  @media ($mobile) {
    max-width: 100px;
  }
}
.temp-logo {
  line-height: 1;
  @media ($mobile) {
    font-size: 18px;
    margin-top: 3px;
  }
}

.right-container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.button-text {
  display: block;
  margin-left: 15px;

  @media ($mobile) {
    margin-left: 8px;
  }
}

.login-button {
  :deep(.icon) {
    margin-right: 0;
  }
}

.info-modal {
  max-width: 500px;
}

.hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 3.2rem;
  height: 3.2rem;
  svg {
    width: 100%;
    height: 100%;
  }
}

.header-button {
  @media ($mobile) {
    height: 3.2rem;
    font-size: 1.2rem;
    * {
      margin: 0;
    }
    img {
      display: none;
    }
  }
}
</style>
