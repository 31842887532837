import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  selectedApplicantIndex: 0,
  selectedPossessionIndex: 0,
  application: null,
  applicationId: null,
  isLoadingDocument: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
