<template>
  <Input
    :disabled="applicationIsSent || disabled || applicationIsArchived"
    :no-validate="noValidate"
    v-bind="$attrs"
    :show-green-checkmark="showGreenCheckmark"
    :description="description"
    :validation="validation"
    :error-text="errorText"
    :dropdown="dropdown"
    :is-loading="isLoading"
    :is-warning="isWarning"
    :value="value"
    :label="label"
    :title="
      applicationIsSent
        ? 'Under granskning, det går inte att göra ändringar för tillfället'
        : $attrs.title
    "
    v-on="$listeners"
  >
    <slot
  /></Input>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '@/components/Input.vue';

export default {
  name: 'SwapInput',
  components: {
    Input
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showGreenCheckmark: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null,
      required: false
    },
    description: {
      type: String,
      default: null,
      required: false
    },
    validation: {
      required: false,
      type: Function,
      default(val) {
        return val?.length > 0;
      }
    },
    errorText: {
      type: String,
      default: 'Detta fält måste fyllas i korrekt',
      required: false
    },
    dropdown: {
      type: Array,
      default: null,
      required: false
    },
    noValidate: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      applicationIsSent: 'application/applicationIsSent',
      applicationIsArchived: 'application/applicationIsArchived'
    })
  }
};
</script>

<style></style>
