<template>
  <p>
    <template v-if="missingFields.length">
      Du har missat att fylla i några fält:
      <ul>
        <li v-for="field in missingFields" :key="field">
          {{ field }}
        </li>
      </ul>
    </template>
    <template v-if="invalidFields.length">
      Dessa fält innehåller fel:
      <ul>
        <li v-for="field in invalidFields" :key="field">
          {{ field }}
        </li>
      </ul>
    </template>
    <br v-if="invalidFields.length || missingFields.length" />
    <slot />
  </p>
</template>
<script>
export default {
  props: {
    invalidFields: {
      type: Array,
      required: true
    },
    missingFields: {
      type: Array,
      required: true
    }
  }
};
</script>
<style scoped>
ul {
  margin: 0;
}
</style>
