<template>
  <div class="summary-field-wrapper">
    <p class="label">
      {{ label }}
    </p>
    <p v-if="hasEmptyInfoFields" class="missing-info">{{ infoHtml }}</p>
    <p v-else><slot /></p>
  </div>
</template>

<script>
export default {
  name: 'SummaryField',

  props: {
    label: {
      type: String,
      default: ''
    },
    hasEmptyInfoFields: {
      type: Boolean,
      default: false
    },
    infoHtml: {
      type: String,
      default: 'Information saknas'
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-field-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 1.6rem;
  @media ($mobile) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
  }
  p {
    width: 43%;
    @media ($mobile) {
      width: 100%;
    }
  }
  .label {
    @media ($mobile) {
      font-weight: 600;
    }
  }

  .missing-info,
  .has-error {
    font-style: italic;
    color: var(--color-red);
  }
}
</style>
