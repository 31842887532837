<template>
  <section class="status-text-wrapper">
    <div class="dynamic-text-container">
      <div class="submit-container">
        <h1>Översikt</h1>
        <BaseButton
          v-if="showSubmit"
          :is-rounded="true"
          :is-disabled="false"
          :disabled="!submitBtnEnabled"
          class="submit-btn"
          :class="{ active: submitBtnEnabled }"
          @click="confirmModalVisible = true"
        >
          Skicka in ansökan
        </BaseButton>
      </div>

      <template v-if="showSubmit">
        <div>
          <h3>
            Allt är sparat! Du kan fortsätta där du slutade när du kommer
            tillbaka.
          </h3>
          <p v-if="!submitBtnEnabled" class="red">
            Innan du kan skicka in din ansökan behöver du ha fyllt i alla
            uppgifter korrekt och laddat upp dokumenten som krävs.
          </p>
        </div>
      </template>
      <template v-else>
        <h3 v-if="allApplicantsDone">Vad händer nu?</h3>
        <p v-html="dynamicStatusText" />
        <p v-if="allApplicantsDone">
          Vi håller tummarna att ni får till bytet!
        </p>
      </template>
    </div>
    <SwapSummary v-if="!!application" :application="application" />

    <template v-if="showSubmit">
      <BaseButton
        v-if="isMobile"
        :is-rounded="true"
        :is-disabled="false"
        :disabled="!submitBtnEnabled"
        class="submit-btn"
        :class="{ active: submitBtnEnabled }"
        :is-fullwidth="true"
        @click="confirmModalVisible = true"
      >
        Skicka in ansökan
      </BaseButton>
    </template>
    <BaseButton
      v-else
      class="show-statuses-for-all-btn"
      :class="{
        'add-margin':
          (!firstApplicantDone && isSingleOwner) ||
          (isOwnerOfAllApplicants && !allApplicantsDone)
      }"
      :is-fullwidth="isMobile"
      :is-rounded="true"
      :is-dark="true"
      @click="showStatusModal = true"
    >
      <img :src="svgs.ICONS.CLIPBOARD" />
      <span class="button-text">Visa status för alla bytesparter</span>
    </BaseButton>
    <StatusModal v-if="showStatusModal" @close="showStatusModal = false" />
    <InfoModal
      v-if="confirmModalVisible"
      title="Bekräfta"
      confirm-label="Skicka in ansökan"
      abort-label="Avbryt"
      :is-disabled="isSubmitting"
      @click="handleSubmit"
    >
      <p>Är du säker på att du vill skicka in din ansökan?</p>
    </InfoModal>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { readableNames } from '@/utils/common';

import BaseButton from '@/components/BaseButton.vue';
//import BaseImage from '@/components/BaseImage.vue';
import svgs from '@/utils/icon-constants';
import StatusModal from '@/components/StatusModal.vue';
import SwapSummary from '@/components/SwapSummary/SwapSummary.vue';
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'StatusView',
  components: {
    BaseButton,
    //BaseImage,
    StatusModal,
    SwapSummary,
    InfoModal
  },
  data() {
    return {
      svgs,
      showStatusModal: false,
      fetchingStatuses: false,
      completedStatuses: ['TREATING', 'AWAITINGSIGN', 'READY', 'SENT'],
      confirmModalVisible: false,
      isSubmitting: false
    };
  },
  computed: {
    ...mapState('application', ['application']),
    ...mapGetters({
      isMobile: 'app/isMobile',
      isLbMode: 'app/isLbMode',
      applicant: 'application/applicant',
      applicants: 'application/applicants',
      isOwnerOfAllApplicants: 'application/isOwnerOfAllApplicants',
      isSingleOwner: 'application/isSingleOwner',
      connectedLandlordApplicants: 'application/connectedLandlordApplicants',
      menuFlat: 'app/menuFlat',
      allSummarized: 'appValidation/allSummarized',
      eachApplicantSummarized: 'appValidation/eachApplicantSummarized'
    }),
    showSubmit() {
      if (!this.application) {
        return false;
      }
      let status = this.application.applicants[0].status;
      if (this.isOwnerOfAllApplicants) {
        status = this.application.status;
      }
      return !this.completedStatuses.includes(status);
    },
    submitBtnEnabled() {
      let sum = {};
      if (this.isOwnerOfAllApplicants) {
        sum = this.allSummarized;
      } else {
        sum = this.eachApplicantSummarized[0];
      }
      return Object.values(sum)
        .filter(n => !!n)
        .every(val => this.isOkStatus(val));
    },
    showNotCompleted() {
      if (
        !this.isOwnerOfAllApplicants &&
        !this.allApplicantsDone &&
        this.isSingleAndDone
      ) {
        return false;
      }

      if (this.allApplicantsDone) {
        return false;
      }
      return true;
    },
    applicantId() {
      if (!this.applicant) return null;

      return this.applicant.id;
    },
    singleApplicantLandlordConnected() {
      return this.application?.applicants[0].apartments[0].landlordId > 0;
    },
    allApplicantsDone() {
      return this.completedStatuses.includes(this.application?.status);
    },
    isSingleAndDone() {
      return this.isSingleOwner && this.firstApplicantDone;
    },
    // first applicant is always the one signed in
    firstApplicantDone() {
      if (!this.eachApplicantSummarized?.length) {
        return false;
      }
      return this.completedStatuses.includes(
        this.application?.applicants[0].status
      );
    },
    dynamicStatusText() {
      const { connected, notConnected } = this.connectedLandlordApplicants;

      const connectedPlural = connected.length > 1;
      const notConnectedPlural = notConnected.length > 1;

      // Everything within LbMode
      if (this.isLbMode) {
        if (this.isSingleAndDone && !this.allApplicantsDone) {
          return 'Det ser ut som att du är klar med din del i den här bytesansökan! De andra bytesparterna är också på god väg att bli klara. När alla är klara <strong>skickar vi in ansökan till era hyresvärdar</strong> eftersom de är anslutna till oss.';
        } else if (this.allApplicantsDone) {
          return 'Efter att alla parter har signerat tar våra handläggare hand om er ansökan och skickar in bytet till era hyresvärdar. När hyresvärdarna tagit emot ansökan kan det ta upp till 3 månader innan beslut tas.';
        }
      }
      // Not LB MODE
      else {
        if (
          !this.isOwnerOfAllApplicants &&
          !this.allApplicantsDone &&
          this.isSingleAndDone
        ) {
          if (this.singleApplicantLandlordConnected) {
            return 'Det ser ut som att du är klar med din del i den här bytesansökan! De andra bytesparterna är också på god väg att bli klara. När alla är klara <strong>skickar vi in ansökan till din hyresvärd</strong> eftersom denne är ansluten till oss.';
          } else {
            return 'Det ser ut som att du är klar med din del i den här bytesansökan. De andra bytesparterna är också på god väg att bli klara. <strong>OBS!</strong> Kom även ihåg att ansöka om byte hos din hyresvärd.';
          }
        } else if (this.allApplicantsDone) {
          if (notConnected.length > 0 && connected.length > 0) {
            return `Efter att alla parter har signerat tar våra handläggare hand om er ansökan och skickar in bytet till ${readableNames(
              connected,
              true,
              true
            )} ${
              connectedPlural ? `hyresvärdar` : `hyresvärd`
            } eftersom deras ${
              connectedPlural
                ? `hyresvärdar är anslutna`
                : `hyresvärd är ansluten`
            } till oss. ${readableNames(notConnected, true, true)} ${
              notConnectedPlural ? `hyresvärdar` : `hyresvärd`
            } använder inte denna tjänst och behöver därför <strong>utöver detta ansöka om ett byte även hos dem</strong>. När hyresvärdarna tagit emot ansökan och alla handlingar är inskickade från samtliga hyresgäster, kan det ta upp till 3 månader innan beslut tas.`;
          } else if (connected.length > 0) {
            return `Efter att alla parter har signerat tar våra handläggare hand om er ansökan och skickar in bytet till ${readableNames(
              connected,
              true,
              true
            )} ${
              connectedPlural ? `hyresvärdar` : `hyresvärd`
            } eftersom <strong>${connectedPlural ? `dessa` : `denna`} ${
              connectedPlural
                ? `hyresvärdar är anslutna`
                : `hyresvärd är ansluten`
            } till oss</strong>. När hyresvärdarna tagit emot ansökan och alla handlingar är inskickade från samtliga hyresgäster, kan det ta upp till 3 månader innan beslut tas.`;
          } else if (notConnected.length > 0) {
            return 'Efter att alla parter har signerat tar våra handläggare hand om er ansökan. Eftersom era hyresvärdar inte använder denna tjänst behöver ni därför <strong>utöver detta ansöka om ett byte även hos dem</strong>. När hyresvärdarna tagit emot ansökan och alla handlingar är inskickade från samtliga hyresgäster, kan det ta upp till 3 månader innan beslut tas.';
          }
        }
      }

      return '';
    }
  },
  async created() {
    this.fetchingStatuses = true;

    await this.syncApplicationStatuses();

    this.fetchingStatuses = false;
  },
  methods: {
    ...mapActions({
      syncApplicationStatuses: 'application/syncApplicationStatuses',
      submitApplicant: 'application/submitApplicant'
    }),
    isOkStatus(status) {
      return ['', 'COMPLETE', 'APPROVED'].includes(status);
    },
    getLinkFromMenu(category) {
      return this.menuFlat.find(link => link.statusName === category) || {};
    },
    getLinkForCategory(category) {
      return this.getLinkFromMenu(category)?.url || '';
    },
    getLabelForCategory(category) {
      return this.getLinkFromMenu(category)?.label || '';
    },
    async handleSubmit(eventType) {
      if (eventType === 'CONFIRM') {
        this.isSubmitting = true;
        if (this.isOwnerOfAllApplicants) {
          // Submits for all applicants
          await Promise.all(
            this.application.applicants.map(async applicant =>
              this.submitApplicant(applicant.id)
            )
          );
        } else {
          // Submit theirs
          await this.submitApplicant(this.applicantId);
        }
        this.isSubmitting = false;
        window.scroll(0, 0);
      }
      this.confirmModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.status-text-wrapper {
  .dynamic-text-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 2rem;
    .icon {
      width: 40px;
    }
    .multiple-icon-container {
      display: flex;
      gap: 10px;
    }
    .dynamic-title {
      text-transform: uppercase;
    }
    .category-link-container {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }

    .category-link {
      color: var(--color-sky-blue);
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
    }
    .category-link:hover {
      color: var(--color-blue);
    }
  }
  p:empty {
    display: none;
  }
}
.show-statuses-for-all-btn {
  :deep(.slot-wrapper) {
    span {
      margin-left: 10px;
    }
  }
  &.add-margin {
    margin-top: 30px;
  }
}

.submit-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.red {
  color: var(--color-red);
  border-color: var(--color-red);
}

.submit-btn {
  &.active {
    animation: pulse 1.5s ease-in-out infinite;
    animation-delay: 0.4s;
  }
  @media ($smaller-mobile) {
    padding: 0 15px;
    height: 40px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(64, 159, 255, 0.7);
  }

  70% {
    opacity: 0.8;
    box-shadow: 0 0 0 10px rgba(64, 159, 255, 0);
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(64, 159, 255, 0);
  }
}
</style>
