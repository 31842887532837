<template>
  <div class="green-checkbox">
    <input
      v-bind="$attrs"
      :id="id"
      :name="id"
      :checked="checked"
      type="checkbox"
      @change="$emit('change', $event)"
    />
    <label id="checkbox" :for="id">
      <svg viewBox="0 0 100 100">
        <path
          class="box"
          d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z"
        />
        <polyline class="check" points="25.5,53.5 39.5,67.5 72.5,34.5 " />
      </svg>
      <span class="text"><slot></slot></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    checked: {
      type: Boolean,
      required: false
    },

    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    };
  },

  created() {
    if (this.name) {
      this.id = this.name;
    }
  }
};
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  display: none;
  pointer-events: none;

  & + label {
    display: flex;
    align-content: center;
    align-items: center;
    font-family: 'Averta Std';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    font-size: 0.8em;
    display: grid;
    grid-template-columns: auto 3fr;

    svg {
      width: 2em;
      stroke-width: 5;
      fill: white;

      .box {
        fill: white;
        stroke: lightgray;
      }

      .check {
        stroke: #40c07b;
        stroke-dasharray: 70;
        stroke-dashoffset: 70;
        fill: none;
        transition: stroke-dashoffset 0.3s linear;
      }
    }
  }

  &:checked + label {
    .check {
      stroke-dashoffset: 0;
    }
  }
}

.text {
  margin-left: 7px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
