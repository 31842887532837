<template>
  <div class="container">
    <h2 v-if="title" class="title">{{ title }}</h2>
    <Tooltip position="bottom" :step="tooltipStep">
      <div ref="tabs" class="tabs">
        <div class="tabs-inner">
          <slot />
          <span
            ref="activeBg"
            :class="[
              'active-bg',
              !useTransitionForActiveBg ? 'active-bg--no-transition' : ''
            ]"
          ></span>
        </div>
      </div>
    </Tooltip>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Tooltip from '@/components/Tooltip.vue';

export default {
  name: 'TabMenu',
  components: {
    Tooltip
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tooltipStep: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      useTransitionForActiveBg: false,
      bgTransitionTime: 350
    };
  },
  created() {
    this.debouncedRefitActiveBg = debounce(this.refitActiveBg, 200);
  },
  mounted() {
    this.setActiveBg(this.getCurrentTarget());
    // Wait for .active-bg transition to complete
    setTimeout(
      () => (this.useTransitionForActiveBg = true),
      this.bgTransitionTime
    );
    this.getCurrentTarget()?.scrollIntoView({ inline: 'center' });
    window.addEventListener('resize', this.debouncedRefitActiveBg);
  },
  destroy() {
    window.removeEventListener('resize', this.debouncedRefitActiveBg);
  },
  updated() {
    this.setActiveBg(this.getCurrentTarget());
  },
  methods: {
    refitActiveBg() {
      this.setActiveBg(this.getCurrentTarget());
    },
    setActiveBg(target) {
      if (!target) {
        return;
      }
      const left =
        target.offsetParent.className === 'tooltip-container'
          ? target.offsetParent.offsetLeft
          : target.offsetLeft;
      const height = target.clientHeight;
      const width = target.clientWidth;
      this.$refs.activeBg.style.transform = `translateX(${left}px)`;
      this.$refs.activeBg.style.height = `${height}px`;
      this.$refs.activeBg.style.width = `${width}px`;
    },
    getCurrentTarget() {
      if (this.$refs?.tabs) {
        return this.$refs.tabs.querySelector('button.active');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  flex-grow: 1;
}

.tabs {
  position: relative;
  @media ($mobile) {
    &::after {
      content: '';
      width: 0px;
      height: 100%;
      position: absolute;
      z-index: 4;
      right: -1rem;
      top: 0;
      box-shadow: 0px 0px 13px 17px #f8f8fa;
      user-select: none;
    }
  }
}

.tabs-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  background-color: #f8f8fa;
  @media ($mobile) {
    padding: 1rem 2.6rem 1rem 1rem;
    margin: 0 -1rem;
    -webkit-overflow-scrolling: touch; /* apply iOS momentum scrolling effect to this element */
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.title {
  position: relative;
  top: 25px;
  left: 35px;
  font-size: 2.4rem;
  margin-bottom: -26px;
  color: #ee9b39;
  width: fit-content;
}

.active-bg {
  position: absolute;
  z-index: 1;
  left: 0;
  will-change: filter, transform;
  transition: 0.2s ease-in-out;
  pointer-events: none;
  &::after {
    content: '';
    border-radius: 10px;
    background: #ddebfb;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &--no-transition {
    transition: none;
  }
}
</style>
