<template>
  <transition mode="out-in" name="accordion-fade">
    <CollapseCard v-if="showMessage" :is-alert="true" class="mb-2">
      <h3 slot="title-text">Kommentar från handläggare</h3>
      <template slot="body-text">
        <p>{{ message }}</p>
      </template>
    </CollapseCard>
  </transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { hasNewPropertyRejections, translateCategory } from '@/utils/common';

import CollapseCard from '@/components/CollapseCard.vue';

export default {
  components: {
    CollapseCard
  },
  props: {
    category: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      application: state => state.application.application
    }),
    ...mapGetters({
      applicant: 'application/applicant'
    }),
    newPropertyRejections() {
      if (!this.application) {
        return false;
      }
      return hasNewPropertyRejections(
        this.application.applicants,
        this.selectedApplicantIndex,
        this.selectedPossessionIndex,
        this.category
      );
    },
    showMessage() {
      return !this.isApproved && this.newPropertyRejections && !!this.message;
    },
    message() {
      if (!this.applicant) {
        return '';
      }
      switch (this.category) {
        case 'APARTMENTS':
        case 'MEMBERS':
        case 'SWAPDETAILS':
          return this.applicant[translateCategory(this.category)][
            this.selectedPossessionIndex
          ]?.review?.message;
        default:
          return this.applicant[translateCategory(this.category)]?.review
            ?.message;
      }
    },
    isApproved() {
      if (!this.applicant) {
        return false;
      }
      switch (this.category) {
        case 'APARTMENTS':
        case 'MEMBERS':
        case 'SWAPDETAILS':
          return this.applicant[translateCategory(this.category)][
            this.selectedPossessionIndex
          ]?.review?.approved;
        default:
          return this.applicant[translateCategory(this.category)]?.review
            ?.approved;
      }
    }
  }
};
</script>

<style></style>
