import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import subletApplication from './modules/subletApplication';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    app,
    subletApplication
  }
});
