export default {
  isMobile: state => state.screenSize === 'MOBILE',
  isSmallDesktop: state => state.screenSize === 'DESKTOP_SMALL',
  isDesktop: state => state.screenSize === 'DESKTOP',
  isLbMode: state => state.supportAddress.endsWith('lagenhetsbyte.se'),
  menuFlat: state =>
    Object.keys(state.menu)
      .map(category => state.menu[category])
      .flat(),
  tooltipsForScreensize: state =>
    state.tooltips.filter(tip =>
      tip.type.includes(state.screenSize.split('_')[0])
    ),
  getSupportAddress(state) {
    return state.landlord?.hasAltEmailAddress
      ? state.altSupportAddress
      : state.supportAddress;
  },
  filteredMenu(state) {
    const newMenu = {};

    for (const categoryKey in state.menu) {
      const categoryArray = state.menu[categoryKey];

      const blackList = ['APPLICATION', 'STATUS'];

      const filteredCategory = categoryArray.filter(
        item => !blackList.includes(item.statusName)
      );
      if (filteredCategory.length) {
        newMenu[categoryKey] = filteredCategory;
      }
    }

    return newMenu;
  }
};
