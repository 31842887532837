<template>
  <div
    class="field-wrapper"
    :class="{ disabled: isDisabled }"
    :title="
      isDisabled
        ? 'Under granskning, det går inte att göra ändringar för tillfället'
        : ''
    "
  >
    <input
      :id="name"
      :disabled="isDisabled"
      type="checkbox"
      :name="name"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="name" class="custom-checkbox"></label>
    <label :for="name" class="description">
      {{ description }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    checked: {
      type: [Boolean, Number],
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;
  .disabled & {
    cursor: not-allowed;
  }
}
.description {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-dark);
  margin-left: 0.8rem;
  padding: 0.5rem 0;
}
.field-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4.8rem;

  &.disabled {
    .description {
      color: var(--color-disabled);
    }
    .custom-checkbox {
      opacity: 0.2;
    }
  }
}
.custom-checkbox {
  width: 2.4rem;
  height: 2.4rem;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  flex: 0 0 auto;
}
input {
  position: absolute;
  left: -9999px;

  &:checked + .custom-checkbox {
    background: var(--color-blue) url('../assets/svgs/icons/status-check.svg')
      center center / 80%;
    background-repeat: no-repeat;
  }
}
</style>
