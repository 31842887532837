export function getGenitive(name) {
  const nameEnding = name.slice(-1);
  if (nameEnding === 's' || nameEnding === 'x' || nameEnding === 'z') {
    return name;
  }

  return `${name}s`;
}

export function genitiveFromApplicant(applicant) {
  let genitiveName = applicant.members
    .map(member => {
      return member.name.split(' ')[0] || 'Medsökande';
    })
    .join(' och ');

  return getGenitiveNameFallback(genitiveName, false);
}

export function getGenitiveNameFallback(name, onlyFirstName = true) {
  let genitiveName =
    (onlyFirstName ? name.split(' ')[0] : name) || 'Medsökande';

  return getGenitive(genitiveName);
}

export default {
  genitiveFromApplicant,
  getGenitiveNameFallback,
  getGenitive
};
