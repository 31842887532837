import wasCreatedBeforeSplitRelease from './wasCreatedBeforeSplitRelease';
import {
  validatePostalCode,
  validateNumber,
  validEmail,
  validatePersonalNumber,
  haveSameSwapAddressSelected,
  validateFullName
} from './input-validations';
import { getAddressDescription, getActiveMemberSwapAddresses } from './common';

function selectedSwapAddress(applicants, applicantIndex, apartmentIndex) {
  const swapAddresses = getActiveMemberSwapAddresses(
    applicants,
    applicantIndex
  );
  return swapAddresses[apartmentIndex];
}

function useSplitDocument(application, applicant, category) {
  // Older application using system before release of document split
  if (wasCreatedBeforeSplitRelease(application)) {
    return false;
  }

  // Members sharing apartment only need to upload invoice/agreement once and
  // is therefore a "shared category"
  if (
    ['LEASE_AGREEMENT', 'RENTAL_INVOICE'].includes(category) &&
    applicant.members.length > applicant.apartments.length
  ) {
    return false;
  }

  return true;
}

export function validateAll(application) {
  return application.applicants.map((applicant, applicantIndex) => {
    return {
      APARTMENTS: applicant.apartments.map(apartment => ({
        address: {
          label: 'Adress',
          value: apartment.address,
          valid: true
        },
        postalCode: {
          label: 'Postnummer',
          value: apartment.postalCode,
          valid: validatePostalCode(apartment.postalCode)
        },
        postalAddress: {
          label: 'Ort',
          value: apartment.postalAddress,
          valid: true
        },
        objectNumber: {
          label: 'Objektnummer/kontraktsnummer',
          value: apartment.objectNumber,
          valid: true
        },
        apartmentNumber: {
          label: 'Lägenhetsnummer',
          value: apartment.apartmentNumber,
          valid: validateNumber(apartment.apartmentNumber)
        },
        rooms: {
          label: 'Antal rum',
          value: apartment.rooms,
          valid: validateNumber(apartment.rooms)
        },
        sqm: {
          label: 'Yta',
          value: apartment.sqm,
          valid: validateNumber(apartment.sqm)
        },
        rent: {
          label: 'Hyra',
          value: apartment.rent,
          valid: validateNumber(apartment.rent)
        },
        contractStartDate: {
          label: 'Innehaft sedan',
          value: apartment.contractStartDate,
          valid: true
        },
        adultCount: {
          label: 'Antal vuxna',
          value: apartment.adultCount,
          valid: validateNumber(apartment.adultCount)
        },
        childCount: {
          label: 'Antal barn',
          value: apartment.childCount,
          valid: validateNumber(apartment.childCount)
        },
        landlordName: {
          label: 'Nuvarande hyresvärd',
          value: apartment.landlordName,
          valid: true
        },
        landlordEmail: {
          label: 'Hyresvärdens telefonnummer',
          value: apartment.landlordEmail,
          valid: true
        },
        landlordPhone: {
          label: 'Hyresvärdens e-postadress',
          value: apartment.landlordPhone,
          valid: true
        }
      })),
      MEMBERS: applicant.members.map((member, index) => ({
        name: {
          label: 'Fullständigt namn',
          value: member.name,
          valid: validateFullName(member.name)
        },
        email: {
          label: 'E-post',
          value: member.email,
          valid: validEmail(member.email)
        },
        phone: {
          label: 'Telefonnummer',
          value: member.phone,
          valid: validateNumber(member.phone)
        },
        ssn: {
          label: 'Personnummer',
          value: member.ssn,
          valid: validatePersonalNumber(member.ssn)
        },
        optAddress: {
          label: 'Adress',
          value: member.optAddress,
          valid: true
        },
        optPostalCode: {
          label: 'Postnummer',
          value: member.optPostalCode,
          valid: validatePostalCode(member.optPostalCode)
        },
        optPostalAddress: {
          label: 'Ort',
          value: member.optPostalAddress,
          valid: true
        },
        otherPropertyOwnershipDescription: {
          label: 'Annat fastighetsinnehav',
          value: member.otherPropertyOwnershipDescription ? 'Ja' : 'Nej',
          valid: true
        },
        contracted: {
          label: `Står på hyreskontraktet för Lägenhet ${getAddressDescription(
            applicant,
            applicantIndex,
            index
          )}`,
          value: member.contracted ? 'Ja' : 'Nej',
          valid: true
        }
      })),
      SWAPREASON: [
        {
          description: {
            label: 'Skäl',
            value: applicant.swapReason.description,
            valid: true
          }
        }
      ],
      SWAPDETAILS: applicant.swapDetails.map((swapDetail, detailIndex) => ({
        adultCount: {
          label: 'Antal vuxna',
          value: swapDetail.adultCount,
          valid: validateNumber(swapDetail.adultCount)
        },
        childCount: {
          label: 'Antal barn',
          value: swapDetail.childCount,
          valid: validateNumber(swapDetail.childCount)
        },
        swapDate: {
          label: 'Önskat bytesdatum',
          value: swapDetail.swapDate,
          valid: true
        },
        apartmentIndex: {
          label: 'Adress:',
          value: selectedSwapAddress(
            application.applicants,
            applicantIndex,
            swapDetail.apartmentIndex
          ),
          valid: !haveSameSwapAddressSelected(
            application.applicants,
            applicantIndex,
            detailIndex
          )
        }
      })),
      CREDIT_INFORMATION: getDocumentGroups(
        application,
        applicant,
        'CREDIT_INFORMATION'
      ).map(group => group.CREDIT_INFORMATION.documents),
      FAMILY_CERTIFICATE: getDocumentGroups(
        application,
        applicant,
        'FAMILY_CERTIFICATE'
      ).map(group => group.FAMILY_CERTIFICATE.documents),
      INCOME_BASIS: getDocumentGroups(
        application,
        applicant,
        'INCOME_BASIS'
      ).map(group => group.INCOME_BASIS.documents),
      LEASE_AGREEMENT: getDocumentGroups(
        application,
        applicant,
        'LEASE_AGREEMENT'
      ).map(group => group.LEASE_AGREEMENT.documents),
      RENTAL_INVOICE: getDocumentGroups(
        application,
        applicant,
        'RENTAL_INVOICE'
      ).map(group => group.RENTAL_INVOICE.documents),
      OTHER: getDocumentGroups(application, applicant, 'OTHER').map(
        group => group.OTHER.documents
      )
    };
  });
}

export function getDocumentGroups(application, applicant, category) {
  if (!useSplitDocument(application, applicant, category)) {
    return [applicant.documentGroups[0]];
  } else if (applicant.members.length === 1) {
    return [applicant.documentGroups[0]];
  }
  return applicant.documentGroups;
}
