<template>
  <span class="text-icon">
    <BaseImage
      v-if="!alignRight"
      :url="iconUrl"
      :class="['icon', size]"
    ></BaseImage>
    <slot></slot>
    <BaseImage
      v-if="alignRight"
      :url="iconUrl"
      :class="['icon', 'icon--right', size]"
    ></BaseImage>
  </span>
</template>

<script>
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'BaseIconText',
  components: { BaseImage },
  props: {
    iconUrl: {
      type: [String, null],
      default: null
    },

    size: {
      type: [String, null],
      default: null
    },

    alignRight: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-flex;
  height: 20px;
  width: 20px;
  margin-right: 12px;
  &--right {
    margin-right: 0;
    margin-left: 12px;
  }
}

.text-icon {
  display: flex;
  align-items: center;
}

.larger {
  height: 24px;
  width: 24px;
}
</style>
