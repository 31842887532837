import Vue from 'vue';
import Router from 'vue-router';
import SwapApplicantManager from '../components/SwapApplicantManager.vue';
import Apartments from '../views/swap/Apartments.vue';
import Members from '../views/swap/Members.vue';
import SwapReason from '../views/swap/SwapReason.vue';
import SwapDetails from '../views/swap/SwapDetails.vue';
//import Offers from '../views/swap/Offers.vue';
import CreditInformation from '../views/swap/CreditInformation.vue';
import FamilyCertificate from '../views/swap/FamilyCertificate.vue';
import IncomeBasis from '../views/swap/IncomeBasis.vue';
import LeaseAgreement from '../views/swap/LeaseAgreement.vue';
import RentalInvoice from '../views/swap/RentalInvoice.vue';
import OtherDocuments from '../views/swap/OtherDocuments.vue';
import StatusView from '../views/swap/StatusView.vue';
import ApplicantSelector from '../views/swap/ApplicantSelector.vue';
import Application from '../views/swap/Application.vue';
import LoginView from '../views/swap/LoginView.vue';

Vue.use(Router);

const routes = [
  {
    path: '/lagenhet',
    component: SwapApplicantManager,
    children: [
      {
        path: '',
        component: Apartments,
        name: 'APARTMENTS',
        meta: {
          title: 'Lägenhet'
        }
      },
      {
        path: '/boenden',
        component: Members,
        name: 'MEMBERS',
        meta: {
          title: 'Boenden'
        }
      },
      {
        path: '/skal-till-bytet',
        component: SwapReason,
        name: 'SWAPREASON',
        meta: {
          title: 'Skäl till bytet'
        }
      },
      {
        path: '/bytesdetaljer',
        component: SwapDetails,
        name: 'SWAPDETAILS',
        meta: {
          title: 'Bytesdetaljer'
        }
      }
    ]
  },
  // {
  //   path: '/erbjudanden',
  //   component: Offers,
  //   meta: {
  //     title: 'Erbjudanden'
  //   }
  // },
  {
    path: '/ladda-upp/minuc',
    component: CreditInformation,
    name: 'CREDIT_INFORMATION',
    meta: {
      title: 'Min UC'
    }
  },
  {
    path: '/ladda-upp/personbevis',
    component: FamilyCertificate,
    name: 'FAMILY_CERTIFICATE',
    meta: {
      title: 'Familjebevis'
    }
  },
  {
    path: '/ladda-upp/inkomstbevis',
    component: IncomeBasis,
    name: 'INCOME_BASIS',
    meta: {
      title: 'Inkomstunderlag'
    }
  },
  {
    path: '/ladda-upp/hyresavtal',
    component: LeaseAgreement,
    name: 'LEASE_AGREEMENT',
    meta: {
      title: 'Hyresavtal'
    }
  },
  {
    path: '/ladda-upp/aktuell-hyra',
    component: RentalInvoice,
    name: 'RENTAL_INVOICE',
    meta: {
      title: 'Aktuell hyra'
    }
  },
  {
    path: '/ladda-upp/ovriga-dokument',
    component: OtherDocuments,
    name: 'OTHER',
    meta: {
      title: 'Övriga dokument'
    }
  },
  {
    path: '/status',
    component: StatusView,
    meta: {
      title: 'Status'
    }
  },
  {
    path: '/valj-byte',
    component: ApplicantSelector,
    meta: {
      title: 'Välj byte'
    }
  },
  {
    path: '/byteskedja',
    component: Application,
    meta: {
      title: 'Byteskedja'
    }
  },
  {
    path: '/logga-in',
    component: LoginView,
    meta: {
      title: 'Logga in'
    }
  },
  {
    path: '/',
    redirect: '/boenden'
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  }
});

router.afterEach(() => {
  if (router.app.$store) {
    //Bypass index reset, give ability to set a specific index and then route user
    //eg. route user to apartments, with applicantindex 2 and apartment 2
    if (router.app.$store.state.app.skipRouterIndexReset) {
      router.app.$store.commit('app/setSkipRouterIndexReset', false);
    } else {
      router.app.$store.commit('application/setSelectedApplicantIndex', 0);
      router.app.$store.commit('application/setSelectedPossessionIndex', 0);
    }
  }
});

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default router;
