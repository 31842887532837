<template>
  <InfoModal title="Information" @click="$emit('click')">
    <div class="container">
      <p>
        Fyll i formuläret och ladda upp aktuella dokument. Du kan alltid spara
        och återkomma där du var senast.
      </p>
      <br />
      <p>
        <strong>Statusar</strong>
      </p>
      <ul>
        <li>
          <StatusCircle class="status-circle" />
          <span>Ej påbörjad</span>
        </li>
        <li>
          <StatusCircle status="INCOMPLETE" class="status-circle" /><span
            >Påbörjad, men inte färdigställd</span
          >
        </li>
        <li>
          <StatusCircle status="INVALID" class="status-circle" /><span
            >Innehåller ett eller flera fel</span
          >
        </li>
        <li>
          <StatusCircle status="COMPLETE" class="status-circle" /><span
            >Färdigställd</span
          >
        </li>
        <li>
          <StatusCircle status="COMMENT" class="status-circle" /><span
            >En handläggare hos Bytesansökan har kommenterat</span
          >
        </li>
        <li>
          <StatusCircle status="APPROVED" class="status-circle" /><span
            >Har granskats av en handläggare på Bytesansökan
          </span>
        </li>
      </ul>
    </div>
  </InfoModal>
</template>

<script>
import InfoModal from '@/components/InfoModal.vue';
import StatusCircle from '@/components/StatusCircle.vue';

export default {
  components: {
    InfoModal,
    StatusCircle
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 450px;
  font-size: 16px;

  @media ($mobile) {
    max-width: 100%;
  }
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

li > span {
  margin-left: 20px;
}

ul {
  padding: 0;
  margin: 0;

  margin-top: 10px;
}

.status-circle {
  flex-shrink: 0;
}
</style>
