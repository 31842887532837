<template>
  <MainHeader
    :show-logo="appReady"
    :show-login-btn="
      appReady && !authenticated && isMobile && !isVerifyEmailPath
    "
    :is-mobile="isMobile"
    :is-swap-header="isSwapHeader"
    :show-right-container="authenticated && !isVerifyEmailPath"
    :side-menu-open="sideMenuOpen"
    :landlord="landlord"
    @toggleMenuClick="toggleSideMenuVisible(!sideMenuOpen)"
    @showStatusClick="$emit('showStatusClick')"
    @swapClick="swapClick"
    @logoutClick="logout()"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MainHeader from '@/components/MainHeader.vue';

export default {
  components: { MainHeader },

  props: {
    sideMenuOpen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      isSwapHeader: true
    };
  },

  computed: {
    ...mapState({
      appReady: state => state.app.appReady,
      authenticated: state => state.app.authenticated,
      application: state => state.application.application,
      landlord: state => state.app.landlord
    }),
    ...mapGetters({
      isMobile: 'app/isMobile'
    }),
    isVerifyEmailPath() {
      return this.$router.currentRoute.path.startsWith('/verifiera-epost');
    }
  },
  methods: {
    ...mapActions({
      logout: 'app/logout',
      clearInviteAndReset: 'app/clearInviteAndReset',
      toggleSideMenuVisible: 'app/toggleSideMenuVisible'
    }),
    async swapClick() {
      await this.$router.push('/valj-byte');
      this.toggleSideMenuVisible(false);
      this.clearInviteAndReset();
    }
  }
};
</script>
