import Vue from 'vue';
import Router from 'vue-router';
import ApplicantManager from '@/components/ApplicantManager.vue';
import CurrentTenants from '@/views/sublet/CurrentTenants.vue';
import Apartment from '@/views/sublet/Apartment.vue';
import Reason from '@/views/sublet/Reason.vue';
import SubletTenantInformation from '@/views/sublet/SubletTenantInformation.vue';
import DataSummary from '@/views/sublet/DataSummary.vue';
import Documents from '@/views/sublet/Documents.vue';
import Status from '@/views/sublet/Status.vue';
import Login from '@/views/sublet/Login.vue';

Vue.use(Router);

const routes = [
  {
    path: '/uppgifter',
    component: ApplicantManager,
    children: [
      {
        path: '',
        component: CurrentTenants,
        name: 'currentTenants',
        meta: {
          title: 'Om dig som ska hyra ut'
        }
      },
      {
        path: '/lagenhet',
        component: Apartment,
        name: 'apartment',
        meta: {
          title: 'Beskriv lägenheten du ska hyra ut'
        }
      },
      {
        path: '/skal',
        component: Reason,
        name: 'reason',
        meta: {
          title: 'Ange skäl'
        }
      },
      {
        path: '/andrahandshyresgast',
        component: SubletTenantInformation,
        name: 'secondaryTenants',
        meta: {
          title: 'Uppgifter på andrahandshyresgäst'
        }
      },
      {
        path: '/granska',
        component: DataSummary,
        meta: {
          title: 'Förhandsgranska'
        }
      }
    ]
  },
  {
    path: '/dokument',
    component: Documents,
    meta: {
      title: 'Ladda upp filer'
    }
  },
  {
    path: '/status',
    component: Status,
    meta: {
      title: 'Vad händer nu?'
    }
  },
  {
    path: '/logga-in',
    component: Login,
    meta: {
      title: 'Logga in'
    }
  },
  {
    path: '/',
    redirect: '/uppgifter'
  },
  { path: '*', redirect: '/' }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  }
});

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default router;
