<template>
  <MainHeader
    :show-logo="true"
    :show-login-btn="!isLoading && !authenticated && isMobile"
    :is-mobile="isMobile"
    :show-right-container="authenticated"
    :side-menu-open="sideMenuOpen"
    :landlord="landlord"
    :default-logo-src="andrahandLogo"
    default-logo-alt="Andrahand logo"
    @showSubletInformationClick="$emit('showSubletInformationClick')"
    @toggleMenuClick="toggleSideMenuVisible(!sideMenuOpen)"
    @logoutClick="logout()"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MainHeader from '@/components/MainHeader.vue';
import andrahandLogo from '@/assets/svgs/andrahand.svg';

export default {
  components: { MainHeader },

  props: {
    sideMenuOpen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      andrahandLogo
    };
  },

  computed: {
    ...mapState({
      isLoading: state => state.app.isLoading,
      authenticated: state => state.app.authenticated,
      landlord: state => state.app.landlord
    }),
    ...mapGetters({
      isMobile: 'app/isMobile'
    })
  },
  methods: {
    ...mapActions({
      logout: 'app/logout',
      toggleSideMenuVisible: 'app/toggleSideMenuVisible'
    })
  }
};
</script>
