<template>
  <transition name="scale">
    <div
      :class="[
        'animated-checkbox-wrapper',
        'flex-center',
        { 'is-large': isLarge }
      ]"
      @animationend="done"
    >
      <slot />
      <div v-if="showAnimation" class="flex-center">
        <svg
          :class="['checkmark', { 'is-large-checkmark': isLarge }]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showAnimation: {
      type: Boolean,
      required: false,
      default: true
    },

    isLarge: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      animationsDone: 0
    };
  },

  methods: {
    done() {
      this.animationsDone++;
      if (this.animationsDone >= 4) this.$emit('done');
    }
  }
};
</script>

<style lang="scss" scoped>
.mgt-20 {
  margin-top: 20px;
}

.animated-checkbox-wrapper {
  backdrop-filter: blur(4px);

  padding: 2px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

// Misc
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--color-green);
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.is-large-checkmark {
  width: 50px;
  height: 50px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--color-green);
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--color-green);
  }
}
</style>
