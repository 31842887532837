<template>
  <ProcessModal
    title="Var är jag i processen?"
    :active-index="processIndex"
    :timeline="dynamicTimeline"
    :main-index="applicationIndex"
    :participants="applicantsStatuses"
    :is-loading="fetchingStatuses"
    @click="$emit('close')"
  />
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { readableNames, getInitials } from '@/utils/common';
import ProcessModal from '@/components/ProcessModal.vue';

export default {
  name: 'StatusModal',
  components: {
    ProcessModal
  },
  data() {
    return {
      fetchingStatuses: false
    };
  },
  computed: {
    ...mapState({
      application: state => state.application.application
    }),
    ...mapGetters({
      isLb: 'application/isLb',
      applicant: 'application/applicant',
      isSingleOwner: 'application/isSingleOwner',
      connectedLandlordApplicants: 'application/connectedLandlordApplicants',
      allSummarized: 'appValidation/allSummarized'
    }),
    applicationIndex() {
      if (!this.applicant) {
        return 0;
      }
      return this.getIndexFromStatus(this.application.status);
    },
    applicantsStatuses() {
      if (!this.application) {
        return [];
      }
      return this.application.applicants.map(applicant => ({
        name: getInitials(applicant.members[0].name),
        index: this.getIndexFromStatus(applicant.status)
      }));
    },
    dynamicTimeline() {
      if (!this.application) {
        return [];
      }

      const { connected, notConnected } = this.connectedLandlordApplicants;

      const connectedPlural = connected.length > 1;
      const notConnectedPlural = notConnected.length > 1;

      let modalMessages = [
        'Skapa en byteskedja med alla lägenheter som ingår i ert byte.',
        'Fyll i formulären och ladda upp dokument för varje part i bytet. Vi på Bytesansökan granskar bytet under tiden.',
        'Det ser ut som att du är klar! Alla uppgifter är inkomna. Vänta tills Bytesansökan godkänt bytets uppgifter så att ni kan signera och slutföra ansökan.',
        '<strong>Alla parter behöver signera sina uppgifter.</strong> En signeringslänk har skickats till parterna via e-post och SMS. <strong>OBS!</strong> Det kan dröja någon dag innan en signering registreras hos oss.'
      ];

      if (this.isSingleOwner || this.isLb) {
        modalMessages = [
          'Byteskedja med alla bytesparter skapad.',
          '<strong>Lämna uppgifter.</strong> Fyll i formulären och ladda upp de dokument som krävs. Vi på Bytesansökan granskar uppgifterna under tiden.',
          'Alla uppgifter är ifyllda. Det som kvarstår nu är att invänta godkännande av uppgifterna så att ni kan signera och slutföra ansökan.',
          '<strong>Signera uppgifter.</strong> En signeringslänk har skickats till dig via e-post och SMS. <strong>OBS!</strong> Det kan dröja någon dag innan en signering registreras hos oss.'
        ];
      }

      let notConnectedText =
        '<strong>OBS!</strong><br/>Från det att alla handlingar är inskickade från samtliga hyresgäster är handläggningstiden upp till 3 månader. Lycka till! 🤗';

      if (notConnected.length > 0) {
        notConnectedText = `<strong>OBS!</strong><br/>${readableNames(
          notConnected,
          true,
          true
        )} ${
          notConnectedPlural ? `hyresvärdar` : `hyresvärd`
        } använder<strong> inte denna tjänst</strong>. ${
          notConnectedPlural ? `De` : `Denne`
        } behöver därför utöver detta ansöka om ett byte hos sin hyresvärd. 
      Från det att alla handlingar är inskickade från samtliga hyresgäster är handläggningstiden upp till 3 månader. Lycka till! 🤗`;
      }

      if (this.isLb) {
        modalMessages.push(
          `<strong>Alla parter har signerat!</strong> Vi paketerar nu samtliga dokument och skickar dem till era hyresvärdar.
          <br/><br/>
          <strong>OBS!</strong><br/>Från det att alla handlingar är inskickade från samtliga hyresgäster är handläggningstiden 
          upp till 3 månader. Lycka till! 🤗`
        );
      } else {
        if (connected.length > 0) {
          modalMessages.push(
            `<strong>Alla parter har signerat!</strong> Vi paketerar nu samtliga dokument och skickar dem till ${readableNames(
              connected,
              true,
              true
            )} ${connectedPlural ? `hyresvärdar` : `hyresvärd`} eftersom ${
              connectedPlural ? `de är anslutna` : `denne är ansluten`
            } till oss.<br/><br/> ${notConnectedText}`
          );
        } else {
          modalMessages.push(
            `<strong>Alla parter har signerat!</strong>
            ${notConnectedText}`
          );
        }
      }

      return modalMessages;
    },
    processIndex() {
      if (!this.application) {
        return 0;
      }
      if (this.application.status) {
        if (this.application.status.description === 'SENT') {
          return 6;
        }
        if (this.application.status.description === 'READY') {
          return 5;
        }
        if (this.application.status.description === 'AWAITINGSIGN') {
          return 4;
        }

        if (
          this.allSummarized &&
          Object.values(this.allSummarized).every(status =>
            ['COMPLETE', 'APPROVED'].includes(status)
          )
        ) {
          return 2;
        }
      }
      if (this.application.applicants.every(a => !!a.members[0].name)) {
        return 1;
      }
      return 0;
    }
  },
  async created() {
    this.fetchingStatuses = true;

    await this.syncApplicationStatuses();

    this.fetchingStatuses = false;
  },
  methods: {
    ...mapActions({
      syncApplicationStatuses: 'application/syncApplicationStatuses'
    }),
    getIndexFromStatus(status) {
      if (!status) {
        return 0;
      }

      switch (status) {
        case 'SENT':
        case 'READY':
          return 4;
        case 'AWAITINGSIGN':
          return 3;
        case 'TREATING':
          return 2;
        case 'ACTIVE':
          return 1;
        default:
          return 0;
      }
    }
  }
};
</script>
