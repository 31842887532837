import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import application from './modules/application';
import appValidation from './modules/appValidation';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    app,
    application,
    appValidation
  }
});
