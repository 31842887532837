<template>
  <SwapInput
    :value="value"
    :label="label"
    :description="description"
    :dropdown="dropdown.length > 0 ? dropdown : null"
    :class="{
      searchbar: true,
      'show-icon': value?.trim() === ''
    }"
    v-bind="$attrs"
    :show-green-checkmark="showGreenCheckmark"
    :is-loading="isSearchingLandlord"
    v-on="$listeners"
    @dropdownClick="dropdownClick"
    @blur="handleBlur"
  />
</template>

<script>
import debounce from 'lodash.debounce';
import applicationService from '@/services/applicationService';
import { generateHighlightedHtml } from '@/utils/common';

import SwapInput from '@/components/SwapInput.vue';

export default {
  components: {
    SwapInput
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: null,
      required: false
    },
    description: {
      type: String,
      default: null,
      required: false
    },
    showGreenCheckmark: {
      type: Boolean,
      default: true
    },
    landlord: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchResult: [],
      searchResultAccumulated: [],
      isSearchingLandlord: false
    };
  },
  computed: {
    dropdown() {
      return generateHighlightedHtml(
        this.landlordDropdownListOrder,
        this.value
      ).sort((a, b) => b.points - a.points);
    },
    landlordDropdownListOrder() {
      if (!this.searchResult?.length) {
        return [];
      }
      const sortedLandlords = [];
      const unsortedLandlords = [];

      for (let i = 0; i < this.searchResult.length; i++) {
        const landlord = this.searchResult[i];

        if (landlord.landlordId !== 0) {
          sortedLandlords.push(landlord);
        } else {
          unsortedLandlords.push(landlord);
        }
      }

      return [...sortedLandlords, ...unsortedLandlords];
    }
  },
  watch: {
    value(val = '') {
      const trimmedVal = val.trim();
      this.searchLandlordDebounced(trimmedVal);
    }
  },
  async created() {
    this.searchLandlordDebounced = debounce(this.searchLandlord, 400);

    await this.searchLandlord(this.value || '');
  },
  methods: {
    merge(array1, array2, prop) {
      return array1
        .filter(item1 => !array2.find(item2 => item1[prop] === item2[prop]))
        .concat(array2);
    },
    async searchLandlord(query) {
      this.isSearchingLandlord = true;
      if (!query.length && this.landlord?.isParentLandlord) {
        this.searchResult =
          (await applicationService.getParentLandLords(
            this.landlord?.isParentLandlord
          )) || [];
      } else {
        this.searchResult =
          (await applicationService.searchLandLord(query)) || [];
      }

      this.searchResultAccumulated = this.merge(
        this.searchResultAccumulated,
        this.searchResult,
        'name'
      );
      this.isSearchingLandlord = false;
    },
    dropdownClick(value) {
      const match = this.checkForMatches(value);
      if (match) {
        this.$emit('dropdownMatch', match);
      } else {
        this.$emit('dropdownNoMatch');
      }
    },
    handleBlur() {
      const match = this.checkForMatches(this.value);
      if (!match) {
        this.$emit('dropdownNoMatch');
      }
    },
    checkForMatches(value) {
      const match = this.searchResultAccumulated.find(
        result => result.name === value
      );

      if (match) {
        this.searchLandlordDebounced.cancel();
        return match;
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.searchbar :deep(input) {
  background-size: 22px;
  background-position: 20px center;
  background-repeat: no-repeat;
  background-image: none;
}

.searchbar.show-icon :deep(input) {
  padding-left: 54px;
  background-image: url('../assets/svgs/icons/magnifying-glass.svg');
}
</style>
