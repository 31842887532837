import axios from 'axios';
import auth from '@/services/auth';

let baseUrl = 'https://api.bytesansokan.se';

if (location.host.includes('localhost')) {
  baseUrl = 'http://localhost:3001';
} else if (location.host.includes('test') || location.host.includes('demo')) {
  baseUrl = 'https://dev-api.bytesansokan.se';
}

const apiEndpoint = baseUrl;

const apiInstance = axios.create({
  baseURL: apiEndpoint,
  withCredentials: true,
  'content-Type': 'application/json'
});

function handleUnauth(error) {
  if (error.response?.status === 401) {
    localStorage.clear();
    window.location.href = '/logga-in';
  } else {
    throw error;
  }
}

async function get(path) {
  try {
    const res = await apiInstance.get(path, {
      headers: await auth.getAuthHeader()
    });
    return res.data;
  } catch (error) {
    handleUnauth(error);
  }
}

async function del(path) {
  try {
    const res = await apiInstance.delete(path, {
      headers: await auth.getAuthHeader()
    });
    return res.data;
  } catch (error) {
    handleUnauth(error);
  }
}

async function put(path, data) {
  try {
    const res = await apiInstance.put(path, data, {
      headers: await auth.getAuthHeader()
    });
    return { data: res.data, status: res.status };
  } catch (error) {
    handleUnauth(error);
  }
}

async function post(path, data) {
  try {
    const res = await apiInstance.post(path, data, {
      headers: await auth.getAuthHeader()
    });
    return { data: res.data, status: res.status };
  } catch (error) {
    handleUnauth(error);
  }
}

export default {
  get,
  put,
  post,
  del,
  apiEndpoint,
  handleUnauth
};
