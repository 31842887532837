import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  supportAddress: 'support@bytesansokan.se',
  altSupportAddress: 'kundservice@bytesansokan.se',
  authenticated: false,
  appReady: false,
  isInitiating: false,
  screenSize: 'DESKTOP',
  account: {},
  landlord: undefined,
  inviteCode: undefined,
  sideMenuVisible: false,
  sideMenuDisabled: false,
  skipRouterIndexReset: false, //Router resets applicant/possesionIndex after each route change, this will bypass that once
  tooltips: [
    {
      reference: 'PageHeader-Lägenhet',
      type: ['MOBILE', 'DESKTOP'],
      seen: false,
      conditions: [],
      content: [
        'Här visas vem av bytesparterna du håller på att fylla i uppgifter åt. När du är klar med en sida klickar du på knappen "Spara och gå vidare" som finns under formuläret',
        'Om du fyller i åt någon annan kan du enkelt växla bytespart genom att trycka på namnet.'
      ]
    },
    {
      reference: 'mobileMenu',
      type: ['MOBILE'],
      seen: false,
      conditions: [],
      content: [
        'Här navigerar du mellan de olika delarna i formulären.',
        'Du ser också vilken status varje del har. När cirkeln är grön har vi alla uppgifter vi behöver.'
      ]
    },
    {
      reference: 'APARTMENTS',
      type: ['DESKTOP'],
      seen: false,
      conditions: [],
      content: [
        'Här navigerar du mellan de olika delarna i formulären.',
        'Du ser också vilken status varje del har. När cirkeln är grön har vi alla uppgifter vi behöver.'
      ]
    },
    {
      reference: 'PageHeader-Boenden',
      type: ['DESKTOP', 'MOBILE'],
      seen: false,
      conditions: [
        { property: 'members', childProperty: 'ssn', isFalsy: false },
        {
          property: 'members',
          childProperty: 'phone',
          isFalsy: true
        }
      ],
      content: [
        'Vi har förifyllt vissa fält gällande din folkbokföringsadress, eftersom du loggade in med BankID.',
        'Om något inte skulle stämma är det bara att ändra.'
      ]
    }
  ],
  menu: {
    Byteskedja: [
      {
        label: 'Skapa byteskedja',
        url: '/byteskedja',
        statusName: 'APPLICATION'
      }
    ],
    Grundläggande: [
      {
        label: 'Lägenhet',
        url: '/lagenhet',
        statusName: 'APARTMENTS'
      },
      {
        label: 'Boenden',
        url: '/boenden',
        statusName: 'MEMBERS'
      },
      {
        label: 'Skäl till bytet',
        url: '/skal-till-bytet',
        statusName: 'SWAPREASON'
      },
      {
        label: 'Bytesdetaljer',
        url: '/bytesdetaljer',
        statusName: 'SWAPDETAILS'
      }
      // {
      //   label: 'Erbjudanden',
      //   url: '/erbjudanden',
      //   statusName: null
      // }
    ],
    Dokument: [
      {
        label: 'Min UC',
        url: '/ladda-upp/minuc',
        statusName: 'CREDIT_INFORMATION'
      },
      {
        label: 'Familjebevis',
        url: '/ladda-upp/personbevis',
        statusName: 'FAMILY_CERTIFICATE'
      },
      {
        label: 'Inkomstunderlag',
        url: '/ladda-upp/inkomstbevis',
        statusName: 'INCOME_BASIS'
      },
      {
        label: 'Hyresavtal',
        url: '/ladda-upp/hyresavtal',
        statusName: 'LEASE_AGREEMENT'
      },
      {
        label: 'Aktuell hyra',
        url: '/ladda-upp/aktuell-hyra',
        statusName: 'RENTAL_INVOICE'
      },
      {
        label: 'Övriga dokument',
        url: '/ladda-upp/ovriga-dokument',
        statusName: 'OTHER'
      },
      {
        label: 'Vad händer nu?',
        url: '/status',
        statusName: 'STATUS'
      }
    ]
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
