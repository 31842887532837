<template>
  <img v-bind="$attrs" :src="url" v-on="$listeners" />
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    url: {
      type: String,
      required: true,
      default: null
    }
  },

  watch: {
    url(newUrl, oldUrl) {
      if (newUrl !== oldUrl && newUrl !== null) {
        this.load();
      }
    }
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.$nextTick(() => {
        const image = new Image();

        image.onload = () => {
          this.$emit('done');
        };

        image.src = this.url || '';
      });
    }
  }
};
</script>
