function setInitData(state, { authenticated, appReady, account }) {
  state.appReady = appReady;
  state.authenticated = authenticated;
  state.account = account;
}

function setAppReady(state, appReady) {
  state.appReady = appReady;
}

function setIsInitiating(state, isInitiating) {
  state.isInitiating = isInitiating;
}

function setScreenSize(state, newSize) {
  state.screenSize = newSize;
}

function setLandlord(state, landlord) {
  state.landlord = landlord;
}

function setSupportAddress(state, supportAddress) {
  state.supportAddress = supportAddress;
}

function setInviteCode(state, inviteCode) {
  state.inviteCode = inviteCode;
}

function setSideMenuVisible(state, visible) {
  state.sideMenuVisible = visible;
}

function markTooltipAsSeen(state, reference) {
  const index = state.tooltips
    .map(tooltip => tooltip.reference)
    .indexOf(reference);

  if (index >= 0) {
    state.tooltips[index].seen = true;
  }
}

function clearSyncedTooltips(state, reference) {
  const index = state.tooltips
    .map(tooltip => tooltip.reference)
    .indexOf(reference);

  if (index >= 0) {
    state.tooltips.splice(index, 1);
  }
}

function toggleSideMenuDisabled(state, isDisabled) {
  state.sideMenuDisabled = isDisabled;
}

function setSkipRouterIndexReset(state, skip) {
  state.skipRouterIndexReset = skip;
}

export default {
  setInitData,
  setScreenSize,
  setLandlord,
  setSupportAddress,
  setInviteCode,
  markTooltipAsSeen,
  clearSyncedTooltips,
  setIsInitiating,
  setSideMenuVisible,
  toggleSideMenuDisabled,
  setAppReady,
  setSkipRouterIndexReset
};
