import subletRouter from '@/router/subletRouter';
import auth from '@/services/auth';
import applicationService from '@/services/applicationService';
import { getAndsetScreenSize as _getAndsetScreenSize } from '@/utils/common';

function getQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    page: urlParams.get('page')
  };
}
function getRedirectPath() {
  let redirectPath = '/logga-in';
  const pathArr = window.location.pathname.split('/');
  const page = pathArr[1];
  const params = [];

  // Example: trying to access andrahand.se/granska/1337, but not authed
  // save info, later on in the Vue router(router/index.js)
  // we'll check these params and route user correctly
  if (page) {
    params.push(`page=${page}`);
  }

  return params.length > 0
    ? `${redirectPath}?${params.join('&')}`
    : redirectPath;
}
async function initiate(ctx) {
  ctx.commit('setIsLoading', true);

  if (!ctx.rootState.applicationId) {
    const localStorageApplicationId = localStorage.getItem('applicationId');
    if (localStorageApplicationId) {
      ctx.commit(
        'subletApplication/setApplicationId',
        localStorageApplicationId,
        {
          root: true
        }
      );
    }
  }

  const valid = await auth.validateSession();

  if (valid) {
    const landlordIdWithFallback = ctx.state.landlord?.landlordId || '169223';

    await ctx.dispatch(
      'subletApplication/fetchSubletApplication',
      landlordIdWithFallback,
      {
        root: true
      }
    );

    const { page } = getQueryParams();

    if (page) {
      if (page === 'logga-in') {
        await subletRouter.push('/');
      } else {
        // Stay on the current page
        await subletRouter.push(page);
      }
    } else if (window.location.pathname === '/logga-in') {
      await subletRouter.push('/');
    }
  } else {
    // Not authed
    clearUserLocalStorage();

    if (window.location.pathname !== '/logga-in') {
      await subletRouter.push(getRedirectPath());
    }
  }

  ctx.commit('setInitData', {
    appReady: true,
    authenticated: valid
  });

  ctx.commit('setIsLoading', false);
}
async function clearUserLocalStorage() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');
}
async function getNextPath(ctx) {
  const currentIndex = ctx.getters.menu.findIndex(
    item => item.url === subletRouter.currentRoute.path
  );

  const next = ctx.getters.menu[currentIndex + 1];
  if (next) {
    return next.url;
  }

  return ctx.getters.menu[0].url;
}
async function goToNextCategory(ctx) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  const nextPath = await ctx.dispatch('getNextPath');
  await subletRouter.push({
    path: nextPath
  });
}
async function getAndsetScreenSize({ state, commit }) {
  await _getAndsetScreenSize(state, commit);
}
async function toggleSideMenuVisible(ctx, show) {
  const body = document.querySelector('body');
  if (show) {
    this.scrollPosition = window.scrollY;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${this.scrollPosition}px`;
    body.style.width = '100%';
    ctx.commit('setSideMenuVisible', true);
  } else {
    ctx.commit('setSideMenuVisible', false);
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    window.scrollTo(0, this.scrollPosition);
  }
}
async function logout(ctx) {
  localStorage.clear();
  await ctx.dispatch('reset');
  await subletRouter.push('/logga-in');
}
async function reset(ctx) {
  ctx.commit('subletApplication/setApplication', null, { root: true });
  ctx.commit('subletApplication/setApplicationId', null, { root: true });
  await ctx.dispatch('initiate');
}
async function getSubdomain() {
  let domain = window.location.host;
  if (domain && domain === 'formular-demo.andrahand.se') {
    domain = 'henrik.andrahand.se';
  }
  const arr = domain.split('.');
  if (arr.length < 2) {
    return null;
  }

  return arr[0];
}
async function fetchLandlord(ctx) {
  let landlord = null;

  const subdomain = await ctx.dispatch('getSubdomain');

  if (subdomain && subdomain !== 'formular-demo') {
    landlord = await applicationService.getLandLord(subdomain);
  }

  ctx.commit('setLandlord', landlord);
  return landlord;
}

export default {
  initiate,
  clearUserLocalStorage,
  getNextPath,
  goToNextCategory,
  getAndsetScreenSize,
  toggleSideMenuVisible,
  logout,
  reset,
  fetchLandlord,
  getSubdomain
};
