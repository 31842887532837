<template>
  <InfoModal
    :title="
      Object.keys(applications).length > 1
        ? 'Några liknande byten är redan skapade'
        : 'Ett liknande byte är redan skapat'
    "
    mobile-full-width
    :show-close-btn="true"
    @click="$emit('close')"
  >
    <div class="body-container">
      <section class="existing-applications">
        <ul>
          <li
            v-for="(application, applicationId) in applications"
            :key="applicationId"
          >
            <label :for="`r_id_${applicationId}`" class="list-item">
              <input
                :id="`r_id_${applicationId}`"
                v-model="selectedApplication"
                type="radio"
                name="radio_select_application"
                class="input-radio"
                :value="`${applicationId}`"
              />
              <label
                :for="`r_id_${applicationId}`"
                class="custom-radiobox"
              ></label>
              <div
                class="progress"
                :data-progress="application.progress"
                :style="{
                  '--progress': `${application.progress * 3.6}deg`
                }"
              >
                {{ application.progress }}
              </div>
              <div class="application-meta">
                <p class="application-apartments">
                  {{ getApplicationApartments(application) }}
                </p>
                <p class="application-members">
                  {{ getApplicationNames(application) }}
                </p>
              </div>
            </label>
          </li>
        </ul>
      </section>
      <div class="btn-wrapper">
        <BaseButton
          :is-rounded="true"
          :is-small="true"
          :is-disabled="!selectedApplication"
          type="button"
          class="button"
          @click="$emit('selectApplication', selectedApplication)"
        >
          Gå vidare till valt byte
          <BaseIconText
            class="button-arrow-right"
            :icon-url="svgs.ICONS.ARROW_RIGHT"
          >
          </BaseIconText>
        </BaseButton>
      </div>
    </div>
  </InfoModal>
</template>

<script>
import InfoModal from '@/components/InfoModal.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';

import svgs from '@/utils/icon-constants';
import { readableNames } from '@/utils/common';

export default {
  name: 'DuplicatesModal',
  components: {
    InfoModal,
    BaseIconText,
    BaseButton
  },
  props: {
    applications: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      svgs,
      selectedApplication: ''
    };
  },
  methods: {
    getApplicationApartments(application) {
      return application.applicants
        .map(applicant =>
          applicant.address ? applicant.address : 'Adress saknas'
        )
        .join(', ');
    },

    getApplicationNames(application) {
      const names = application.applicants.map(applicant =>
        applicant.name ? applicant.name.split(' ')[0] : 'Namn saknas'
      );
      return readableNames(names);
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  max-width: none;
  font-size: 1.6rem;
  @media ($mobile) {
    font-size: 1.4rem;
  }
}

.existing-applications {
  margin-bottom: 2rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f8f8fa;
    border-radius: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  a {
    text-decoration: none;
  }

  li {
    position: relative;
  }

  li .list-item {
    margin: 0 2rem;
    padding: 1.2rem 0;
    color: #000;
    display: flex;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--color-light-gray);
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  li .disclaimer-disabled {
    position: absolute;
    bottom: 0.4rem;
    right: 0.7rem;
    font-size: 1.2rem;
  }

  li:nth-last-of-type(1) .list-item {
    border-bottom: none;
  }

  .application-apartments {
    font-weight: 600;
  }

  .application-members {
    color: var(--color-gray);
  }
}

.progress {
  flex-shrink: 0;
  display: flex;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background: conic-gradient(
    var(--color-blue) var(--progress),
    var(--color-light-gray) 0deg
  );
  font-size: 0;
}

.progress::after {
  content: attr(data-progress) '%';
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5px;
  border-radius: 50%;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
  text-align: center;
}

.spinner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner-label {
  margin: 1rem 0 0;
}

.btn-wrapper {
  position: sticky;
  bottom: -1px;
  margin: 0;
  height: 60px;
  background-color: #fff;
  display: flex;
  padding: 10px 0 10px 0;
  gap: 1rem;
  @media ($mobile) {
    display: block;
    height: 120px;
    .button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

.button {
  font-size: 1.2rem;
}

.button-arrow-right {
  margin-left: 1rem;
  :deep(img) {
    margin-right: 0;
  }
}

.custom-radiobox {
  width: 1.6rem;
  height: 1.6rem;
  background-color: transparent;
  border: 2px solid var(--color-blue);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  &::after {
    content: '';
    position: absolute;
    height: 0.8rem;
    width: 0.8rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-blue);
    border-radius: 50%;
    opacity: 0;
    transition: 0.3s ease;
  }
}
.input-radio {
  position: absolute;
  left: -9999px;

  &:checked + .custom-radiobox::after {
    opacity: 1;
  }
}
</style>
