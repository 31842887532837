var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errorModalVisible)?_c('InfoModal',{attrs:{"title":"Något gick fel","show-close-btn":false,"confirm-label":"Försök igen","abort-label":"Logga ut"},on:{"click":_vm.errorModalClick}},[_c('p',[_vm._v("Det gick inte att hämta dina byten.")])]):_vm._e(),(_vm.isInitiating || _vm.isFetchingApplications || _vm.isCreatingApplication)?_c('div',{staticClass:"spinner-wrapper"},[_c('BaseLoadingSpinner'),_c('p',{staticClass:"spinner-label"},[_vm._v(_vm._s(_vm.loadingText))])],1):(!_vm.application)?_c('InfoModal',{attrs:{"title":_vm.applications.length > 0
        ? 'Välj befintligt byte'
        : 'Här är det tomt just nu',"mobile-full-width":"","show-close-btn":true,"disable-outside-click":true},on:{"click":_vm.logout}},[_c('div',{staticClass:"empty-body-container"},[(_vm.applications.length === 0)?[_c('section',{staticClass:"no-applications-container"},[_c('p',[_vm._v(" Det ser ut som att du inte har något påbörjat byte från Lägenhetsbyte.se än. Fortsätt ditt sökande på "),_c('a',{attrs:{"href":"https://www.lagenhetsbyte.se","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Lägenhetsbyte.se")]),_vm._v(" och återkom när du hittat ditt byte. ")])])]:_vm._e()],2),_c('div',{staticClass:"body-container"},[(_vm.applications.length > 0)?_vm._l((_vm.groupedApplications),function(group,title){return _c('section',{key:`group-${title}`,staticClass:"existing-applications"},[_c('h4',[_vm._v(_vm._s(title))]),_c('ul',_vm._l((group),function(application){return _c('li',{key:application.id},[(_vm.isNotInactivator(application))?_c('i',{staticClass:"disclaimer-disabled"},[_vm._v("(Avbruten av annan part)")]):_vm._e(),_c('label',{staticClass:"list-item",class:{
                  disabled: _vm.isNotInactivator(application)
                },attrs:{"for":`r_id_${application.id}`}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedApplication),expression:"selectedApplication"}],staticClass:"input-radio",attrs:{"id":`r_id_${application.id}`,"disabled":_vm.isNotInactivator(application),"type":"radio","name":"radio_select_application"},domProps:{"value":`${application.id}`,"checked":_vm._q(_vm.selectedApplication,`${application.id}`)},on:{"change":function($event){_vm.selectedApplication=`${application.id}`}}}),_c('label',{staticClass:"custom-radiobox",attrs:{"for":`r_id_${application.id}`}}),_c('div',{staticClass:"progress",style:({
                    '--progress': `${application.progress * 3.6}deg`
                  }),attrs:{"data-progress":application.progress}},[_vm._v(" "+_vm._s(application.progress)+" ")]),_c('div',{staticClass:"application-meta"},[_c('p',{staticClass:"application-apartments"},[_vm._v(" "+_vm._s(_vm.getApplicationApartments(application))+" ")]),_c('p',{staticClass:"application-members"},[_vm._v(" "+_vm._s(_vm.getApplicationNames(application))+" ")])])])])}),0)])}):_vm._e(),_c('div',{staticClass:"btn-wrapper"},[_c('BaseButton',{staticClass:"button",attrs:{"is-rounded":true,"is-small":true,"is-disabled":!_vm.selectedApplication,"type":"button"},on:{"click":function($event){return _vm.selectApplication(_vm.selectedApplication)}}},[_vm._v(" Gå vidare till valt byte "),_c('BaseIconText',{staticClass:"button-arrow-right",attrs:{"icon-url":_vm.svgs.ICONS.ARROW_RIGHT}})],1),(!_vm.isLbMode)?_c('BaseButton',{staticClass:"button create-new-btn",attrs:{"type":"button","is-default":true,"is-small":true,"is-rounded":true},on:{"click":_vm.createNewApplication}},[_c('BaseIconText',{attrs:{"icon-url":_vm.svgs.ICONS.ADD_CIRCLE_BLUE}},[_vm._v("Skapa helt ny ansökan ")])],1):_vm._e()],1)],2)]):_c('div',{staticClass:"spinner-wrapper"},[_c('BaseLoadingSpinner'),_c('p',{staticClass:"spinner-label"},[_vm._v("Hämtar information...")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }