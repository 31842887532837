var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-wrapper"},[(_vm.uploadError)?_c('InfoModal',{attrs:{"title":"Något gick fel","abort-label":"Stäng"},on:{"click":function($event){return _vm.$emit('uploadErrorModalClose')}}},[_c('p',[_vm._v(" Det gick inte att ladda upp filen/filerna. Försök igen senare. ")])]):_vm._e(),(_vm.notAcceptedModalVisible)?_c('InfoModal',{attrs:{"title":"Filtypen stöds inte","abort-label":"Stäng"},on:{"click":function($event){_vm.notAcceptedModalVisible = false}}},[_c('p',[_vm._v(" Det gick inte att ladda upp filen/filerna eftersom filtypen inte stöds. Godkända filformat är "),_c('strong',[_vm._v(_vm._s(_vm.readableAcceptedFiles))]),_vm._v(". ")])]):_vm._e(),(_vm.fileToDelete)?_c('InfoModal',{attrs:{"title":"Ta bort fil?","confirm-label":!_vm.deleting ? 'Ta bort' : 'Tar bort...',"abort-label":!_vm.deleting ? 'Stäng' : '',"is-warning":true,"is-disabled":_vm.deleting},on:{"click":_vm.handleModalClick}}):_vm._e(),_c('transition',{attrs:{"name":"form","mode":"out-in"}},[_c('section',{key:_vm.currentId},[_c('input',{ref:"fileInput",attrs:{"id":_vm.inputId,"multiple":"","hidden":"","disabled":_vm.disabled,"accept":_vm.acceptedFiles,"type":"file"},on:{"change":function($event){return _vm.checkFilesBeforeUpload($event.target.files)}}}),(_vm.documents.length === 0)?_c('label',{staticClass:"upload-area",class:{
          dragging: _vm.dragging,
          disabled: _vm.disabled
        },attrs:{"for":_vm.inputId,"title":_vm.infoTitle,"draggable":""},on:{"dragover":function($event){$event.preventDefault();_vm.dragging = true},"dragleave":function($event){$event.preventDefault();_vm.dragging = false},"drop":function($event){$event.preventDefault();_vm.dragging = false;
          _vm.checkFilesBeforeUpload($event.dataTransfer.files);}}},[(_vm.uploading)?[_c('base-loading-spinner',{staticClass:"loader"}),_c('p',{staticClass:"no-pointer-events"},[_vm._v("Laddar upp...")])]:[_c('BaseImage',{staticClass:"upload-image no-pointer-events",attrs:{"url":_vm.svgs.ICONS.ADD_DOCUMENT}}),_c('p',{staticClass:"upload-text no-pointer-events"},[_vm._v(" Släpp filen här eller klicka för att ladda upp "),_c('span',{staticClass:"text-light"},[_vm._v("("+_vm._s(_vm.readableAcceptedFiles)+")")])])]],2):_vm._e(),_c('div',{staticClass:"documents-wrapper"},[(_vm.documents.length > 0)?_c('label',{staticClass:"document-container upload-2-area document",class:{
            dragging: _vm.dragging,
            disabled: _vm.disabled
          },attrs:{"for":_vm.inputId,"title":_vm.infoTitle,"draggable":""},on:{"dragover":function($event){$event.preventDefault();_vm.dragging = true},"dragleave":function($event){$event.preventDefault();_vm.dragging = false},"drop":function($event){$event.preventDefault();_vm.dragging = false;
            _vm.checkFilesBeforeUpload($event.dataTransfer.files);}}},[(_vm.uploading)?[_c('base-loading-spinner',{staticClass:"loader"}),_c('p',{staticClass:"upload-text-2 no-pointer-events"},[_vm._v("Laddar upp...")])]:[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M30 14H18V2C18 1.46957 17.7893 0.96086 17.4142 0.585787C17.0391 0.210714 16.5304 0 16 0C15.4696 0 14.9609 0.210714 14.5858 0.585787C14.2107 0.96086 14 1.46957 14 2V14H2C1.46957 14 0.96086 14.2107 0.585787 14.5858C0.210714 14.9609 0 15.4696 0 16C0 16.5304 0.210714 17.0391 0.585787 17.4142C0.96086 17.7893 1.46957 18 2 18H14V30C14 30.5304 14.2107 31.0391 14.5858 31.4142C14.9609 31.7893 15.4696 32 16 32C16.5304 32 17.0391 31.7893 17.4142 31.4142C17.7893 31.0391 18 30.5304 18 30V18H30C30.5304 18 31.0391 17.7893 31.4142 17.4142C31.7893 17.0391 32 16.5304 32 16C32 15.4696 31.7893 14.9609 31.4142 14.5858C31.0391 14.2107 30.5304 14 30 14Z","fill":"#409FFF"}})]),_c('p',{staticClass:"upload-text-2 no-pointer-events"},[_vm._v(" Ladda upp fler "),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v("(pdf, jpg, png)")])])]],2):_vm._e(),_vm._l((_vm.documents),function(item,index){return _c('div',{key:index,staticClass:"document-container"},[_c('div',{class:[
              'document',
              'document-file',
              Object.keys(_vm.invalidDocuments).includes(item[_vm.docIdentifier])
                ? 'invalid'
                : ''
            ]},[_c('button',{staticClass:"delete-image-button",attrs:{"disabled":_vm.disabled,"title":_vm.infoTitle},on:{"click":function($event){_vm.fileToDelete = item}}},[_c('BaseImage',{staticClass:"delete-image",attrs:{"url":_vm.svgs.ICONS.DELETE_DOCUMENT}})],1),_c('button',{staticClass:"simple-button document-text document",class:{
                'document-hover': item[_vm.docIdentifier] == _vm.downloadingId
              },on:{"click":function($event){return _vm.openFile(item)}}},[(_vm.downloadingId == item[_vm.docIdentifier])?_c('span',[_vm._v("Laddar ned...")]):_c('span',[_vm._v("Öppna")])]),_c('BaseImage',{attrs:{"url":_vm.svgs.ICONS.DOCUMENT}})],1),_c('span',{staticClass:"mt-10"},[_vm._v(" "+_vm._s(_vm.getFilename(item.fileName)))])])})],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }