<template>
  <InfoModal
    :title="title"
    class="information-modal"
    mobile-full-width
    :is-disabled="!hasReadInfo"
    :show-close-btn="hasReadInfo"
    @click="$emit('closeInfoModal')"
  >
    <div class="information-wrapper">
      <div class="information-container">
        <h2>Information om folkbokföringslagen</h2>
        <p>
          Enligt folkbokföringslagen är du som hyresgäst skyldig att vara
          folkbokförd på den adress du bor på, även om du hyr i andra hand och
          inte själv äger bostaden.
        </p>
        <p>
          Att en person är folkbokförd på din bostadsadress påverkar inte ditt
          förstahandskontrakt, utan är endast en skattemässig registrering som
          fastställer var en person är bosatt.
        </p>
      </div>
      <div class="information-container">
        <h2>Det är ditt ansvar att granska din andrahandshyresgäst</h2>
        <p>
          Din hyresvärd kommer inte att granska din andrahandshyresgäst.
          Eventuellt slitage som skulle kunna uppstå under perioden din lägenhet
          är uthyrd är ditt ansvar att återställa. Skulle din
          andrahandshyresgäst bete sig olämpligt eller bryta mot fastighetens
          regler kan du som förstahandshyresgäst riskera att bli vräkt från ditt
          hyreskontrakt. Tänk på att kontakta ditt försäkringsbolag och meddela
          att du kommer att hyra ut din lägenhet, då det kan vara bra att kolla
          vilken typ av hemförsäkring du behöver under uthyrningsperioden.
        </p>
        <p>
          Det är även ditt ansvar att hyran kommer in till hyresvärden varje
          månad.
        </p>
        <p>
          Vi rekommenderar att du kontrollerar följande uppgifter om din
          andrahandshyresgäst:
        </p>
        <ul>
          <li>Boendereferens</li>
          <li>Inkomstunderlag</li>
          <li>Eventuella betalningsanmärkningar</li>
          <li>Eventuella skulder</li>
          <li>Eventuella domar</li>
          <li>Nuvarande folkbokföringsadress</li>
        </ul>
      </div>
      <div class="information-container">
        <h2>Om något skulle förändras under hyresperioden</h2>
        <p>
          Du som hyresgäst har som skyldighet att informera din hyresvärd om
          något skulle förändras under uthyrningsperioden. Det kan exempelvis
          gälla om du byter andrahandshyresgäst eller om du ska studera på annan
          ort än den du angivit. Om du inte informerar din hyresvärd om
          förändringarna riskerar du att hyresvärden drar in medgivandet för
          andrahandsupplåtelsen och i värsta fall kan ditt hyresavtal komma att
          sägas upp.
        </p>
      </div>
      <div class="information-container">
        <h2>Tidsperiod</h2>
        <p></p>
        <p>
          Om ditt skäl för andrahandsuthyrningen är att provbo är den tillåtna
          tidsperioden upp till och med ett år. Vid andra anledningar kan
          hyresvärden tillåta längre perioder, men vanligast är ett år åt
          gången. När du gör din ansökan är det startdatum och slutdatum du
          väljer endast önskemål.
        </p>
      </div>
      <div class="information-container">
        <h2>Oskälig hyra</h2>
        <p>
          Tänk på att du endast får göra tillägg på din hyra om lägenheten hyrs
          ut fullt möblerad. Det tillåtna beloppet att ta för en fullt möblerad
          lägenhet är upp till 15% på grundhyran. Eventuella tillägg ska
          specificeras i hyreskontraktet mellan dig och din andrahandshyresgäst
          och tillhandahållas hyresvärden. Man brukar säga att i en fullt
          möblerad lägenhet ska det man normalt använder mest i hemmet ingå, t.
          ex. soffa, säng, matbord, kastruller, bestick/tallrikar, kyl & frys,
          vattenkokare, dammsugare.
        </p>
      </div>
      <div class="information-container">
        <h2>Handläggningstid</h2>
        <p>
          Tiden för handläggning av ansökan kan komma att bli upp till 2 månader
          då hyresvärden handlägger ansökningar allt efter de kommer in.
        </p>
      </div>
      <div class="information-container">
        <h2>Risker med att hyra ut utan tillstånd</h2>
        <p>
          Att hyra ut sin lägenhet utan tillstånd kan vara en godtagbar grund
          för uppsägning av hyreskontraktet, vilket innebär att du som hyresgäst
          riskerar att förlora boendet. Om du dessutom tar ut en för hög hyra
          vid andrahandsuthyrningen betraktas det som ett brott sedan den 1
          oktober 2019. Lagbrottet kan leda till böter eller fängelse upp till
          två år.
        </p>
      </div>
    </div>
    <Checkbox
      v-if="!hasReadInfo"
      v-model="checkedReadInfo"
      name="checkedReadInfo"
      description="Jag har läst och tagit del av informationen"
      :checked="checkedReadInfo"
      @change="checkedReadInfoToggle"
    />
    <BaseButton
      v-if="!hasReadInfo"
      :is-loading="false"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-small="isMobile"
      type="button"
      :is-disabled="!checkedReadInfo"
      @click="$emit('confirmAndCloseInfoModal')"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Godkänn och stäng
      </BaseIconText>
    </BaseButton>
    <BaseButton
      v-else
      :is-loading="false"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-small="isMobile"
      type="button"
      @click="$emit('closeInfoModal')"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Stäng
      </BaseIconText>
    </BaseButton>
  </InfoModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InfoModal from '@/components/InfoModal.vue';
import Checkbox from '@/components/Checkbox.vue';
import BaseButton from './BaseButton.vue';
import BaseIconText from './BaseIconText.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'SubletInformationModal',
  components: {
    InfoModal,
    Checkbox,
    BaseButton,
    BaseIconText
  },
  props: {
    title: {
      type: String,
      required: true
    },
    hasReadInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedReadInfo: false,
      svgs
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    ...mapGetters({
      isMobile: 'app/isMobile'
    })
  },
  methods: {
    checkedReadInfoToggle() {
      this.checkedReadInfo = !this.checkedReadInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
.information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100%;
  padding: 1rem 0;

  .information-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;

    h2,
    p {
      max-width: 100%;
      overflow-wrap: break-word;
    }

    ul {
      margin: 2rem 0 0 0;
      padding: 0 0 0 2rem;
    }
  }
}
</style>
