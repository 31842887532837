<template>
  <fieldset>
    <slot />
  </fieldset>
</template>
<script>
export default {
  name: 'Fieldset'
};
</script>
<style lang="scss" scoped>
fieldset {
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    flex-basis: 49%;
  }
  @media ($mobile) {
    > * {
      flex-basis: 100%;
    }
  }
}
</style>
