export default {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        el.dispatchEvent(new Event('outside'));
        if (binding.expression) vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
  }
};
