import BANKID from '@/assets/svgs/bankid.svg';
import LB_LOGO from '@/assets/svgs/lb-logo.svg';

import ADD_CIRCLE from '@/assets/svgs/icons/add-circle.svg';
import ADD_CIRCLE_BLUE from '@/assets/svgs/icons/add-circle-blue.svg';
import ADD_CIRCLE_GREY from '@/assets/svgs/icons/add-circle-grey.svg';
import ADD_DOCUMENT from '@/assets/svgs/icons/add-document.svg';
import ARROW_RIGHT from '@/assets/svgs/icons/arrow-right.svg';
import ARROW_RIGHT_GRAY from '@/assets/svgs/icons/arrow-right-gray.svg';
import ARROW_RIGHT_CIRCLE from '@/assets/svgs/icons/arrow-right-circle.svg';
import CHECK_WHITE_CIRCLE from '@/assets/svgs/icons/check-white.svg';
import CHECK_GREEN_CIRCLE from '@/assets/svgs/icons/green-check-mark-circle.svg';
import CLIPBOARD from '@/assets/svgs/icons/clipboard.svg';
import CLOSE from '@/assets/svgs/icons/close.svg';
import DELETE_DOCUMENT from '@/assets/svgs/icons/delete-document.svg';
import DOCUMENT from '@/assets/svgs/icons/document.svg';
import EMAIL from '@/assets/svgs/icons/email.svg';
import EMAIL_ARROW from '@/assets/svgs/icons/email-arrow-white.svg';
import ICON_CHECK_GREEN from '@/assets/svgs/icons/icon-check-mark-green.svg';
import LIGHTBULB_BLUE from '@/assets/svgs/icons/lightbulb-blue.svg';
import LOCKED from '@/assets/svgs/icons/locked.svg';
import MAGNIFYING_GLASS from '@/assets/svgs/icons/magnifying-glass.svg';
import QUESTION_ENVELOPE from '@/assets/svgs/icons/question-envelope.svg';
import REMOVE_CIRCLE_BLUE from '@/assets/svgs/icons/remove-circle-blue.svg';
import REMOVE_CIRCLE_GREY from '@/assets/svgs/icons/remove-circle-grey.svg';
import TIP from '@/assets/svgs/icons/tip-bubble.svg';
import TIP_DARK from '@/assets/svgs/icons/tip-bubble-dark.svg';
import TRASH_BLUE from '@/assets/svgs/icons/trash-blue.svg';
import LB from '@/assets/svgs/icons/lb.svg';
import BLUE_EXCLAMATION from '@/assets/svgs/icons/blue-exclamation-circle.svg';

export default {
  BANKID,
  LB_LOGO,
  ICONS: {
    ADD_DOCUMENT,
    ADD_CIRCLE_BLUE,
    ADD_CIRCLE_GREY,
    ADD_CIRCLE,
    ARROW_RIGHT_CIRCLE,
    ARROW_RIGHT,
    ARROW_RIGHT_GRAY,
    CHECK_WHITE_CIRCLE,
    CHECK_GREEN_CIRCLE,
    CLIPBOARD,
    CLOSE,
    DELETE_DOCUMENT,
    DOCUMENT,
    EMAIL_ARROW,
    EMAIL,
    ICON_CHECK_GREEN,
    LIGHTBULB_BLUE,
    LOCKED,
    MAGNIFYING_GLASS,
    QUESTION_ENVELOPE,
    REMOVE_CIRCLE_BLUE,
    REMOVE_CIRCLE_GREY,
    TIP_DARK,
    TIP,
    TRASH_BLUE,
    LB,
    BLUE_EXCLAMATION
  }
};
