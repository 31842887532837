export function scrollExpandableForm(formRef, targetId) {
  const formInput = formRef;
  const stickyTop = document.getElementById(targetId);

  if (formInput && stickyTop) {
    const rect = formInput.getBoundingClientRect();
    const scrollTop = window.scrollY;
    const targetScrollTop = rect.top + scrollTop;

    window.scrollTo({
      top: targetScrollTop - stickyTop.getBoundingClientRect().height,
      behavior: 'smooth'
    });
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
