<template>
  <InfoModal
    :confirm-icon="false"
    :title="
      !connectingAccount
        ? `${inviteeName} har redan ett konto`
        : 'Kopplar användare, vänligen vänta'
    "
    :confirm-label="!connectingAccount ? 'Fortsätt med ansökan' : ''"
    :show-close-btn="false"
    :is-disabled="connectingAccount"
    @click="$emit('accepted')"
  >
    <div key="content">
      <p>
        Användaren med mailadressen <strong>{{ inviteeEmail }}</strong> finns
        redan på Bytesansökan. För att underlätta processen har vi nu kopplat
        denna användare till ert byte.
      </p>

      <p>Vad händer nu?</p>
      <ul>
        <li class="check" :style="checkmark">
          <span class="capitalize-first"
            >{{ inviteeName }} fyller i sina uppgifter på egen hand</span
          >
        </li>
        <li class="check" :style="checkmark">
          <span class="capitalize-first"
            >Du kan inte se vad {{ inviteeName }} fyller i</span
          >
        </li>
        <li class="check" :style="checkmark">
          <span class="capitalize-first"
            >{{ inviteeName }} kan inte se dina uppgifter</span
          >
        </li>
      </ul>
    </div>
    <div v-if="connectingAccount" key="connectingAccount">
      <BaseLoadingSpinner />
    </div>
  </InfoModal>
</template>
<script>
import InfoModal from '@/components/InfoModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import svgs from '@/utils/icon-constants';
import { mapActions } from 'vuex';
export default {
  components: {
    InfoModal,
    BaseLoadingSpinner
  },
  props: {
    applicant: {
      required: true,
      type: Object,
      default: null
    }
  },
  data() {
    return {
      svgs,
      connectingAccount: false,
      connectApplicantIndex: null
    };
  },
  computed: {
    inviteeName() {
      return this.applicant?.members[0].name
        ? this.applicant?.members[0].name
        : 'denna användare';
    },
    inviteeEmail() {
      return this.applicant?.members[0].email;
    },
    checkmark() {
      return {
        background: `url(${svgs.ICONS.ICON_CHECK_GREEN}) no-repeat left top`
      };
    }
  },
  async mounted() {
    this.connectingAccount = true;
    await this.fetchApplication();
    this.connectingAccount = false;
  },
  methods: {
    ...mapActions({
      fetchApplication: 'application/fetchSelectedApplication'
    })
  }
};
</script>
<style scoped lang="scss">
h4 {
  font-size: 1.6rem;
  font-family: 'Poppins';
  color: var(--color-dark);
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 0.5rem;
}

p {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  font-weight: 400;
  color: var(--color-dark);
  max-width: none;
}

ul {
  padding-left: 0;
}

.check {
  line-height: normal;
  list-style-type: none;
  padding: 0 0 0.7em 1.3em;
}

.capitalize-first {
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
}
</style>
