import cognito from './cognito';

async function loginUser(username, password) {
  const { success } = await cognito.login(username, password);
  return success;
}

async function loginUserBankid(tokenData) {
  return await cognito.login(tokenData, null, true);
}

async function validateSession() {
  return await cognito.validateUser();
}

async function getAuthHeader() {
  const token = await cognito.getToken();
  return { authorization: token };
}

export default {
  loginUserBankid,
  loginUser,
  validateSession,
  getAuthHeader
};
