var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.devEnvironment)?_c('div',{staticClass:"env-info"},[_vm._v("DEV")]):_vm._e(),_c('SubletHeader',{attrs:{"side-menu-open":_vm.sideMenuVisible},on:{"showSubletInformationClick":function($event){return _vm.handleShowSubletInformationToggle()}}}),(
      !_vm.isLoading &&
      _vm.appReady &&
      _vm.authenticated &&
      !!_vm.application &&
      _vm.subletInformationModalVisible
    )?_c('SubletInformationModal',{attrs:{"has-read-info":_vm.hasReadInfo,"title":"Information om uthyrning i andra hand"},on:{"confirmAndCloseInfoModal":function($event){return _vm.handleUpdateHasReadInfo(_vm.application.id)},"closeInfoModal":function($event){_vm.subletInformationModalVisible = false}}}):_vm._e(),_c('div',{staticClass:"app-inner-wrapper"},[(!_vm.isLoading && _vm.authenticated)?_c('SubletSideMenu',{attrs:{"visible":_vm.sideMenuVisible,"toggle-side-menu":_vm.toggleSideMenuVisible},on:{"close":function($event){return _vm.toggleSideMenuVisible(false)}}}):_vm._e(),_c('main',{staticClass:"app-inner-wrapper-main"},[(_vm.isLoading)?_c('div',{staticClass:"app-spinner-wrapper"},[_c('BaseLoadingSpinner'),_c('p',{staticClass:"app-spinner-label"},[_vm._v("Hämtar information...")])],1):_c('div',{staticClass:"content-wrapper"},[_c('transition',{attrs:{"name":"fade-list","mode":"out-in","appear":""},on:{"enter":_vm.forceScrollTop}},[_c('div',{key:_vm.$route.path},[_c('router-view')],1)])],1),_c('SupportButton')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }