<template>
  <BaseButton
    :is-loading="false"
    :is-secondary="true"
    :class="[
      'send-invite',
      { 'invite-sent': hasBeenInvited },
      { 'invite-accepted': inviteAccepted },
      { 'email-updated': emailUpdated }
    ]"
    :disabled="sending || hasBeenInvited || inviteAccepted || isDisabled"
    @click="sendInvite()"
  >
    <div v-if="sending" class="applicant-spinner">
      <BaseLoadingSpinner color="#fff" :size="30" />
    </div>
    <BaseIconText
      v-if="!hasBeenInvited && !inviteAccepted && !emailUpdated"
      :icon-url="svgs.ICONS.EMAIL_ARROW"
    >
      Bjud in
    </BaseIconText>

    <BaseIconText
      v-if="!hasBeenInvited && !inviteAccepted && emailUpdated"
      :icon-url="svgs.ICONS.EMAIL_ARROW"
    >
      Skicka igen
    </BaseIconText>

    <BaseIconText
      v-if="hasBeenInvited && !inviteAccepted"
      :icon-url="svgs.ICONS.EMAIL"
    >
      Inbjudan skickad
    </BaseIconText>

    <BaseIconText v-if="inviteAccepted" :icon-url="svgs.ICONS.EMAIL">
      Konto färdigställs
    </BaseIconText>
  </BaseButton>
</template>

<script>
import BaseIconText from '@/components/BaseIconText.vue';
import svgs from '@/utils/icon-constants';
import BaseButton from '@/components/BaseButton.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  name: 'InviteButton',
  components: { BaseIconText, BaseButton, BaseLoadingSpinner },
  props: {
    applicantId: {
      type: String,
      required: true,
      default: null
    },

    invitationSent: {
      type: Boolean,
      default: false
    },

    inviteAccepted: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    email: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      svgs,
      sending: false,
      inviteSent: false,
      emailUpdated: false
    };
  },
  computed: {
    hasBeenInvited() {
      return this.inviteSent;
    }
  },
  watch: {
    email: {
      handler(newEmail, oldEmail) {
        if (newEmail !== oldEmail && !this.isDisabled) {
          this.checkInvite();
        }
        if (!newEmail) {
          this.inviteSent = false;
          this.emailUpdated = false;
        }
      },
      immediate: true
    },
    invitationSent: {
      handler(newVal) {
        this.inviteSent = newVal;
      },
      immediate: true
    }
  },
  methods: {
    async sendInvite() {
      this.sending = true;

      try {
        const result = await this.$applicationService.inviteApplicant(
          this.applicantId,
          this.email
        );

        if (result.status === 202) {
          this.$emit('connect');
        }

        this.inviteSent = true;
      } catch (error) {
        this.inviteSent = false;
      }

      this.sending = false;
    },

    async checkInvite() {
      this.sending = true;

      try {
        const result = await this.$applicationService.checkInvite(
          this.applicantId,
          this.email
        );
        this.inviteSent = result.sent;
        this.emailUpdated = result.updated;
      } catch (error) {
        this.inviteSent = false;
      }

      this.sending = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.send-invite {
  min-width: 22rem;
  height: 44px;
  margin-left: 0.5rem;
  border-radius: 10px;

  position: relative;

  &.email-updated {
    background-color: var(--color-teal);
  }

  &.invite-sent {
    background-color: var(--color-teal);
    color: #000;
  }

  &.invite-accepted {
    background-color: var(--color-yellow);
    color: #000;
  }

  @media ($mobile) {
    width: 100%;
    margin-left: 0;
  }

  .applicant-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}
</style>
