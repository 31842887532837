<template>
  <div
    class="summary-wrapper"
    :class="{
      'information-missing': hasEmptyInfoFields,
      'information-approved': isApproved
    }"
  >
    <div class="top-container">
      <h2>{{ title }}</h2>
      <button
        v-if="applicationIsEditable || rejectReason.length > 0"
        type="button"
        class="change-btn"
        :disabled="changeBtnDisabled"
        @click="$emit('click', category)"
      >
        Ändra
      </button>
    </div>
    <div v-if="rejectReason.length > 0" class="reject-reason-container">
      <h2>Kommentar från handläggare:</h2>
      <i style="color: red">{{ rejectReason }}</i>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Summary',

  props: {
    title: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    hasEmptyInfoFields: {
      type: Boolean,
      default: false
    },
    changeBtnDisabled: {
      type: Boolean,
      default: false
    },
    rejectReason: {
      type: String,
      default: ''
    },
    applicationIsEditable: {
      type: Boolean,
      default: true
    },
    isApproved: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  @media ($mobile) {
    display: flex;
    width: 100%;
  }

  &.information-missing {
    border: 2px solid var(--color-red);
  }
  &.information-approved {
    border: 2px solid var(--color-green);
  }

  .reject-reason-container {
    display: flex;
    flex-direction: column;

    h2 {
      text-transform: unset;
    }
  }

  .top-container {
    display: flex;
    justify-content: space-between;
    .change-btn {
      outline: none;
      border: none;
      background-color: transparent;
      padding: 0;
      color: var(--color-blue);
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
