export default {
  setSideMenuVisible(state, visible) {
    state.sideMenuVisible = visible;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setInitData(state, { authenticated, appReady }) {
    state.appReady = appReady;
    state.authenticated = authenticated;
  },
  setScreenSize(state, newSize) {
    state.screenSize = newSize;
  },
  setLandlord(state, landlord) {
    state.landlord = landlord;
  }
};
