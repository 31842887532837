<template>
  <Summary
    :title="title"
    category="APARTMENTS"
    :has-empty-info-fields="!!rejectionMessage || categoryHasErrors"
    :is-approved="isApproved"
    :reject-reason="!isApproved && rejectionMessage ? rejectionMessage : ''"
    @click="$emit('goToCategory', $event)"
  >
    <SummaryBody
      v-for="(fields, groupName) in fieldGroups"
      :key="groupName"
      :sub-heading="groupName"
    >
      <template v-for="(field, fieldName) in fields">
        <SwapSummaryField
          :key="fieldName"
          :label="field.label"
          :field-name="fieldName"
          :field-value="field.value"
          :is-valid="field.valid"
        />
      </template>
    </SummaryBody>
  </Summary>
</template>

<script>
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import SwapSummaryField from './SwapSummaryField.vue';

export default {
  components: {
    Summary,
    SummaryBody,
    SwapSummaryField
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    rejectionMessage: {
      type: String,
      default: ''
    },
    apartment: {
      type: Object,
      required: true
    },
    categoryHasErrors: {
      type: Boolean,
      required: true
    },
    isApproved: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    fieldGroups() {
      return {
        Adress: {
          address: this.apartment.address,
          postalCode: this.apartment.postalCode,
          postalAddress: this.apartment.postalAddress
        },
        Lägenheten: {
          objectNumber: this.apartment.objectNumber,
          apartmentNumber: this.apartment.apartmentNumber,
          rooms: this.apartment.rooms,
          sqm: this.apartment.sqm,
          rent: this.apartment.rent
        },
        Boende: {
          adultCount: this.apartment.adultCount,
          childCount: this.apartment.childCount,
          contractStartDate: this.apartment.contractStartDate
        },
        Hyresvärd: {
          landlordName: this.apartment.landlordName
        }
      };
    }
  }
};
</script>
