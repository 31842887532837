<template>
  <div id="app">
    <div v-if="devEnvironment" class="env-info">DEV</div>
    <SwapHeader
      :side-menu-open="sideMenuVisible"
      @showStatusClick="handleShowStatusModalClick"
    />
    <div class="app-inner-wrapper">
      <template v-if="appReady && authenticated && !applicationIsInactive">
        <SwapSideMenu
          :visible="sideMenuVisible"
          @close="toggleSideMenuVisible(false)"
          @privacyPolicyClick="termsModalVisible = true"
          @skandiaClick="skandiaInfoModalVisible = true"
          @showHelpModal="helpModalVisible = true"
        />
        <TermsModal
          v-if="termsModalVisible"
          :show-approve-btn="false"
          @click="termsModalVisible = false"
        />
        <InfoModal
          v-if="skandiaInfoModalVisible"
          title="Ansökan om lägenhetsbyte (bostad)"
          :disable-outside-click="true"
          :show-close-btn="false"
          confirm-label="Jag förstår"
          :is-position-left="true"
          @click="skandiaInfoModalVisible = false"
        >
          <div
            class="info-modal-content"
            v-html="skandiaInfoModalContent"
          ></div>
        </InfoModal>
        <CircleInfoModal
          v-if="helpModalVisible"
          @click="helpModalVisible = false"
        />
      </template>
      <main class="app-inner-wrapper-main">
        <div v-if="!appReady" class="app-spinner-wrapper">
          <BaseLoadingSpinner />
          <p class="app-spinner-label">Hämtar information...</p>
        </div>
        <template v-else-if="applicationIsInactive">
          <InfoModal
            v-if="activateAppError"
            title="Något gick fel"
            abort-label="Tillbaka"
            :confirm-icon="false"
            @click="handleAppErrorClick"
          >
            <p>
              Det gick inte att aktivera ansökan, försök igen senare.
            </p></InfoModal
          >
          <InfoModal
            v-if="!activateAppError"
            :title="
              isActivatingApplication ? 'Aktiverar...' : 'Ansökan är avbruten'
            "
            :show-close-btn="!isActivatingApplication"
            confirm-label="Aktivera ansökan"
            abort-label="Tillbaka"
            :confirm-icon="false"
            :is-disabled="isActivatingApplication"
            @click="handleActiveAppClick"
          >
            <p>Ansökan är avbruten, men du kan aktivera den igen.</p></InfoModal
          >
        </template>
        <div v-else class="content-wrapper">
          <CollapseCard
            v-if="isLb && notStarted?.length > 0"
            :is-alert="true"
            class="mb-2"
          >
            <template slot="body-text">
              <p style="display: flex; gap: 2rem; align-items: center">
                <BaseImage
                  class="icon-tip"
                  :url="svgs.ICONS.TIP_DARK"
                ></BaseImage
                ><span v-html="getNotStartedInfoHTML(notStarted)" />
              </p>
            </template>
          </CollapseCard>
          <transition
            name="fade-list"
            mode="out-in"
            appear
            @enter="forceScrollTop"
          >
            <router-view :key="$route.path" />
          </transition>
        </div>
        <SupportButton></SupportButton>
      </main>
    </div>
    <StatusModal
      v-if="
        appReady &&
        authenticated &&
        !!application &&
        applicationStarted &&
        statusModalVisible &&
        !applicationIsInactive
      "
      @close="statusModalVisible = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import SwapHeader from '@/components/SwapHeader.vue';
import SupportButton from '@/components/SupportButton.vue';
import StatusModal from '@/components/StatusModal.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import BaseImage from '@/components/BaseImage.vue';
import svgs from '@/utils/icon-constants';
import InfoModal from '@/components/InfoModal.vue';
import applicationService from '@/services/applicationService';
import { readableNames } from '@/utils/common';
import SwapSideMenu from './components/SwapSideMenu.vue';
import TermsModal from '@/components/TermsModal.vue';
import CircleInfoModal from '@/components/CircleInfoModal.vue';

export default {
  name: 'SwapApp',
  components: {
    SwapHeader,
    BaseLoadingSpinner,
    SupportButton,
    StatusModal,
    CollapseCard,
    BaseImage,
    InfoModal,
    SwapSideMenu,
    TermsModal,
    CircleInfoModal
  },
  data() {
    return {
      scrollPosition: 0,
      statusModalVisible: true,
      domainTitle: 'Bytesansökan',
      pageTitle: '',
      svgs,
      isActivatingApplication: false,
      activateAppError: false,
      fetchingStatuses: false,
      termsModalVisible: false,
      skandiaInfoModalVisible: false,
      helpModalVisible: false,
      skandiaInfoModalContent: `
          <h3><i><u>Genom sin underskrift av denna ansökan bekräftar sökanden att man har tagit del av följande information:</i></u></h3>
          </br>
          <b>Vad säger hyreslagen om att byta bostad?</b>
          </br>
          En hyresgäst får enligt 12 kap 35 § Jordabalken (”hyreslagen”) överlåta sin hyresrätt för att genom byte
          erhålla annan stadigvarande bostad.
          </br>
          Lagtexten säger bland annat följande:
          </br>
          <i>Hyresgästen får överlåta hyresrätten till sin bostadslägenhet för att genom byte få en annan bostad, om hyresnämnden
          lämnar tillstånd till överlåtelsen. Tillstånd ska lämnas om hyresgästen har beaktansvärda skäl för bytet och att detta
          kan äga rum utan påtaglig olägenhet för hyresvärden, samt inte heller andra skäl talar emot bytet. Tillståndet kan
          förenas med villkor.</i>
          </br>
          </br>
          <b>Vi ställer samma krav på den du vill byta med som på alla andra som vill bli hyresgäst hos oss:</b>
          <ul>
            <li>Vi accepterar inga betalningsanmärkningar.</li>
            <li>Hushållets sammanlagda bruttoinkomst ska vara minst tre-fyra gånger hyresbeloppet beroende på fastighet / ort.</li>
            <li>Den sökande ska kunna visa upp goda referenser från tidigare hyresvärd.</li>
            <li>Den sökande får inte ha någon annan bostad, om sådan finns ska detta boende avslutas.</li>
            <li>Den sökande måste ha fyllt 18 år.</li>
          </ul>
          <b>Handläggningstid</b>
          </br>
          Handläggningstiden - efter fullständigt ifylld ansökan med samtliga handlingar och uppgifter som vi önskar
          för bytets genomförande - är minst åtta veckor. Vi påbörjar inte handläggningen innan er ansökan är
          komplett - var därför mycket noga med att få med alla de handlingar som ska biläggas din ansökan.
          </br>
          </br>
          <b>Besiktning</b>
          </br>
          Innan bytet kan genomföras ska din lägenhet besiktigas. Eventuella skador, installationer eller ändringar
          orsakade eller genomförda av dig, kan inte övertas av inflyttande hyresgäst. Vid skador debiterar vi dig för
          att återställa lägenheten. Återställer du lägenheten själv ska detta arbete utföras och sedan godkännas av
          oss innan bytet kan genomföras.
          </br>
          </br>
          <b>Vårt beslut</b>
          </br>
          När bytet är godkänt av samtliga hyresvärdar bokas tid för undertecknande av hyreskontrakt. Det krävs att
          samtliga parter i byteskedjan undertecknar skriftligt hyreskontrakt innan flyttning kan ske. Som avflyttande
          hyresgäst ska du underteckna en uppsägning av ditt hyresavtal i samband med att ny hyresgäst tecknar
          nytt hyresavtal för din gamla bolastad.
          Om vi avslår din ansökan får du ett skriftligt besked om detta. Om du tycker vårt beslut är felaktigt kan du
          vända dig till Hyresnämnden och låta dem pröva din ansökan. Hyresnämndens beslut går inte att
          överklaga.
          </br>
          </br>
          <b>Integritetspolicy</b>
          </br>
          <a href="https://www.skandiafastigheter.se/globalassets/om-oss/personuppgifter/behandling-av-personuppgifter-vid-bostadsuthyrning.docx.pdf" target="_blank">Länk till Skandia Fastigheters integritetspolicy</a>
          `
    };
  },
  computed: {
    ...mapState({
      appReady: state => state.app.appReady,
      account: state => state.app.account,
      authenticated: state => state.app.authenticated,
      application: state => state.application.application,
      landlord: state => state.app.landlord,
      sideMenuVisible: state => state.app.sideMenuVisible,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      applicationId: state => state.application.applicationId
    }),
    ...mapGetters({
      isMobile: 'app/isMobile',
      isLb: 'application/isLb',
      notStarted: 'application/notStarted',
      applicationIsInactive: 'application/applicationIsInactive',
      applicant: 'application/applicant'
    }),
    devEnvironment() {
      if (process.env.NODE_ENV === 'production') {
        return false;
      }
      return true;
    },

    applicationStarted() {
      return this.application.applicants.every(applicant => applicant.started);
    }
  },

  watch: {
    appReady: {
      handler(appReady) {
        if (appReady && this.application && !this.applicationStarted) {
          this.statusModalVisible = false;
        }
      },
      immediate: true
    },
    $route(to) {
      this.pageTitle = to.meta.title;
      this.updateFullMetaTitle();
      if (this.pageTitle === 'Byteskedja') {
        this.processModalVisible = false;
      }
    },
    landlord(isLandlord) {
      if (isLandlord) {
        this.domainTitle = `${this.landlord.name} Bytesansökan`;
      } else {
        this.domainTitle = 'Bytesansökan';
      }
      this.updateFullMetaTitle();
    }
  },

  async created() {
    window.addEventListener('resize', this.getAndsetScreenSize);
    window.addEventListener('new-size', arg => {
      if (arg.detail != 'MOBILE') {
        this.toggleSideMenuVisible(false);
      }
    });
    await this.initiate();
  },
  methods: {
    ...mapActions({
      initiate: 'app/initiate',
      getAndsetScreenSize: 'app/getAndsetScreenSize',
      toggleSideMenuVisible: 'app/toggleSideMenuVisible',
      reset: 'app/reset'
    }),
    ...mapMutations({
      setApplicationStatus: 'application/setApplicationStatus'
    }),
    async handleAppErrorClick() {
      this.activateAppError = false;
      await this.reset();
    },
    async handleActiveAppClick(eventType) {
      if (eventType === 'CONFIRM') {
        this.isActivatingApplication = true;
        try {
          await applicationService.reactivateApplication(
            this.applicationId,
            this.applicant.id
          );
          this.setApplicationStatus('ACTIVE');
        } catch {
          this.activateAppError = true;
        }
        this.isActivatingApplication = false;
      } else {
        await this.reset();
      }
    },
    getNotStartedInfoHTML() {
      // Single case + is owner
      if (this.notStarted.length === 1 && this.notStarted[0].owner) {
        return '<b>Du</b> har inte påbörjat ansökan än.';
      }

      // Multiple
      const names = this.notStarted.map((notStarted, i) => {
        if (notStarted.owner) {
          return `<b>${i === 0 ? 'Du' : 'du'}</b>`;
        }
        return `<b>${notStarted.name}</b>`;
      });

      return `${readableNames(names)} har inte påbörjat ansökan än.`;
    },
    updateFullMetaTitle() {
      document.title = `${this.domainTitle} - ${this.pageTitle}`;
    },

    async handleShowStatusModalClick() {
      this.statusModalVisible = true;
    },

    forceScrollTop() {
      this.$nextTick(() => {
        window.scroll(0, 0);
      });
    }
  }
};
</script>

<style></style>
