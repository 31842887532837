<template>
  <SwapDefaultPage
    :names="swapDetailsActiveMemberTitle"
    :possessions="swapDetailsMembersList"
    v-on="$listeners"
    @possessionClick="setSelectedPossessionIndex"
  >
    <RejectionMessageCard category="SWAPDETAILS" />
    <CollapseCard is-primary initially-closed class="mb-4">
      <h3 slot="title-text">Tänk på att</h3>
      <template slot="body-text">
        <p>
          Då byten i regel sker vid månadsskiften så är det endast möjligt att
          välja den första varje månad som önskat bytesdatum. Ett byte tar i
          snitt 6-8 veckor så det är därför inte möjligt att välja ett
          månadsskifte som inte ligger inom ramen för detta.
        </p>
        <br />
        <p>
          Exempel: Anmälning till Bytesansökan sker 5/1 och önskemålet är att
          flytta så snart som möjligt. Önskat bytesdatum på ansökningarna blir
          då 1/3.
        </p>
        <br />
        <p><b>OBS! Detta är ett önskemål på inflyttningsdatum.</b></p>
      </template>
    </CollapseCard>
    <transition name="form" mode="out-in">
      <SwapForm
        :key="swapDetailsActiveMemberTitle"
        :is-loading="savingApplicationData"
        :form-type="formType"
        :form-has-changed="formHasChanged"
        v-on="$listeners"
      >
        <Select
          v-if="activeMemberSwapAddresses"
          v-model="activeSelectedSwapAddress"
          :selected-option="activeSelectedSwapAddress"
          title="Adress"
          :description="`Ange den adress som ${swapDetailsActiveMemberTitle} kommer flytta till i detta byte`"
          :options="activeMemberSwapAddresses"
          :is-disabled="activeMemberSwapAddresses.length === 1"
          :error-text="`${swapDetailsMembersList
            .map(member => member.title)
            .join(' & ')} kan inte flytta in i samma lägenhet`"
          :has-error="haveSameSwapAddressSelected"
        />
        <Fieldset>
          <SwapInput
            v-model="activeSwapDetails.adultCount"
            :disabled="savingApplicationData"
            label="Antal vuxna"
            placeholder="Antal vuxna"
            description="Ange antal vuxna som kommer flytta in"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <SwapInput
            v-model="activeSwapDetails.childCount"
            :disabled="savingApplicationData"
            label="Antal barn"
            placeholder="Antal barn"
            description="Ange antal barn som kommer flytta in"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </Fieldset>

        <transition name="form" mode="out-in">
          <DatePicker
            :key="activeApplicant.id"
            title="Önskat bytesdatum"
            :description="`
              Prata ihop dig med de andra parterna om vilket bytesdatum ni föredrar och välj det här. 
              Tänk på att du som huvudregel behöver ha bott i lägenheten i minst 1 år för att få byta enligt den nya hyreslagen.
              <br/>
              <b>OBS: Detta är endast önskat inflyttningsdatum. Det är upp till hyresvärden när det faktiska datumet för inflytt blir.</b>
            `"
            :selected-option="selectedDate || ''"
            :options="getDates"
            :applicants-selected-dates="applicantsSelectedDates"
            @click="setSelectedDate"
          />
        </transition> </SwapForm
    ></transition>
  </SwapDefaultPage>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import { validateNumber } from '@/utils/input-validations';
import { haveSameSwapAddressSelected as _haveSameSwapAddressSelected } from '@/utils/input-validations';

import Select from '@/components/Select.vue';
import DatePicker from '@/components/DatePicker.vue';
import Fieldset from '@/components/Fieldset.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import SwapForm from '@/components/SwapForm.vue';
import SwapInput from '@/components/SwapInput.vue';
import RejectionMessageCard from '@/components/RejectionMessageCard.vue';

export default {
  name: 'SwapDetails',
  components: {
    Fieldset,
    Select,
    DatePicker,
    CollapseCard,
    SwapDefaultPage,
    SwapForm,
    SwapInput,
    RejectionMessageCard
  },
  props: {
    activeApplicant: {
      type: Object,
      required: true
    },
    applicants: {
      type: Array,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    activeMemberSwapAddresses: {
      type: Array,
      required: true
    },
    swapDetailsMembersList: {
      type: Array,
      required: true
    },
    swapDetailsActiveMemberTitle: {
      type: String,
      required: true
    },
    mergeMemberNames: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedDate: '',
      validateNumber,
      formType: 'SWAPDETAILS'
    };
  },
  computed: {
    ...mapState({
      application: state => state.application.application,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    getDates() {
      const months = [];
      const formatter = new Intl.DateTimeFormat('sv', {
        month: 'long'
      });
      for (let i = 2; i < 8; i += 1) {
        const start = new Date();
        start.setDate(1);
        start.setMonth(start.getMonth() + i);
        const month = formatter.format(start);
        const isoDateString = start.toISOString();
        months.push({
          id: isoDateString.split('T')[0],
          label: `1:a ${month.substring(0, 3)}`
        });
      }

      return months;
    },
    activeApplicantSwapDetails() {
      if (!this.activeApplicant) {
        return [];
      }
      return this.activeApplicant.swapDetails;
    },
    activeMember() {
      if (!this.activeApplicantMembers) {
        return null;
      }
      return this.activeApplicantMembers[this.selectedPossessionIndex];
    },
    activeApplicantMembers() {
      if (!this.activeApplicant) {
        return [];
      }
      return this.activeApplicant.members.slice(
        0,
        this.activeApplicantSwapDetails.length
      );
    },

    haveSameSwapAddressSelected() {
      return _haveSameSwapAddressSelected(
        this.applicants,
        this.selectedApplicantIndex,
        this.selectedPossessionIndex
      );
    },
    activeSelectedSwapAddress: {
      get() {
        if (!this.activeSwapDetails) {
          return '';
        }
        const index = this.activeSwapDetails.apartmentIndex;
        return this.activeMemberSwapAddresses[index];
      },
      set(value) {
        const selectedIndex = this.activeMemberSwapAddresses.indexOf(value);
        this.activeSwapDetails.apartmentIndex = selectedIndex;
      }
    },
    activeSwapDetails() {
      if (!this.activeApplicant) {
        return null;
      }
      return this.activeApplicantSwapDetails[this.selectedPossessionIndex];
    },
    applicantsSelectedDates() {
      return this.applicants
        .filter(applicant => applicant.id !== this.activeApplicant.id)
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr.members[0].name]: curr.swapDetails[0].swapDate
          }),
          {}
        );
    }
  },
  watch: {
    activeSwapDetails: {
      handler(newDetails, oldDetails) {
        if (newDetails !== oldDetails) {
          this.selectedDate = newDetails?.swapDate ? newDetails.swapDate : null;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    setSelectedDate(option) {
      this.$emit('isEditing', this.formType);
      const nextApplicantDetails =
        this.activeApplicantSwapDetails[this.selectedPossessionIndex + 1];
      const previousApplicantDetails =
        this.activeApplicantSwapDetails[this.selectedPossessionIndex - 1];

      this.selectedDate = option.id;
      this.activeSwapDetails.swapDate = option.id;

      if (nextApplicantDetails) {
        nextApplicantDetails.swapDate = option.id;
        return;
      }
      if (previousApplicantDetails) {
        previousApplicantDetails.swapDate = option.id;
        return;
      }
    }
  }
};
</script>
<style></style>
