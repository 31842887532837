<template>
  <span :class="['loading loading--spinner', { overlay: withOverlay }]">
    <svg
      class="spinner"
      :style="{ height: `${size}px`, width: `${size}px` }"
      viewBox="0 0 50 50"
    >
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        :stroke="color"
        stroke-width="5"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'BaseLoadingSpinner',
  props: {
    size: {
      type: Number,
      default: 50
    },
    withOverlay: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#409fff'
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.spinner {
  animation: rotate 2s linear infinite;

  & .path {
    animation: dash 1s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
    stroke-linecap: round;
  }
}
</style>
