<template>
  <div>
    <PageHeader :is-mobile="isMobile" :title="pageTitle" :names="pageNames">
      <SwapTabMenuButton
        v-for="(applicant, idx) in applicants"
        :key="idx"
        :is-disabled="applicant.disabled"
        :is-locked="applicant.disabled"
        :status="applicant.status"
        :active="applicant.active"
        :classes="applicant.classes"
        :link="applicant.tabLink"
        @tabMenuButtonClick="$emit('applicantClick', $event)"
      >
        {{ applicant.title }}
      </SwapTabMenuButton>
    </PageHeader>
    <TabMenu v-if="possessions && possessions.length > 0" class="mb-4">
      <SwapTabMenuButton
        v-for="(possession, idx) in possessions"
        :key="idx"
        :is-disabled="possession.disabled"
        :is-locked="possession.disabled"
        :show-status="false"
        :active="possession.active"
        :classes="possession.classes"
        :link="possession.tabLink"
        @tabMenuButtonClick="$emit('possessionClick', $event)"
      >
        {{ possession.title }}
      </SwapTabMenuButton>
      <slot name="page-possession-addition" />
    </TabMenu>
    <ApplicationReviewedCard v-if="applicationIsSent" />
    <slot />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import TabMenu from '@/components/TabMenu.vue';
import SwapTabMenuButton from '@/components/SwapTabMenuButton.vue';
import ApplicationReviewedCard from '@/components/ApplicationReviewedCard.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'SwapDefaultPage',
  components: {
    TabMenu,
    SwapTabMenuButton,
    PageHeader,
    ApplicationReviewedCard
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    names: {
      type: String,
      default: null
    },
    possessions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      application: state => state.application.application,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex
    }),
    ...mapGetters({
      applicationIsSent: 'application/applicationIsSent',
      applicant: 'application/applicant',
      isMobile: 'app/isMobile',
      eachApplicantSummarized: 'appValidation/eachApplicantSummarized'
    }),
    pageTitle() {
      if (this.title) {
        return this.title;
      }
      return this.$route.meta?.title || '';
    },
    pageNames() {
      if (this.names) {
        return this.names;
      }
      return this.applicant?.members[0].name || '';
    },
    applicants() {
      if (!this.application) {
        return [];
      }
      return this.application.applicants.map((applicant, index) => {
        return {
          title: applicant.members[0].name,
          active: this.selectedApplicantIndex === index,
          tabLink: index,
          status: this.eachApplicantSummarized[index]?.[this.$route.name] || '',
          disabled: !applicant.owner
        };
      });
    }
  }
};
</script>

<style></style>
