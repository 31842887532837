<template>
  <div class="field-wrapper">
    <input
      :id="radioBtnLabel"
      :class="[
        'radio-btn-input',
        isSelected ? 'selected' : '',
        isDisabled ? 'disabled' : ''
      ]"
      type="radio"
      :label="radioBtnLabel"
      :value="radioBtnValue"
      :checked="isSelected"
      :disabled="isDisabled"
      @change="$emit('change', radioBtnValue.toLowerCase())"
    />
    <label
      class="label"
      :class="[isDisabled ? 'disabled' : '']"
      :for="radioBtnLabel"
      >{{ radioBtnLabel }}</label
    >
  </div>
</template>

<script>
export default {
  name: 'RadioButton',

  props: {
    radioBtnLabel: {
      type: String,
      default: ''
    },
    radioBtnValue: {
      type: [String, Number],
      default: ''
    },
    selectedValue: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      value: null
    };
  },

  computed: {
    isSelected() {
      return this.radioBtnValue === this.selectedValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5rem;
  margin: 0;

  .label {
    padding: 0;
    width: fit-content;
    line-height: 1.5;
    color: var(--color-dark);
    cursor: pointer;
    &.disabled {
      cursor: initial;
    }
    .title {
      width: fit-content;
      font-weight: 600;
      font-size: 1.6rem;
      display: block;
    }
  }

  .radio-btn-input {
    padding: 1rem;
    border-radius: 50%;
    background-color: white;
    border: 3px solid var(--color-blue);
    cursor: pointer;
    position: relative;
    outline: 0px;
    appearance: none;
    &.selected {
      background-color: var(--color-blue);
      &.disabled {
        border: 3px solid #a8a5a5;
        background-color: #a8a5a5;
        cursor: initial;
      }
    }
    &.disabled {
      border: 3px solid #a8a5a5;
      cursor: initial;
    }
  }
}
</style>
