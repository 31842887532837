<template>
  <TabMenuButton
    :show-status="showStatus"
    :status="status"
    :is-disabled="isDisabled || isLoadingDocument"
    :is-locked="isLocked"
    :active="active"
    :classes="classes"
    :disabled-reason="disabledReason"
    :link="link"
    :status-title="statusTitle"
    v-on="$listeners"
  >
    <slot />
  </TabMenuButton>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import TabMenuButton from '@/components/TabMenuButton.vue';

export default {
  components: {
    TabMenuButton
  },
  props: {
    showStatus: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: () => []
    },
    disabledReason: {
      type: String,
      default: 'Rättigheter saknas'
    },
    link: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    ...mapState({
      isLoadingDocument: state => state.application.isLoadingDocument
    }),
    ...mapGetters({
      statusDescriptions: 'application/statusDescriptions'
    }),
    statusTitle() {
      return this.statusDescriptions[this.status];
    }
  }
};
</script>

<style></style>
