<template>
  <span>
    <template v-for="(part, index) in parts">
      <a
        v-if="linkRegex.test(part)"
        :key="index"
        class="link-wrapper"
        :href="part"
        target="_blank"
        rel="noopener noreferrer"
        >{{ part }}</a
      >
      <span v-else :key="index">{{ part }}</span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'LinkWrapper',
  props: {
    input: {
      type: String,
      default: ''
    }
  },
  computed: {
    linkRegex() {
      return /(https?:\/\/[^\s]+)/g;
    },
    parts() {
      return this.input ? this.input.split(this.linkRegex) : [];
    }
  }
};
</script>

<style lang="scss" scoped>
.link-wrapper,
.link-wrapper:visited {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

.link-wrapper:hover {
  text-decoration: underline;
}
</style>
