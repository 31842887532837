<template>
  <SummaryField
    :label="label"
    :has-empty-info-fields="!!errorMsg"
    :info-html="errorMsg"
    >{{ fieldValue }}</SummaryField
  >
</template>

<script>
import SummaryField from '@/components/summary/SummaryField.vue';
import { fieldIsEmpty as _fieldIsEmpty } from '@/utils/input-validations';

export default {
  components: {
    SummaryField
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      required: true
    },
    fieldValue: {
      type: [String, Number],
      default: ''
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fieldIsEmpty: _fieldIsEmpty
    };
  },
  computed: {
    errorMsg() {
      if (this.fieldIsEmpty(this.fieldValue)) {
        return 'Information saknas';
      }
      if (!this.isValid) {
        return 'Fältet måste fyllas i korrekt.';
      }
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-field-wrapper {
  :deep(.label) {
    font-weight: 600;
  }
}
</style>
