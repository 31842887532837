<template>
  <transition mode="out-in" name="accordion-fade">
    <CollapseCard v-if="showMessage" :is-alert="true" class="mb-2">
      <h3 slot="title-text">Kommentar från handläggare</h3>
      <div slot="body-text" class="comments-body">
        <p v-if="!!documentGroupComment">
          <LinkWrapper :input="documentGroupComment"></LinkWrapper>
        </p>
        <p v-for="document in invalidDocuments" :key="document.uuid">
          <strong>{{ document.fileName }}</strong
          >:
          <LinkWrapper :input="document.message"></LinkWrapper>
          <br />
        </p>
      </div>
    </CollapseCard>
  </transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CollapseCard from '@/components/CollapseCard.vue';
import LinkWrapper from '@/components/LinkWrapper.vue';

export default {
  components: {
    CollapseCard,
    LinkWrapper
  },
  props: {
    type: {
      type: String,
      required: true
    },
    invalidDocuments: {
      type: Object,
      required: true
    },
    isNewRejection: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'application/applicant'
    }),
    ...mapState({
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    documentGroupComment() {
      return this.applicant?.documentGroups[this.selectedPossessionIndex][
        this.type
      ].comment;
    },
    isApproved() {
      return (
        this.applicant?.documentGroups[this.selectedPossessionIndex][this.type]
          ?.approved || false
      );
    },
    showMessage() {
      return (
        this.isNewRejection &&
        !this.isApproved &&
        (!!this.documentGroupComment ||
          Object.keys(this.invalidDocuments).length > 0)
      );
    }
  }
};
</script>

<style></style>
