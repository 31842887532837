<template>
  <button
    ref="tabBtn"
    :disabled="isDisabled"
    :title="isDisabled ? disabledReason : ''"
    :class="['tab', ...classes, active ? 'active' : '']"
    @click="handleClick"
  >
    <BaseImage v-if="isLocked" :url="svgs.ICONS.LOCKED" class="user-locked" />
    <slot />
    <template v-if="showStatus">
      <StatusCircle
        v-if="status"
        :status="status"
        :title="statusTitle"
        class="icon"
      />
    </template>
  </button>
</template>

<script>
import StatusCircle from '@/components/StatusCircle.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'TabMenuButton',
  components: {
    StatusCircle,
    BaseImage
  },
  props: {
    showStatus: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: () => []
    },
    disabledReason: {
      type: String,
      default: 'Rättigheter saknas'
    },
    link: {
      type: [String, Number],
      default: ''
    },
    statusTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svgs,
      highlight: false,
      withinView: false
    };
  },
  computed: {
    showHighlight() {
      return this.withinView && this.highlight;
    }
  },
  watch: {
    showHighlight(show) {
      if (show) {
        this.$refs.tabBtn.classList.add('highlight');
      } else {
        this.$refs.tabBtn.classList.remove('highlight');
      }
    },
    active: {
      handler(isActive) {
        if (isActive && !this.highlight) {
          this.highlight = true;
        } else {
          this.highlight = false;
        }
      },
      immediate: true
    },
    withinView: {
      handler(isWithinView) {
        if (isWithinView && this.highlight) {
          const highLightDuration =
            parseInt(
              getComputedStyle(document.body).getPropertyValue(
                '--highLightDurationMs'
              )
            ) || 0;
          setTimeout(() => (this.highlight = false), highLightDuration);
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener);
  },
  created() {
    this.scrollListener();

    window.addEventListener('scroll', this.scrollListener);
  },
  methods: {
    handleClick() {
      if (this.active) {
        return;
      }
      this.$emit('tabMenuButtonClick', this.link);
    },
    scrollListener() {
      this.withinView = 70 >= window.pageYOffset;
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  padding: 1.2rem 1.4rem;
  border-radius: 12px 12px 0 0;
  color: var(--color-dark);
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1;
  cursor: pointer;
  border: none;
  background: transparent;
  position: relative;
  z-index: 2;
  will-change: color, transform;
  transition: 0.1s ease-in-out;
  display: flex;
  align-items: center;

  &.active {
    color: var(--color-sky-blue);
    transition: 0.2s 0.2s ease-in-out;
  }

  &.highlight {
    ~ :deep(.active-bg::after) {
      animation: scaleShadow calc(var(--highLightDurationMs) * 1ms) ease
        forwards;
    }

    animation: scale calc(var(--highLightDurationMs) * 1ms) ease forwards;
  }
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.user-locked {
  margin-right: 0.5rem;
}

.icon {
  margin-left: 1rem;
  flex-shrink: 0;
}

@keyframes scale {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.1);
  }
}

@keyframes scaleShadow {
  0% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 3px transparent);
  }
  50%,
  75% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 3px #7b52ea);
  }
  25%,
  75% {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 3px #7b52ea);
  }

  100% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 3px transparent);
  }
}
</style>
