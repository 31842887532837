<template>
  <SubletPage :categories="[reason]">
    <SubletForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      :block-documents-view="blockDocumentsView"
      :form-type="formType"
      v-on="$listeners"
    >
      <div class="reason-subheading">
        <p><i>Välj det skäl som passar bäst in på din situation</i></p>
      </div>
      <div class="radio-btn-wrapper">
        <RadioButton
          v-model="reason.type"
          radio-btn-label="Provsambo"
          radio-btn-value="sambo"
          :selected-value="reason.type"
          :is-disabled="editingDisabled"
          @change="handleRadioBtnSelected"
        />
        <RadioButton
          v-model="reason.type"
          radio-btn-label="Arbete på annan ort eller utomlands"
          radio-btn-value="arbete"
          :selected-value="reason.type"
          :is-disabled="editingDisabled"
          @change="handleRadioBtnSelected"
        />
        <RadioButton
          v-model="reason.type"
          radio-btn-label="Studera på annan ort eller utomlands"
          radio-btn-value="studie"
          :selected-value="reason.type"
          :is-disabled="editingDisabled"
          @change="handleRadioBtnSelected"
        />
        <RadioButton
          v-model="reason.type"
          radio-btn-label="Utlandsvistelse"
          radio-btn-value="utlandsvistelse"
          :selected-value="reason.type"
          :is-disabled="editingDisabled"
          @change="handleRadioBtnSelected"
        />
        <div class="other-reason-container">
          <RadioButton
            v-model="reason.type"
            radio-btn-label="Annan anledning"
            radio-btn-value="other"
            :selected-value="reason.type"
            :is-disabled="editingDisabled"
            @change="handleRadioBtnSelected"
          />
          <InfoButtonModal :title="'Vad är detta?'">
            <p>
              Ett beaktansvärt skäl för uthyrning i andra hand är en legitim
              anledning som motiverar varför du ska få hyra ut din bostad till
              en annan person. Beskrivningen av ett sådant skäl kan variera
              beroende på situation.
            </p>
          </InfoButtonModal>
        </div>
      </div>
      <div v-if="reasonType === 'sambo'">
        <h2>Uppgifter på den du ska provbo med</h2>
        <Fieldset>
          <SubletInput
            v-model="reason.partnerName"
            label="Namn"
            placeholder="Fullständigt namn"
            description="Ange fullständigt namn på personen du ska provbo med"
          />
          <SubletInput
            v-model="reason.partnerSsn"
            label="Personnummer"
            placeholder="Personnummer"
            description="Ange personnummer på personen du ska provbo med"
            :error-text="ssnErrorText"
            :validation="validateSsn"
          />
        </Fieldset>

        <h2>Adress under uthyrningstiden</h2>
        <SubletInput
          v-model="reason.address"
          label="Adress"
          description="Ange den adress på vilken ni kommer att bo på under uthyrningstiden"
          placeholder="Adress"
        />
        <Fieldset>
          <SubletInput
            v-model="reason.postalCode"
            label="Postnummer"
            description="Ange postnummer"
            placeholder="Postnummer"
            error-text="Postnummer måste anges med 5 siffror"
            :validation="validatePostalCode"
          />
          <SubletInput
            v-model="reason.city"
            label="Ort"
            placeholder="Ort"
            description="Ange vilken ort lägenheten ligger i"
          />
        </Fieldset>
        <div class="date-picker-info">
          {{ infoModalText }}
        </div>
        <Fieldset>
          <SubletInput
            v-model="rentFrom"
            label="Önskar hyra ut fr.o.m."
            description="Ange datum"
            type="date"
            :min="getDateAMonthFromCurrentDate(1)"
          />
          <SubletInput
            v-model="rentTo"
            label="Önskar hyra ut t.o.m."
            description="Ange datum"
            type="date"
            :min="rentFrom"
            :max="rentToMaxDate"
          />
        </Fieldset>
        <div>
          <div class="housing-type-container">
            <h2>Boendeform</h2>
            <InfoButtonModal
              class="housing-type-info-modal"
              title="Vad är detta?"
            >
              <p class="text-info">
                Typ av boendeform för där ni kommer att bo under prövotiden.
              </p>
            </InfoButtonModal>
          </div>
          <div class="radio-btn-wrapper">
            <RadioButton
              v-model="reason.housingType"
              radio-btn-label="Hyresrätt"
              radio-btn-value="hyresratt"
              :selected-value="reason.housingType"
              :is-disabled="editingDisabled"
              @change="handleSubRadioBtnSeleted"
            />
            <RadioButton
              v-model="reason.housingType"
              radio-btn-label="Bostadsrätt"
              radio-btn-value="bostadsratt"
              :selected-value="reason.housingType"
              :is-disabled="editingDisabled"
              @change="handleSubRadioBtnSeleted"
            />
            <RadioButton
              v-model="reason.housingType"
              radio-btn-label="Villa"
              radio-btn-value="villa"
              :selected-value="reason.housingType"
              :is-disabled="editingDisabled"
              @change="handleSubRadioBtnSeleted"
            />
          </div>
          <div v-if="reason?.type && reason?.housingType === 'hyresratt'">
            <SubletInput
              v-model="reason.nameOnContract"
              label="Namn på hyreskontrakt"
              description="Ange för- och efternamn på den person som står på hyreskontraktet"
              placeholder="Namn på hyreskontrakt"
            />
            <SubletInput
              v-model="reason.houseOwner"
              label="Namn på hyresvärd"
              description="Ange för- och efternamn på hyresvärd"
              placeholder="Namn på hyresvärd"
            />
            <SubletInput
              v-model="reason.ownerPhone"
              label="Telefon till hyresvärd"
              description="Ange telefonnummer till hyresvärd"
              placeholder="Telefon till hyresvärd"
              error-text="Fältet får endast innehålla siffror"
              :validation="validateNumber"
              inputmode="numeric"
              pattern="[0-9]*"
            />
            <SubletInput
              v-model="reason.ownerEmail"
              label="Email till hyresvärd"
              description="Ange e-postadress till hyresvärd"
              placeholder="Email till hyresvärd"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
            />
          </div>
          <div v-else>
            <SubletInput
              v-model="reason.houseOwner"
              :label="titleName"
              :description="descriptionName"
              :placeholder="titleName"
            />
            <SubletInput
              v-model="reason.ownerPhone"
              :label="titlePhone"
              :description="descriptionPhone"
              :placeholder="titlePhone"
              error-text="Fältet får endast innehålla siffror"
              :validation="validateNumber"
              inputmode="numeric"
              pattern="[0-9]*"
            />
            <SubletInput
              v-model="reason.ownerEmail"
              :label="titleEmail"
              :description="descriptionEmail"
              :placeholder="titleEmail"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
            />
            <ExpandableForm
              :btn-text="expandBtnText"
              :form-expanded="formExpanded"
              :disabled="editingDisabled"
              @click="handleButtonClick"
            >
              <h2>
                Andra
                {{ reason?.housingType === 'villa' ? 'ägare' : 'innehavare' }}
              </h2>
              <SubletInput
                v-model="reason.secondHouseOwner"
                :label="titleName"
                :description="descriptionName"
                :placeholder="titleName"
              />
              <SubletInput
                v-model="reason.secondOwnerPhone"
                :label="titlePhone"
                :description="descriptionPhone"
                :placeholder="titlePhone"
                error-text="Fältet får endast innehålla siffror"
                :validation="validateNumber"
                inputmode="numeric"
                pattern="[0-9]*"
              />
              <SubletInput
                v-model="reason.secondOwnerEmail"
                :label="titleEmail"
                :description="descriptionEmail"
                :placeholder="titleEmail"
                type="email"
                :validation="validEmail"
                error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              />
            </ExpandableForm>
            <Checkbox
              v-model="propertyOwner"
              name="propertyOwner"
              :description="`Jag är delägare / 
                ${reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}
              till det boendet där jag ska provbo`"
              :checked="propertyOwner"
              :is-disabled="editingDisabled"
              @change="handlePropertyOwnerToggle"
            />
          </div>
        </div>
      </div>
      <div v-if="reasonType === 'arbete' || reasonType === 'studie'">
        <Checkbox
          v-model="movingAbroad"
          name="movingAbroad"
          :description="'Jag kommer att bo utomlands under uthyrningstiden'"
          :checked="movingAbroad"
          :is-disabled="editingDisabled"
          @change="handleMovingAbroadToggle"
        />
        <SubletInput
          v-model="reason.city"
          label="Ort"
          placeholder="Ort under uthyrningstiden"
          description="Ange den ort (i Sverige eller utrikes) där du kommer att bo under uthyrningstiden"
        />
      </div>
      <div v-if="reasonType === 'utlandsvistelse'">
        <SubletInput
          v-model="reason.city"
          label="Ort"
          placeholder="Ort under uthyrningstiden"
          description="Ange den ort där du kommer att bo under uthyrningstiden"
        />
        <SubletInput
          v-model="reason.text"
          class="other-reason-textarea"
          name="other-reason"
          type="textarea"
          label="Övrig information"
          placeholder="Övrig information"
          :show-green-checkmark="false"
        />
      </div>
      <div v-if="reasonType === 'other'" class="other-reason-wrapper">
        <SubletInput
          v-model="reason.city"
          label="Ort"
          placeholder="Ort under uthyrningstiden"
          description="Ange den ort (i Sverige eller utrikes) där du kommer att bo under uthyrningstiden"
        />
        <SubletInput
          v-model="reason.text"
          class="other-reason-textarea"
          name="other-reason"
          type="textarea"
          label="Beskriv annan anledning"
          placeholder="Annan anledning"
          :show-green-checkmark="false"
        />
      </div>
      <div
        v-if="!!reasonType && reasonType !== 'sambo'"
        class="date-picker-info"
      >
        {{ infoModalText }}
      </div>
      <Fieldset v-if="!!reasonType && reasonType !== 'sambo'">
        <SubletInput
          v-model="rentFrom"
          label="Önskar hyra ut fr.o.m."
          description="Ange datum"
          type="date"
          :min="getDateAMonthFromCurrentDate(1)"
        />
        <SubletInput
          v-model="rentTo"
          label="Önskar hyra ut t.o.m."
          description="Ange datum"
          type="date"
          :min="rentFrom"
          :max="rentToMaxDate"
        />
      </Fieldset>
    </SubletForm>
  </SubletPage>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePostalCode,
  validatePersonalNumber
} from '@/utils/input-validations';
import { getDateAMonthFromCurrentDate } from '@/utils/common';
import RadioButton from '@/components/RadioButton.vue';
import SubletInput from '@/components/SubletInput.vue';
import Fieldset from '@/components/Fieldset.vue';
import SubletPage from '@/components/SubletPage.vue';
import SubletForm from '@/components/SubletForm.vue';
import InfoButtonModal from '@/components/InfoButtonModal.vue';
import Checkbox from '@/components/Checkbox.vue';
import ExpandableForm from '@/components/ExpandableForm.vue';

export default {
  name: 'Reason',

  components: {
    RadioButton,
    SubletInput,
    Fieldset,
    SubletPage,
    SubletForm,
    InfoButtonModal,
    Checkbox,
    ExpandableForm
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      radioBtnSelected: false,
      validEmail,
      validateNumber,
      validatePostalCode,
      validatePersonalNumber,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      getDateAMonthFromCurrentDate,
      formExpanded: false,
      documentsInfoTextVisible: false,
      formType: 'reason'
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      rejectedCategories: 'subletApplication/rejectedCategories'
    }),
    reasonCategoryRejected() {
      return this.rejectedCategories.includes('reason');
    },
    editingDisabled() {
      return !this.applicationIsEditable && !this.reasonCategoryRejected;
    },
    reason: {
      get() {
        return this.application?.reason;
      },
      set() {
        this.$emit();
      }
    },
    movingAbroad: {
      get() {
        return this.reason?.movingAbroad || false;
      },
      set(newValue) {
        if (this.reason) {
          this.$set(this.reason, 'movingAbroad', newValue);
        }
      }
    },
    propertyOwner: {
      get() {
        return this.reason?.propertyOwner || false;
      },
      set(newValue) {
        if (this.reason) {
          this.$set(this.reason, 'propertyOwner', newValue);
        }
      }
    },
    rentFrom: {
      get() {
        return this.getDateHelper(this.reason.rentFrom);
      },
      set(value) {
        this.reason.rentFrom = this.setDateHelper(value);
        this.reason.rentTo = null;
      }
    },
    rentTo: {
      get() {
        return this.getDateHelper(this.reason.rentTo);
      },
      set(value) {
        this.reason.rentTo = this.setDateHelper(value);
      }
    },
    reasonType() {
      return this.reason?.type || '';
    },
    rentToMaxDate() {
      if (!this.rentFrom) {
        return '';
      }
      return this.sweDateStr(
        new Date(
          new Date(this.rentFrom).setFullYear(
            new Date(this.rentFrom).getFullYear() + 1
          )
        )
      );
    },
    infoModalText() {
      let text = '';
      if (this.reasonType === 'sambo') {
        text =
          'För provsambo är den tillåtna tidsperioden för uthyrning upp till och med ett år.';
      } else {
        text =
          'Hyresvärden godkänner vanligast uthyrning ett år åt gången, men kan ibland tillåta uthyrning under längre perioder.';
      }
      text +=
        ' När du gör din ansökan är det startdatum och slutdatum du väljer endast önskemål. Tänk på att du behöver hyra ut i minst tre månader för att få en godkänd ansökan.';
      return text;
    },
    blockDocumentsView() {
      const pathname = window.location.pathname;
      const parts = pathname.split('/');
      const category = parts.pop();

      if (category === 'skal' && !this.reasonType) {
        return true;
      }

      return false;
    },
    titleName() {
      return `Namn på ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    titlePhone() {
      return `Telefon till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    titleEmail() {
      return `Email till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    descriptionName() {
      return `Ange för- och efternamn på ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    descriptionPhone() {
      return `Ange telefonnummer till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    descriptionEmail() {
      return `Ange e-postadress till ${this.reason?.housingType === 'villa' ? 'ägare' : 'innehavare'}`;
    },
    expandBtnText() {
      if (this.formExpanded) {
        return this.reason?.housingType === 'villa'
          ? 'Ta bort ägare'
          : 'Ta bort andra innehavare';
      } else {
        return this.reason?.housingType === 'villa'
          ? 'Fler ägare? Klicka för att lägga till'
          : 'Fler innehavare? Klicka för att lägga till';
      }
    }
  },
  watch: {
    reasonType(newReason, oldReason) {
      const reason = this.reason;
      // Clears fields depending if new active reason type is choosen
      if (newReason !== oldReason) {
        switch (newReason) {
          case 'other':
            for (const key in reason) {
              if (key === 'text' || key === 'type') {
                continue;
              }
              //  Clear all fields except 👆
              this.reason[key] = '';
            }
            break;
          case 'sambo':
            this.reason.text = '';
            break;
          default:
            for (const key in reason) {
              if (key === 'type') {
                continue;
              }
              //  Clear all fields except 👆
              this.reason[key] = '';
            }
        }
        // Reset date fields to null and not ''
        this.reason.rentFrom = null;
        this.reason.rentTo = null;
      }
    }
  },
  mounted() {
    if (
      this.reason.secondHouseOwner ||
      this.reason.secondOwnerEmail ||
      this.reason.secondOwnerPhone
    ) {
      this.formExpanded = true;
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'app/goToNextCategory'
    }),
    sweDateStr(value = null) {
      return new Date(value).toLocaleDateString('sv-SE');
    },
    setDateHelper(value) {
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (value.match(regEx)) {
        return this.sweDateStr(value);
      }
      return this.sweDateStr();
    },
    getDateHelper(value) {
      return value ? this.sweDateStr(value) : '';
    },
    handleRadioBtnSelected(value) {
      this.reason.type = value;
      this.emitEditing();
    },
    handleSubRadioBtnSeleted(value) {
      this.reason.housingType = value;
      this.emitEditing();
    },
    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    handleButtonClick() {
      this.formExpanded = !this.formExpanded;

      if (!this.formExpanded) {
        this.reason.secondHouseOwner = '';
        this.reason.secondOwnerEmail = '';
        this.reason.secondOwnerPhone = '';
      }

      this.emitEditing();
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    },
    handlePropertyOwnerToggle() {
      this.propertyOwner = !this.propertyOwner;
    },
    handleMovingAbroadToggle() {
      this.movingAbroad = !this.movingAbroad;
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 0.5rem;
}

.reason-subheading {
  margin-bottom: 2.8rem;
}

.radio-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.3rem;
  margin-bottom: 4.8rem;
}

.other-reason-container {
  display: flex;
  align-items: center;
}

.other-reason-wrapper {
  margin-top: 4.8rem;
  display: flex;
  flex-direction: column;

  .other-reason-textarea {
    resize: none;
  }
}

.housing-type-container {
  display: flex;
  align-items: center;

  > h2 {
    margin: 0;
    padding: 0;
  }

  .housing-type-info-modal {
    margin-bottom: 0.5rem;
  }

  .text-info {
    padding: 1rem 0;
  }
}

.next-category-button {
  margin-top: 4.8rem;
}

.date-picker-info {
  display: block;
  font-weight: 400;
  font-size: 1.6rem;
  font-style: italic;
  margin-bottom: 2.8rem;
}
</style>
