<template>
  <div>
    <InfoModal
      v-if="errorModalVisible"
      title="Något gick fel"
      :show-close-btn="false"
      confirm-label="Försök igen"
      abort-label="Logga ut"
      @click="errorModalClick"
    >
      <p>Det gick inte att hämta dina byten.</p>
    </InfoModal>
    <div
      v-if="isInitiating || isFetchingApplications || isCreatingApplication"
      class="spinner-wrapper"
    >
      <BaseLoadingSpinner />
      <p class="spinner-label">{{ loadingText }}</p>
    </div>
    <InfoModal
      v-else-if="!application"
      :title="
        applications.length > 0
          ? 'Välj befintligt byte'
          : 'Här är det tomt just nu'
      "
      mobile-full-width
      :show-close-btn="true"
      :disable-outside-click="true"
      @click="logout"
    >
      <div class="empty-body-container">
        <template v-if="applications.length === 0">
          <section class="no-applications-container">
            <p>
              Det ser ut som att du inte har något påbörjat byte från
              Lägenhetsbyte.se än. Fortsätt ditt sökande på
              <a
                href="https://www.lagenhetsbyte.se"
                target="_blank"
                rel="noopener noreferrer"
                >Lägenhetsbyte.se</a
              >
              och återkom när du hittat ditt byte.
            </p>
          </section>
        </template>
      </div>
      <div class="body-container">
        <template v-if="applications.length > 0">
          <section
            v-for="(group, title) in groupedApplications"
            :key="`group-${title}`"
            class="existing-applications"
          >
            <h4>{{ title }}</h4>
            <ul>
              <li v-for="application in group" :key="application.id">
                <i
                  v-if="isNotInactivator(application)"
                  class="disclaimer-disabled"
                  >(Avbruten av annan part)</i
                >
                <label
                  :for="`r_id_${application.id}`"
                  class="list-item"
                  :class="{
                    disabled: isNotInactivator(application)
                  }"
                >
                  <input
                    :id="`r_id_${application.id}`"
                    v-model="selectedApplication"
                    :disabled="isNotInactivator(application)"
                    type="radio"
                    name="radio_select_application"
                    class="input-radio"
                    :value="`${application.id}`"
                  />
                  <label
                    :for="`r_id_${application.id}`"
                    class="custom-radiobox"
                  ></label>
                  <div
                    class="progress"
                    :data-progress="application.progress"
                    :style="{
                      '--progress': `${application.progress * 3.6}deg`
                    }"
                  >
                    {{ application.progress }}
                  </div>
                  <div class="application-meta">
                    <p class="application-apartments">
                      {{ getApplicationApartments(application) }}
                    </p>
                    <p class="application-members">
                      {{ getApplicationNames(application) }}
                    </p>
                  </div>
                </label>
              </li>
            </ul>
          </section>
        </template>
        <div class="btn-wrapper">
          <BaseButton
            :is-rounded="true"
            :is-small="true"
            :is-disabled="!selectedApplication"
            type="button"
            class="button"
            @click="selectApplication(selectedApplication)"
          >
            Gå vidare till valt byte
            <BaseIconText
              class="button-arrow-right"
              :icon-url="svgs.ICONS.ARROW_RIGHT"
            >
            </BaseIconText>
          </BaseButton>
          <BaseButton
            v-if="!isLbMode"
            type="button"
            :is-default="true"
            :is-small="true"
            :is-rounded="true"
            class="button create-new-btn"
            @click="createNewApplication"
          >
            <BaseIconText :icon-url="svgs.ICONS.ADD_CIRCLE_BLUE"
              >Skapa helt ny ansökan
            </BaseIconText>
          </BaseButton>
        </div>
      </div>
    </InfoModal>
    <div v-else class="spinner-wrapper">
      <BaseLoadingSpinner />
      <p class="spinner-label">Hämtar information...</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import applicationService from '@/services/applicationService';
import InfoModal from '@/components/InfoModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import { readableNames } from '@/utils/common';

export default {
  components: {
    InfoModal,
    BaseLoadingSpinner,
    BaseIconText,
    BaseButton
  },

  data() {
    return {
      isFetchingApplications: false,
      isCreatingApplication: false,
      applications: [],
      errorModalVisible: false,
      svgs,
      selectedApplication: ''
    };
  },

  computed: {
    ...mapState('app', ['appReady', 'landlord', 'isInitiating']),
    ...mapState('application', ['application']),
    ...mapGetters({
      isLbMode: 'app/isLbMode'
    }),
    groupedApplications() {
      if (!this.applications) {
        return [];
      }

      const groups = this.applications.reduce((result, obj) => {
        const { status } = obj;

        if (status === 'SENT') {
          // Sent
          result = this.createGroup(result, 'Skickade', obj);
        } else if (status === 'INACTIVE') {
          // Inactive
          result = this.createGroup(result, 'Avbrutna', obj);
        } else if (status === 'ARCHIVED') {
          // Declined/archived
          result = this.createGroup(result, 'Avslutade', obj);
        } else {
          // Rest
          result = this.createGroup(result, 'Pågående', obj);
        }
        return result;
      }, {});

      const entries = Object.entries(groups);

      entries.sort(([key1], [key2]) => {
        if (key1 === 'Pågående') {
          return -1;
        } else if (key2 === 'Pågående') {
          return 1;
        } else {
          return 0;
        }
      });
      // Make sure 'Pågående' always is first
      return Object.fromEntries(entries);
    },
    loadingText() {
      if (this.isFetchingApplications) {
        return 'Hämtar dina byten...';
      }
      if (this.isCreatingApplication) {
        return 'Skapar byte...';
      }
      return 'Hämtar information...';
    }
  },
  async created() {
    this.fetchUserApplications();
  },
  methods: {
    ...mapActions({
      initiate: 'app/initiate',
      logout: 'app/logout'
    }),
    ...mapMutations({ setApplicationId: 'application/setApplicationId' }),

    async handleShowModal() {
      if (
        !this.application &&
        !this.isLbMode &&
        this.applications.length === 0
      ) {
        await this.createNewApplication();
      }
    },

    createGroup(list, groupKey, item) {
      if (!list[groupKey]) {
        list[groupKey] = [];
      }
      list[groupKey].push(item);
      return list;
    },

    isNotInactivator(application) {
      return application.status === 'INACTIVE' && !application.isInactivator;
    },

    async selectApplication(applicationId) {
      this.setApplicationId(applicationId);
      await this.initiate();
    },

    async createNewApplication() {
      this.isCreatingApplication = true;
      const newApplication = await applicationService.createApplication(
        this.landlord?.landlordId,
        this.landlord?.name
      );
      this.setApplicationId(newApplication.id);
      await this.initiate();
    },

    async fetchUserApplications() {
      this.isFetchingApplications = true;
      try {
        const res = await applicationService.getUserApplications();
        this.applications = res.sort((a, b) => b.createdDate - a.createdDate);
        await this.handleShowModal();
      } catch {
        this.errorModalVisible = true;
      }
      this.isFetchingApplications = false;
    },

    getApplicationApartments(application) {
      return application.applicants
        .map(x => x.apartments)
        .map(y => y.apartment1)
        .map(z => (z.address ? z.address : 'Adress saknas'))
        .join(', ');
    },

    getApplicationNames(application) {
      const names = application.applicants
        .map(x => x.members)
        .map(y => y.member1)
        .map(z => (z.name ? z.name?.split(' ')[0] : 'Namn saknas'));

      return readableNames(names);
    },

    errorModalClick(retry) {
      if (retry) {
        this.errorModalVisible = false;
        this.fetchUserApplications();
      } else {
        this.logout();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
}

p {
  max-width: none;
  font-size: 1.6rem;
  @media ($mobile) {
    font-size: 1.4rem;
  }
}

.no-applications-container {
  margin-bottom: 2rem;

  a {
    text-decoration: none;
    color: var(--color-blue);
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.existing-applications {
  margin-bottom: 2rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f8f8fa;
    border-radius: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  a {
    text-decoration: none;
  }

  li {
    position: relative;
  }

  li .list-item {
    margin: 0 2rem;
    padding: 1.2rem 0;
    color: #000;
    display: flex;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--color-light-gray);
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  li .disclaimer-disabled {
    position: absolute;
    bottom: 0.4rem;
    right: 0.7rem;
    font-size: 1.2rem;
  }

  li:nth-last-of-type(1) .list-item {
    border-bottom: none;
  }

  .application-apartments {
    font-weight: 600;
  }

  .application-members {
    color: var(--color-gray);
  }
}

.create-container {
  margin-top: 20px;

  p {
    margin-bottom: 20px;
  }
}

.progress {
  flex-shrink: 0;
  display: flex;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background: conic-gradient(
    var(--color-blue) var(--progress),
    var(--color-light-gray) 0deg
  );
  font-size: 0;
}

.progress::after {
  content: attr(data-progress) '%';
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5px;
  border-radius: 50%;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
  text-align: center;
}

.spinner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner-label {
  margin: 1rem 0 0;
}

.btn-wrapper {
  position: sticky;
  bottom: -1px;
  margin: 0;
  height: 60px;
  background-color: #fff;
  display: flex;
  padding: 10px 0 10px 0;
  gap: 1rem;
  @media ($mobile) {
    display: block;
    height: 120px;
    .button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

.button {
  font-size: 1.2rem;
}

.button-arrow-right {
  margin-left: 1rem;
  :deep(img) {
    margin-right: 0;
  }
}

.create-new-btn {
  color: var(--color-blue);
  border-color: var(--color-blue);
}

.custom-radiobox {
  width: 1.6rem;
  height: 1.6rem;
  background-color: transparent;
  border: 2px solid var(--color-blue);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  &::after {
    content: '';
    position: absolute;
    height: 0.8rem;
    width: 0.8rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-blue);
    border-radius: 50%;
    opacity: 0;
    transition: 0.3s ease;
  }
}
.input-radio {
  position: absolute;
  left: -9999px;

  &:checked + .custom-radiobox::after {
    opacity: 1;
  }
}
</style>
