<template>
  <SwapDefaultPage
    :possessions="
      !useSplitDocumentUpload('RENTAL_INVOICE')
        ? []
        : getMembersListForTab(applicant, selectedPossessionIndex)
    "
    @applicantClick="handleApplicantClick"
    @possessionClick="setSelectedPossessionIndex"
  >
    <RejectionMessageCardDocs
      type="RENTAL_INVOICE"
      :invalid-documents="invalidDocuments"
      :is-new-rejection="isNewRejection"
    />
    <CollapseCard
      :closed-identifier="'invoice_modal_closed'"
      :is-primary="true"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <p>
          Här laddar du upp en kopia på hyresavi alternativt en skärmavbild från
          bank på autogiro / betalning.
        </p>
        <br />
        <p>
          Det går bra att skicka dokument som är inscannade så väl som
          fotokopior.
        </p>
        <br />

        <p><strong>Tänk på att:</strong></p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          Tips: För att fota dokument på bästa sätt rekommenderar vi att du
          använder dig av en app som scannar av dokument. Exempel på appar som
          fungerar bra till både iOS och Android är Adobe Scan och Genius Scan,
          båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a :href="`mailto:${getSupportAddress}`" target="_blank">
            {{ getSupportAddress }}
          </a>
        </p>
      </template>
    </CollapseCard>
    <SwapDocumentUpload
      type="RENTAL_INVOICE"
      :invalid-documents="invalidDocuments"
    ></SwapDocumentUpload>
  </SwapDefaultPage>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import svgs from '@/utils/icon-constants';
import { getInvalidDocuments, getMembersListForTab } from '@/utils/common';

import SwapDocumentUpload from '@/components/SwapDocumentUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import RejectionMessageCardDocs from '@/components/RejectionMessageCardDocs.vue';

export default {
  name: 'RentalInvoice',

  components: {
    SwapDocumentUpload,
    CollapseCard,
    SwapDefaultPage,
    RejectionMessageCardDocs
  },
  data() {
    return {
      svgs,
      getMembersListForTab
    };
  },
  computed: {
    ...mapGetters({
      applicant: 'application/applicant',
      getSupportAddress: 'app/getSupportAddress',
      useSplitDocumentUpload: 'application/useSplitDocumentUpload'
    }),
    ...mapState({
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      application: state => state.application.application,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    invalidDocuments() {
      if (!this.isNewRejection) {
        return {};
      }
      const documents =
        this.applicant?.documentGroups[this.selectedPossessionIndex]
          .RENTAL_INVOICE.documents;
      return getInvalidDocuments(documents);
    },
    isNewRejection() {
      return !!this.applicant?.newRejections.documentGroups[
        this.selectedPossessionIndex
      ].RENTAL_INVOICE;
    },
    isLastApplicantIndex() {
      return (
        this.application.applicants.length - 1 === this.selectedApplicantIndex
      );
    }
  },
  methods: {
    ...mapMutations({
      setSelectedApplicantIndex: 'application/setSelectedApplicantIndex',
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    handleApplicantClick(index) {
      this.setSelectedApplicantIndex(index);
      this.setSelectedPossessionIndex(0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style></style>
