<template>
  <CollapseCard :is-green="true">
    <h3 slot="title-text">Era uppgifter granskas</h3>
    <template slot="body-text">
      <p>
        Just nu granskar hyresvärdarna era uppgifter. Det innebär att det inte
        går att göra fler ändringar.
      </p>
    </template>
  </CollapseCard>
</template>

<script>
import CollapseCard from '@/components/CollapseCard.vue';

export default {
  components: {
    CollapseCard
  }
};
</script>
