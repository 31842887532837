<template>
  <div class="flex-center bankid-outerwrapper">
    <section class="bankid-wrapper mg-all-10">
      <section v-if="$slots.header" class="header flex-center">
        <slot name="header" />
      </section>

      <div class="bankid-selector">
        <BaseImage class="bankid-image" :url="icons.BANKID"></BaseImage>
      </div>

      <section v-if="isMobile & showStartOtherDevice" class="input-wrapper">
        <p v-if="!isLbLogin" class="bankid-text mb-1">
          <strong>Med BankID</strong><br />
          Om du loggar in med BankID hämtar vi dina uppgifter från
          folkbokföringen vilket underlättar ifyllnaden av formulären. Det
          säkerställer också att du är du och ingen annan obehörig.
        </p>

        <BaseButton
          :disabled="isInvalid"
          :is-loading="isAuthenticatingWithQr"
          :is-fullwidth="true"
          :is-rounded="true"
          class="full-width"
          @click="bankidStartQr()"
        >
          <p :style="{ 'margin-right': '10px' }">Starta BankID</p>
          <BaseIconText
            class="info-modal-arrow-right"
            :icon-url="icons.ICONS.ARROW_RIGHT"
          >
          </BaseIconText>
        </BaseButton>
      </section>
      <section v-else-if="!isMobile" class="input-wrapper">
        <p v-if="!isLbLogin" class="bankid-text mb-1">
          <strong>Med BankID</strong><br />
          Om du loggar in med BankID hämtar vi dina uppgifter från
          folkbokföringen vilket underlättar ifyllnaden av formulären. Det
          säkerställer också att du är du och ingen annan obehörig.
        </p>

        <BaseButton
          :disabled="isInvalid"
          :is-loading="isAuthenticatingWithQr"
          :is-fullwidth="true"
          :is-rounded="true"
          class="full-width mb-1"
          @click="bankidStartQr()"
        >
          <p :style="{ 'margin-right': '10px' }">Mobilt BankID</p>
          <BaseIconText
            class="info-modal-arrow-right"
            :icon-url="icons.ICONS.ARROW_RIGHT"
          >
          </BaseIconText>
        </BaseButton>
        <BaseButton
          :is-loading="false"
          :is-fullwidth="true"
          :is-rounded="true"
          :is-default="true"
          :is-disabled="isAuthenticatingWithQr || successVisible"
          @click.stop.prevent="
            !isAuthenticatingWithQr &&
              !isAuthenticatingAutostart &&
              bankidAutostart()
          "
          >BankID på denna enhet</BaseButton
        >
        <SkandiaInfoLink v-if="isSkandia" class="skandia" />
      </section>

      <template v-if="isMobile & !showStartOtherDevice">
        <BaseButton
          :is-loading="false"
          :is-fullwidth="true"
          :is-rounded="true"
          :is-default="true"
          :is-disabled="isAuthenticatingWithQr || successVisible"
          class="mb-1"
          @click.stop.prevent="
            !isAuthenticatingWithQr &&
              !isAuthenticatingAutostart &&
              bankidAutostart()
          "
          >Starta BankID på denna enhet</BaseButton
        >

        <button
          class="mt-10 mb-1 simple-button"
          @click="showStartOtherDevice = true"
        >
          BankID på annan enhet
        </button>
        <SkandiaInfoLink v-if="isSkandia" class="skandia" />
      </template>

      <p class="text-error">
        <span v-if="bankidMessage">
          {{ bankidMessage }}
        </span>
      </p>

      <slot />
    </section>

    <InfoModal
      v-if="
        isAuthenticatingWithQr || isAuthenticatingAutostart || successVisible
      "
      :blur-bg="true"
      :title="infoModalTitle"
      :show-close-btn="false"
    >
      <div class="waiting-wrapper flex-center flex-col">
        <BaseLoadingSpinner
          v-if="isAuthenticatingAutostart"
          class="waiting-spinner"
        />

        <!-- QR -->
        <div v-if="isAuthenticatingWithQr" class="authenticating-container">
          <div class="bankid-selector">
            <BaseImage
              v-if="!successVisible"
              class="bankid-image"
              :url="icons.BANKID"
            ></BaseImage>
          </div>

          <p class="bankid-info">
            Starta BankID appen i din mobil och skanna QR-koden.
          </p>
          <div class="waiting-wrapper flex-center flex-col">
            <BaseLoadingSpinner v-if="!qr" class="waiting-spinner" />
            <BaseImage v-else :url="qr" class="qr" />
          </div>

          <BaseButton
            :is-rounded="true"
            :is-default="true"
            class="cancel-auth-button"
            @click="bankidCancel(orderRef)"
            >Avbryt</BaseButton
          >
        </div>

        <div v-if="isInitiating || successVisible" class="success-container">
          <BaseLoadingSpinner v-if="isInitiating" class="waiting-spinner" />

          <!-- DONE -->
          <AnimatedCheckbox
            v-else-if="successVisible"
            :show-animation="true"
            :is-large="true"
            class="waiting-spinner"
            @done="$emit('success', responseData)"
          ></AnimatedCheckbox>
        </div>
      </div>
    </InfoModal>
  </div>
</template>

<script>
import BaseImage from '@/components/BaseImage.vue';
import BaseButton from '@/components/BaseButton.vue';
import AnimatedCheckbox from '@/components/AnimatedCheckbox.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import InfoModal from '@/components/InfoModal.vue';
import SkandiaInfoLink from '@/components/SkandiaInfoLink.vue';
import icons from '@/utils/icon-constants';
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    BaseButton,
    BaseImage,
    AnimatedCheckbox,
    BaseLoadingSpinner,
    BaseIconText,
    InfoModal,
    SkandiaInfoLink
  },

  props: {
    authPath: {
      type: String,
      default: ''
    },

    collectPath: {
      type: String,
      default: ''
    },

    qrPath: {
      type: String,
      default: ''
    },

    cancelPath: {
      type: String,
      default: ''
    },

    baseUrl: {
      type: String,
      default: ''
    },

    isLbLogin: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      axiosInstance: null,
      bankidMessage: null,
      isAuthenticatingAutostart: false,
      isAuthenticatingWithQr: false,
      qr: null,
      orderRef: null,
      successVisible: false,
      responseData: null,
      icons,
      showStartOtherDevice: false,
      autoStartToken: null,
      abortController: new AbortController()
    };
  },

  computed: {
    ...mapState('app', ['inviteCode', 'isInitiating', 'landlord']),
    ...mapGetters({
      isMobile: 'app/isMobile'
    }),
    skandiaLandlordIds() {
      // This component is used by both AH & BA
      // Therefore it's an optional getter for BA, to prevent AH from crashing
      return this.$store.getters['application/skandiaLandlordIds'] || [];
    },
    isSkandia() {
      return this.skandiaLandlordIds.includes(this.landlord?.landlordId);
    },
    infoModalTitle() {
      if (this.isInitiating) {
        return 'Vänligen vänta...';
      } else if (this.isLbLogin) {
        return 'Logga in med BankID via Lägenhetsbyte';
      }
      return 'Logga in med BankID';
    },

    isInvalid() {
      return (
        this.isAuthenticatingWithQr ||
        this.isAuthenticatingAutostart ||
        this.successVisible
      );
    },

    startUrl() {
      const returnUrl = `${window.location.href}#safari`;

      if (this.isIphoneMode) {
        return `https://app.bankid.com/?autostarttoken=${this.autoStartToken}&redirect=${returnUrl}`;
      }

      return `bankid:///?autostarttoken=${this.autoStartToken}&redirect=null`;
    },

    isIphoneMode() {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      return iOS && webkit && !ua.match(/CriOS/i);
    }
  },

  created() {
    const config = {
      baseURL: this.baseUrl,
      withCredentials: true,
      'content-Type': 'application/json'
    };

    this.axiosInstance = axios.create(config);

    if (this.isIphoneMode) {
      this.$router.push(`#safari`);
    }
  },

  methods: {
    async apiCall(path) {
      try {
        const res = await this.axiosInstance.get(`${this.baseUrl}${path}`, {
          signal: this.abortController.signal
        });

        return { status: res.status, data: res.data };
      } catch (error) {
        if (error.response?.status === 0) {
          // Prolly aborted by safari
          return false;
        }
        if (
          this.abortController &&
          this.abortController.signal &&
          !this.abortController.signal.aborted
        ) {
          throw error;
        } else {
          return false;
        }
      }
    },

    async bankidStartQr() {
      try {
        this.qr = null;
        this.bankidMessage = null;
        this.isAuthenticatingWithQr = true;

        await this.getBankIdOrder(true);
        this.collect(true);
      } catch (error) {
        this.handleError(error);
      }
    },

    async getQr() {
      const { data } = await this.apiCall(
        this.addToPath(this.qrPath, this.orderRef)
      );

      return data.url;
    },

    async getBankIdOrder(qr) {
      this.orderRef = null;
      this.autoStartToken = null;

      let {
        data: { orderRef, autoStartToken }
      } = await this.axiosInstance.get(this.authPath, {
        params: { qr: qr ? 'true' : '' }
      });

      this.orderRef = orderRef;
      this.autoStartToken = autoStartToken;
    },

    async bankidAutostart() {
      try {
        this.qr = null;
        this.bankidMessage = null;
        this.isAuthenticatingAutostart = true;

        await this.getBankIdOrder();
        this.collect();

        this.$nextTick(() => {
          window.location.href = this.startUrl;
        });
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },

    async bankidCancel(orderRef) {
      this.qr = null;
      await this.axiosInstance.get(this.addToPath(this.cancelPath, orderRef));
      this.bankidMessage = 'Åtgärden avbruten.';
      this.isAuthenticatingAutostart = false;
      this.isAuthenticatingWithQr = false;
    },

    handleSuccess(responseData) {
      this.responseData = responseData;
      this.successVisible = true;

      this.isAuthenticatingAutostart = false;
      this.isAuthenticatingWithQr = false;
      this.orderRef = null;
      this.autoStartToken = null;
    },

    handleError(error) {
      if (this.isAuthenticatingWithQr || this.isAuthenticatingAutostart) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.readableMessage
        ) {
          this.bankidMessage = error.response.data.readableMessage;
        } else if (this.isLbLogin) {
          this.bankidMessage =
            'Ett fel inträffade, ifall du inte har verifierat ditt Lägenhetsbyte-konto med BankID, välj istället att logga in med användarnamn och lösenord nedan.';
        } else {
          this.bankidMessage = 'Ett oväntat fel inträffade, försök igen.';
        }
      }

      this.isAuthenticatingAutostart = false;
      this.isAuthenticatingWithQr = false;
    },

    addToPath(url, ...toAdd) {
      let path = url;
      for (let i = 0; i < toAdd.length; i++) {
        if (toAdd[i] && !path.endsWith('/')) {
          path = path + '/' + toAdd[i];
        } else if (toAdd[i]) {
          path = path + toAdd[i];
        }
      }
      return path;
    },

    async collect(fetchQr) {
      if (this.responseData) {
        // Ignore if success
        return;
      }

      if (fetchQr || this.qr) {
        this.qr = await this.getQr();
      }

      try {
        const response = await this.apiCall(
          this.addToPath(this.collectPath, this.orderRef, this.inviteCode)
        );

        if (this.isLbLogin && (response.status !== 201 || response === false)) {
          // Wait between every poll
          await new Promise(resolve => setTimeout(resolve, 1200));
          return await this.collect();
        }

        if (
          !this.isLbLogin &&
          (response.status === 204 || response === false)
        ) {
          // Wait between every poll
          await new Promise(resolve => setTimeout(resolve, 1200));
          return await this.collect();
        }

        this.handleSuccess({
          ...response.data,
          statusCode: response.status
        });
      } catch (error) {
        // Common error when switching from browser, ignore and restart collection
        if (error.response?.status === 0 || error.code === 'ECONNABORTED') {
          return await this.collect();
        }

        this.handleError(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0 0 1.4rem;
  margin-bottom: 10px;
  width: 100%;
  color: var(--color-dark);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.4;
  position: relative;
  border-bottom: solid 1px var(--color-light-gray);
}

.login-wrapper {
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bankid-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  background: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1.4rem 2rem 2rem;
  position: relative;
  overflow: hidden;
}

.authenticating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
}

.qr {
  margin-bottom: 20px;
  height: 212px;
  width: 212px;
}

.cancel-auth-button {
  margin-bottom: 30px;
}

.bankid-selector {
  padding: 5px 10px;
  background: transparent;
  margin-bottom: 20px !important;
  border-radius: 0 10px 10px 0;
  &--fixedHeight {
    height: 50px;
  }
}

.bankid-image {
  height: 48px;
  width: 52px;
}

.bankid-outerwrapper {
  width: 100%;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.bankid-text {
  color: var(--color-gray);
}

.waiting-wrapper {
  background-color: white;
  color: var(--color-gray);
  padding: 20px;
  border-radius: 10px;
}

.waiting-spinner {
  height: 50px;
  width: 50px;
}

.flex-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.text-error {
  margin-top: 15px;
  color: var(--color-error);
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.success-container {
  height: 50px;
}

.skandia {
  margin-top: 20px;
}
</style>
