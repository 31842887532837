<template>
  <Summary
    title="Skäl till bytet"
    category="SWAPREASON"
    :has-empty-info-fields="!!rejectionMessage || categoryHasErrors"
    :is-approved="isApproved"
    :reject-reason="!isApproved && rejectionMessage ? rejectionMessage : ''"
    @click="$emit('goToCategory', $event)"
  >
    <SummaryBody
      v-for="(fields, groupName) in fieldGroups"
      :key="groupName"
      :sub-heading="groupName"
    >
      <template v-for="(field, fieldName) in fields">
        <SwapSummaryField
          :key="fieldName"
          :label="field.label"
          :field-name="fieldName"
          :field-value="field.value"
          :is-valid="field.valid"
        />
      </template>
    </SummaryBody>
  </Summary>
</template>

<script>
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import SwapSummaryField from './SwapSummaryField.vue';

export default {
  components: {
    Summary,
    SummaryBody,
    SwapSummaryField
  },
  props: {
    rejectionMessage: {
      type: String,
      default: ''
    },
    categoryHasErrors: {
      type: Boolean,
      required: true
    },
    swapReason: {
      type: Object,
      required: true
    },
    isApproved: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    fieldGroups() {
      return {
        '': {
          description: this.swapReason.description
        }
      };
    }
  }
};
</script>
