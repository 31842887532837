<template>
  <div class="field-wrapper">
    <transition name="fade" mode="out-in">
      <div v-if="formExpanded">
        <slot />
      </div>
    </transition>
    <div class="btn-container">
      <button
        class="expand-btn"
        :disabled="disabled"
        type="button"
        @click="$emit('click')"
      >
        <BaseIconText class="expand-text" :icon-url="iconUrl">
          {{ btnText }}
        </BaseIconText>
      </button>
    </div>
  </div>
</template>

<script>
import BaseIconText from './BaseIconText.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'ExpandableForm',
  components: {
    BaseIconText
  },
  props: {
    btnText: {
      type: String,
      default: ''
    },
    formExpanded: {
      type: Boolean,
      default: false
    },
    isPersonalInfo: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    iconUrl() {
      if (this.formExpanded) {
        return this.disabled
          ? svgs.ICONS.REMOVE_CIRCLE_GREY
          : svgs.ICONS.REMOVE_CIRCLE_BLUE;
      }
      return this.disabled
        ? svgs.ICONS.ADD_CIRCLE_GREY
        : svgs.ICONS.ADD_CIRCLE_BLUE;
    }
  }
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  .btn-container {
    margin-bottom: 4.8rem;

    .expand-btn {
      border: none;
      outline: none;
      background-color: transparent;
      color: var(--color-blue);
      cursor: pointer;
      font-weight: 600;
      display: flex;
      justify-content: center;
      padding: 0;
      @media ($mobile) {
        text-align: left;
      }
      :deep(img.icon) {
        margin-right: 5px;
      }
      &[disabled] {
        color: var(--color-disabled);
        cursor: not-allowed;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
