<template>
  <div
    class="collapse-card-wrapper"
    :style="{ 'border-top-color': borderTopColor }"
    :class="[
      { 'is-primary': isPrimary },
      { 'is-alert': isAlert },
      { 'is-green': isGreen }
    ]"
  >
    <div class="inner-wrapper">
      <div
        v-if="hasTitleSlot"
        class="collapse-card"
        :class="[{ active: open }]"
        @click="toggleOpen"
      >
        <div class="collapse-title">
          <div class="left">
            <BaseImage class="icon-tip" :url="currentIcon"></BaseImage>
            <slot name="title-text" />
          </div>

          <svg
            class="toggle-open-icon"
            :class="{ rotated: open }"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99701 9.99702L16.0711 10.0041C16.3374 10.0041 16.5927 9.8983 16.7811 9.71C16.9694 9.5217 17.0751 9.2663 17.0751 9C17.0751 8.7337 16.9694 8.4783 16.7811 8.29C16.5927 8.1017 16.3374 7.99591 16.0711 7.99591L9.99701 8.00298L10.0041 1.92893C10.0041 1.66263 9.89829 1.40724 9.70999 1.21893C9.52168 1.03063 9.26629 0.924841 8.99999 0.924841C8.73368 0.924841 8.47829 1.03063 8.28999 1.21893C8.10168 1.40724 7.99589 1.66263 7.99589 1.92893L8.00297 8.00298L1.92892 7.99591C1.79691 7.99537 1.6661 8.02097 1.54403 8.07124C1.42196 8.12151 1.31106 8.19545 1.21771 8.28879C1.12437 8.38214 1.05043 8.49304 1.00016 8.61511C0.949888 8.73718 0.924287 8.86799 0.924828 9C0.924287 9.13201 0.949887 9.26282 1.00016 9.38489C1.05042 9.50696 1.12437 9.61786 1.21771 9.71121C1.31106 9.80455 1.42196 9.87849 1.54403 9.92876C1.6661 9.97903 1.79691 10.0046 1.92892 10.0041L8.00297 9.99702L7.9959 16.0711C7.99536 16.2031 8.02095 16.3339 8.07122 16.456C8.12149 16.578 8.19543 16.6889 8.28878 16.7823C8.38213 16.8756 8.49303 16.9496 8.6151 16.9998C8.73716 17.0501 8.86798 17.0757 8.99999 17.0752C9.132 17.0757 9.26281 17.0501 9.38488 16.9998C9.50694 16.9496 9.61785 16.8756 9.71119 16.7823C9.80454 16.6889 9.87848 16.578 9.92875 16.456C9.97902 16.3339 10.0046 16.2031 10.0041 16.0711L9.99701 9.99702Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div class="collapse-body-wrapper" :class="[{ active: open }]">
        <div class="collapse-body">
          <slot name="body-text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';

export default {
  components: {
    BaseImage
  },
  props: {
    closedIdentifier: {
      type: String,
      default: null
    },
    initiallyClosed: {
      type: Boolean,
      default: false
    },
    isPrimary: { type: Boolean, default: false },
    isAlert: { type: Boolean, default: false },
    isGreen: { type: Boolean, default: false }
  },
  data() {
    return {
      svgs,
      open: true
    };
  },
  computed: {
    borderTopColor() {
      if (this.isAlert) {
        return '#ed63d2';
      }
      if (this.isGreen) {
        return '#2EC973';
      }
      return '#409fff';
    },
    currentIcon() {
      if (this.isAlert) {
        return svgs.ICONS.TIP_DARK;
      }
      if (this.isGreen) {
        return svgs.ICONS.MAGNIFYING_GLASS;
      }
      return svgs.ICONS.LIGHTBULB_BLUE;
    },
    hasTitleSlot() {
      return !!this.$slots['title-text'];
    }
  },
  beforeMount() {
    if (this.closedIdentifier) {
      const initiallyClosed = localStorage.getItem(this.closedIdentifier);
      if (initiallyClosed) {
        this.open = false;
      }
    }

    if (this.initiallyClosed) {
      this.open = false;
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;

      if (!this.open) {
        localStorage.setItem(this.closedIdentifier, 'true');
      } else {
        localStorage.removeItem(this.closedIdentifier);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse-card-wrapper {
  margin-top: 2rem;

  border-top: 0.6rem solid;
  margin-bottom: 3rem;
  &.is-primary {
    background-color: #fff;
  }
  &.is-alert {
    background-color: #fce5f6;
  }
  &.is-green {
    background-color: #d9f1e4;
  }
}

.inner-wrapper {
  padding: 3.2rem 3.8rem;
  @media ($mobile) {
    padding: 1.2rem 1.8rem;
  }
}

.collapse-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  + .collapse-body-wrapper .collapse-body {
    margin-top: 2rem;
  }
}

.collapse-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapse-title h3 {
  font-size: 2.2rem;
  margin-top: 5px;
  @media ($mobile) {
    font-size: 1.6rem;
  }
  &:empty {
    display: none;
  }
}

.collapse-body-wrapper {
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;
  max-height: 0;
  overflow: hidden;
}

.collapse-body {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  @media ($mobile) {
    font-size: 1.4rem;
  }
}

.collapse-body-wrapper.active {
  max-height: 2000px;
}

.left {
  gap: 30px;
  display: flex;
  align-items: center;
  @media ($mobile) {
    gap: 10px;
  }
}

.icon-tip {
  width: 3rem;
  height: 3.6rem;
  .is-alert & {
    margin-top: 0.3rem;
  }
}

.toggle-open-icon {
  transition: transform 0.2s ease-in-out;
}

.rotated {
  transform: rotate(45deg);
}
</style>
