<template>
  <SwapDefaultPage :names="activeApplicant.members[0].name" v-on="$listeners">
    <RejectionMessageCard category="SWAPREASON" />
    <CollapseCard
      :closed-identifier="'swap_reason_modal_closed'"
      :is-primary="true"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <p>
          För att ha rätt att byta din lägenhet krävs i enlighet med hyreslagen
          ett så kallat ”beaktansvärt skäl”. Det vill säga ett behov av att byta
          sin lägenhet. Exempel på detta kan vara att man är i behov av lägre
          hyra i och med en förändrad ekonomisk situation, ett rum extra till
          barnen som blivit större eller arbete/studier på annan ort. Det finns
          naturligtvis en mängd olika skäl till varför man behöver byta sin
          bostad och på hyresnämndens hemsida kan du läsa mer om vad som gäller
          vid byte av din bostad om du känner dig osäker.
        </p>
        <br />
        <h3>
          OBS: Skälet som anges måste alltid kunna styrkas. T.ex. så ska ett
          havandeskapsintyg kunna uppvisas om skälet är tillökning i familjen.
        </h3>
        <br />
        <p>
          Länk till hyresnämnden:
          <a
            href="https://www.domstol.se/amnen/hyra-bostadsratt-och-arrende/hyra-av-bostad-och-lokal/byta-och-overlata/byta-bostad/"
            target="_blank"
          >
            https://www.domstol.se/amnen/hyra-bostadsratt-och-arrende/hyra-av-bostad-och-lokal/byta-och-overlata/byta-bostad/</a
          >
        </p>
      </template>
    </CollapseCard>

    <transition name="form" mode="out-in">
      <SwapForm
        :is-loading="savingApplicationData"
        :form-has-changed="formHasChanged"
        form-type="SWAPREASON"
        v-on="$listeners"
      >
        <SwapInput
          v-model="swapReason.description"
          label="Skäl"
          placeholder="Skäl"
          :disabled="savingApplicationData"
          :description="`Beskriv ${formName(
            'ditt/dina'
          )} beaktansvärda skäl. Fältet är begränsat till max ${descriptionMaxLength}
        tecken.`"
          type="textarea"
          :maxlength="descriptionMaxLength"
        />
      </SwapForm>
    </transition>
  </SwapDefaultPage>
</template>
<script>
import { mapState } from 'vuex';
import { genitiveFromApplicant } from '@/utils/genitive';

import CollapseCard from '@/components/CollapseCard.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import SwapForm from '@/components/SwapForm.vue';
import SwapInput from '@/components/SwapInput.vue';
import RejectionMessageCard from '@/components/RejectionMessageCard.vue';

export default {
  name: 'SwapReason',
  components: {
    CollapseCard,
    SwapDefaultPage,
    SwapForm,
    SwapInput,
    RejectionMessageCard
  },
  props: {
    activeApplicant: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      descriptionMaxLength: 250
    };
  },
  computed: {
    ...mapState({
      application: state => state.application.application,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex
    }),
    swapReason: {
      get() {
        return this.activeApplicant?.swapReason;
      },
      set() {
        this.$emit();
      }
    },
    isApproved() {
      return this.swapReason?.review?.approved || false;
    }
  },
  methods: {
    formName(defaultWord) {
      return this.selectedApplicantIndex === 0
        ? defaultWord
        : genitiveFromApplicant(this.activeApplicant);
    }
  }
};
</script>
<style></style>
