<template>
  <div class="summary-body-wrapper">
    <h3>{{ subHeading }}</h3>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SummaryBody',

  props: {
    subHeading: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-body-wrapper {
  @media ($mobile) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  h3 {
    color: var(--color-blue);
  }
}
</style>
