<template>
  <BaseModal
    v-bind="$attrs"
    class="info-base-modal"
    :class="{ 'mobile-full-width': mobileFullWidth }"
    v-on="$listeners"
    @close="handleOutsideClick"
  >
    <div :class="['info-modal', mobileFullWidth ? 'mobile-full-width' : '']">
      <div class="info-modal-title-wrapper">
        <h3 class="info-modal-title">
          <span>{{ title }}</span
          ><button
            v-if="showCloseBtn"
            type="button"
            :disabled="isDisabled"
            class="info-modal-close-btn"
            aria-label="Stäng"
            @click="$emit('click', 'CANCEL')"
          >
            <BaseImage :url="svgs.ICONS.CLOSE" />
          </button>
        </h3>
      </div>
      <div class="info-modal-body">
        <slot />
        <div
          v-if="confirmLabel || abortLabel"
          class="info-modal-btn-wrapper"
          :class="{ 'btn-position-start': isPositionLeft }"
        >
          <BaseButton
            v-if="confirmLabel"
            :is-rounded="true"
            :is-warning="isWarning"
            type="button"
            class="info-modal-btn info-modal-btn--save"
            :disabled="isDisabled"
            @click="$emit('click', 'CONFIRM')"
          >
            {{ confirmLabel }}
            <BaseIconText
              v-if="!isWarning && confirmIcon"
              class="info-modal-arrow-right"
              :icon-url="svgs.ICONS.ARROW_RIGHT"
            >
            </BaseIconText>
          </BaseButton>
          <BaseButton
            v-if="abortLabel"
            :disabled="isDisabled"
            :is-rounded="true"
            :is-transparent="true"
            type="button"
            class="info-modal-btn info-modal-btn--discard"
            @click="$emit('click', 'REJECT')"
          >
            {{ abortLabel }}
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import svgs from '@/utils/icon-constants';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'InfoModal',
  components: {
    BaseModal,
    BaseButton,
    BaseIconText,
    BaseImage
  },
  props: {
    title: {
      type: String,
      required: true
    },
    confirmLabel: {
      type: String,
      default: null
    },
    abortLabel: {
      type: String,
      default: null
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    disableOutsideClick: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    confirmIcon: {
      type: Boolean,
      default: true
    },
    mobileFullWidth: {
      type: Boolean,
      default: false
    },
    isPositionLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgs
    };
  },
  methods: {
    handleOutsideClick() {
      if (this.isDisabled) {
        return;
      }
      if (!this.disableOutsideClick) {
        this.$emit('click', 'CANCEL');
      }
    }
  }
};
</script>
<style lang="scss">
.info-modal {
  &-body {
    > * {
      margin-bottom: 3rem;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    p {
      max-width: 43rem;
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
}
</style>
<style lang="scss" scoped>
$mainPadding: 3.6rem;
$mainPadding-mobile: 2.6rem;

.info-modal {
  background-color: #fff;
  max-width: min(60rem, 90vw);
  max-height: 94vh;
  overflow: auto;
  @media ($mobile) {
    border-radius: 10px;
  }
  &.mobile-full-width {
    @media ($mobile) {
      width: 100%;
      height: 100%;
      max-height: none;
      max-width: none;
      border-radius: 0;
    }
  }
}

.info-modal-body {
  padding: 1.7rem $mainPadding;
  @media ($mobile) {
    padding: 1.7rem $mainPadding-mobile;
  }
}

.info-modal-title-wrapper {
  border-bottom: 1px solid var(--color-light-gray);
  padding: 2.3rem $mainPadding 2.1rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 3;
  @media ($mobile) {
    padding: 2.3rem $mainPadding-mobile 2.1rem;
  }
}

.info-modal-title {
  font-size: 1.6rem;
  font-family: 'Poppins';
  color: var(--color-dark);
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin: 1px 0 0;
  text-align: center;
  position: relative;
  word-break: break-word;
  span {
    display: inline-block;
    padding: 0 3rem;
  }
}

.info-modal-arrow-right {
  margin-left: 1rem;
}

.info-modal-btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;

  &.btn-position-start {
    justify-content: flex-start;
  }
  @media ($mobile) {
    flex-direction: column;
    gap: 1rem;
  }
}

.info-modal-btn {
  padding-right: 2rem;
  padding-left: 2rem;
  height: 40px;

  @media ($mobile) {
    width: 100%;
  }
  &--discard {
    border: 2px solid var(--color-gray);
    color: var(--color-gray);
  }
}

.info-modal-close-btn {
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 0;
  cursor: pointer;
}

@media ($mobile) {
  .info-base-modal.mobile-full-width {
    :deep(.modal-wrapper) {
      border-radius: 0;
    }
  }
}
</style>
