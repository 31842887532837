<template>
  <i
    :class="['status-icon', statusClass]"
    :style="{
      height: `${size / 10}rem`,
      width: `${size / 10}rem`,
      fontSize: `${size}px`
    }"
    ><i class="symbol"></i
  ></i>
</template>

<script>
export default {
  name: 'StatusCircle',
  props: {
    status: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: Number,
      required: false,
      default: 16
    }
  },
  computed: {
    statusFormatted() {
      return this.status.toUpperCase();
    },
    statusClass() {
      if (this.statusFormatted === 'INCOMPLETE') {
        return 'status-icon--hollow-yellow';
      }
      if (
        this.statusFormatted === 'INVALID' ||
        this.statusFormatted === 'REJECTED'
      ) {
        return 'status-icon--hollow-red';
      }
      if (this.statusFormatted === 'COMPLETE') {
        return 'status-icon--hollow-green';
      }
      if (this.statusFormatted === 'COMMENT') {
        return 'status-icon--blue';
      }
      if (this.statusFormatted === 'APPROVED') {
        return 'status-icon--green';
      }
      if (this.statusFormatted === 'EMPTY') {
        return 'status-icon--grey';
      }
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.status-icon {
  border-radius: 50%;
  display: inline-block;
  border: 0.2rem solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  .symbol {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &--hollow-yellow {
    background-color: transparent;
    border-color: #ffc212;
  }

  &--hollow-red {
    background-color: transparent;
    border-color: #ff3843;
  }

  &--hollow-green {
    background-color: transparent;
    border-color: var(--color-green);
  }

  &--blue {
    background-color: #409fff;
    .symbol {
      &::after {
        content: '!';
        font-family:
          Averta Std,
          Helvetica,
          Arial,
          sans-serif;
        font-weight: 900;
        font-size: 0.7em;
        line-height: 1.5;
        color: #fff;
        display: inline-grid;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-style: normal;
      }
    }
  }

  &--green {
    background-color: var(--color-green);
    .symbol {
      background: url('../assets/svgs/icons/status-check.svg') center center /
        cover;
      background-repeat: no-repeat;
    }
  }

  &--grey {
    background-color: var(--color-light-gray);
    border-color: transparent;
  }
}
</style>
