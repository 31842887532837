<template>
  <div class="lb-login">
    <div v-if="showLogo" class="logo">
      <BaseImage :url="svgs.LB_LOGO" />
    </div>
    <template v-if="showForgotPassword">
      <div v-if="sentForgotMailSuccess" class="login-wrapper">
        <p>
          Ett e-postmeddelande har skickats till den e-postadress du angivit.
          Notera att det kan ta någon minut innan du får meddelandet.
        </p>
        <p>
          Klicka därefter på länken i meddelandet för att återställa lösenordet.
        </p>
        <div style="text-align: center">
          <BaseButton
            :is-fullwidth="false"
            :is-default="true"
            :is-rounded="true"
            type="button"
            class="login-button"
            @click="showLoginBankId"
            >Stäng</BaseButton
          >
        </div>
      </div>
      <div v-else-if="showForgotMailError" class="login-wrapper">
        <p>Något gick fel, försök igen senare.</p>
        <div style="text-align: center">
          <BaseButton
            :is-fullwidth="false"
            :is-default="true"
            :is-rounded="true"
            type="button"
            class="login-button"
            @click="showLoginBankId"
            >Stäng</BaseButton
          >
        </div>
      </div>
      <form v-else @submit.prevent="sendForgotPassword">
        <div class="login-wrapper">
          <Input
            v-model="username"
            :disabled="loading"
            :no-validate="true"
            class="mb-0"
            placeholder="E-postadress"
            type="text"
          />
          <BaseButton
            :is-loading="loading"
            :is-disabled="loading"
            :is-fullwidth="true"
            :is-rounded="true"
            type="submit"
            class="login-button"
            >Skicka</BaseButton
          >
        </div>
      </form>
    </template>
    <form
      v-if="!showForgotPassword && !showLoginBankIdLb"
      @submit.prevent="login"
    >
      <div class="login-wrapper">
        <Input
          v-model="username"
          :no-validate="true"
          class="mb-0"
          :disabled="loading"
          placeholder="Användarnamn"
          type="text"
        />
        <Input
          v-model="password"
          :no-validate="true"
          class="mb-0"
          :disabled="loading"
          placeholder="Lösenord"
          type="password"
        />
        <input type="submit" hidden="true" />
      </div>
      <p v-show="showErrorMessage" class="text-error">
        Fel användarnamn och/eller lösenord
      </p>

      <BaseButton
        :is-loading="loading"
        :is-disabled="loading"
        :is-fullwidth="true"
        :is-rounded="true"
        type="submit"
        class="login-button"
        >Logga in</BaseButton
      >

      <BaseButton
        :is-disabled="loading"
        :is-fullwidth="true"
        :is-rounded="true"
        type="button"
        class="login-button"
        @click="showLoginBankIdLb = true"
      >
        {{
          isMobile
            ? 'BankID via Lägenhetsbyte'
            : 'Logga in med BankID via Lägenhetsbyte'
        }}</BaseButton
      >
    </form>

    <BaseBankId
      v-if="!showForgotPassword && showLoginBankIdLb"
      :qr-path="'/lb/bankid/qr'"
      :auth-path="'/lb/bankid/start'"
      :collect-path="'/lb/bankid/auth'"
      :cancel-path="'/lb/bankid/cancel'"
      :base-url="baseUrl"
      :is-lb-login="true"
      @success="loginBankId($event)"
    >
      <template slot="header"><slot name="header" /></template>
    </BaseBankId>

    <section v-if="showCornerButtons" class="login-switch-wrapper">
      <button
        v-if="!showForgotPassword && !showLoginBankIdLb"
        class="simple-button login-switcher"
        @click="showForgotPassword = true"
      >
        Jag har glömt lösenordet
      </button>
      <button
        v-if="showForgotPassword && !showLoginBankIdLb"
        class="simple-button login-switcher"
        @click="showForgotPassword = false"
      >
        Tillbaka
      </button>
      <button
        v-if="showLoginBankIdLb"
        class="simple-button login-switcher"
        @click="showLoginBankIdLb = false"
      >
        Logga in med användarnamn och lösenord
      </button>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import auth from '@/services/auth';
import Input from '@/components/Input.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';
import BaseBankId from '@/components/BaseBankid.vue';
import apiClient from '@/services/apiClient';

export default {
  components: {
    Input,
    BaseButton,
    BaseImage,
    BaseBankId
  },

  props: {
    showLogo: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      svgs,
      baseUrl: apiClient.apiEndpoint,
      loading: false,
      showForgotPassword: false,
      showLoginBankIdLb: false,
      showErrorMessage: false,
      showForgotMailError: false,
      sentForgotMailSuccess: false,
      username: '',
      password: ''
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile'
    }),
    showCornerButtons() {
      if (this.showForgotMailError || this.sentForgotMailSuccess) {
        return false;
      }
      return true;
    }
  },

  methods: {
    ...mapActions({
      initiate: 'app/initiate'
    }),
    showLoginBankId() {
      this.showForgotMailError = false;
      this.sentForgotMailSuccess = false;
      this.showForgotPassword = false;
    },
    async sendForgotPassword() {
      this.loading = true;
      try {
        await axios.post('https://auth.lagenhetsbyte.se/auth/reset-password ', {
          username: this.username
        });
        this.sentForgotMailSuccess = true;
      } catch {
        this.showForgotMailError = true;
      }
      this.loading = false;
    },
    async login() {
      this.loading = true;
      this.showErrorMessage = false;

      if (!this.username || !this.password) {
        this.loading = false;
        this.showErrorMessage = true;
        this.username = '';
        this.password = '';
        return;
      }

      const success = await auth.loginUser(
        this.username.trim(),
        this.password.trim()
      );

      if (success) {
        await this.initiate();
      } else {
        this.showErrorMessage = true;
      }

      this.loading = false;
    },

    async loginBankId(responseData) {
      this.loading = true;

      const result = await auth.loginUserBankid(responseData);

      if (result.success) {
        await this.initiate();
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.login-wrapper > *:not(:first-child) {
  margin-top: 10px;
}

.login-button {
  margin-top: 20px !important;
}

.text-error {
  color: var(--color-error);
  margin-top: 10px;
}

.login-switcher {
  font-size: 12px;
  display: flex;
  margin-top: 20px;
  color: var(--color-blue);
}

:deep(.modal-content) {
  @media ($mobile) {
    width: calc(100vw - 20px);
  }
}

:deep(.bankid-wrapper) {
  padding: 0;
}

.login-view-body {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  width: 500px;
  max-width: 95%;
  padding: 35px;
  position: relative;
}

.logo {
  width: 25rem;
  max-width: 100%;
  margin: 0 auto 2.5rem;
  padding: 0 0.5rem;
}
</style>
