import { render, staticRenderFns } from "./InviteButton.vue?vue&type=template&id=461a650e&scoped=true"
import script from "./InviteButton.vue?vue&type=script&lang=js"
export * from "./InviteButton.vue?vue&type=script&lang=js"
import style0 from "./InviteButton.vue?vue&type=style&index=0&id=461a650e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461a650e",
  null
  
)

export default component.exports