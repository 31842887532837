import { render, staticRenderFns } from "./SwapSummary.vue?vue&type=template&id=b42ee134&scoped=true"
import script from "./SwapSummary.vue?vue&type=script&lang=js"
export * from "./SwapSummary.vue?vue&type=script&lang=js"
import style0 from "./SwapSummary.vue?vue&type=style&index=0&id=b42ee134&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b42ee134",
  null
  
)

export default component.exports