export function cloneTenant(tenants) {
  const clonedTenant = {};
  for (const key in tenants[0]) {
    if (key === 'index') {
      clonedTenant[key] = 1;
    }
    clonedTenant[key] = '';
  }
  return clonedTenant;
}

export default {
  cloneTenant
};
